import { Badge, Button, IndexTable, Modal } from "@shopify/polaris";
import { useState } from "react";

const ScopeListCell = ({ scopes }: { scopes: string[] }) => {
  const [ScopesModal, setScopesModal] = useState(false);
  return (
    <IndexTable.Cell>
      <div
        style={{
          display: "flex",
          gap: ".2rem",
        }}
      >
        <Button variant="plain" onClick={() => setScopesModal(true)}>
          Assigned Scopes
        </Button>
        <Modal
          open={ScopesModal}
          onClose={() => setScopesModal(false)}
          title="Assigned Scopes"
          primaryAction={{
            content: "Close",
            onAction: () => setScopesModal(false),
          }}
        >
          <Modal.Section>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
              {scopes.map((scope: string, roleIndex: number) => (
                <Badge
                  key={roleIndex}
                  tone={
                    scope.includes("ORDERS")
                      ? "success-strong"
                      : scope.includes("ACCOUNTS")
                      ? "attention"
                      : scope.includes("USERS")
                      ? "magic"
                      : "enabled"
                  }
                >
                  {scope}
                </Badge>
              ))}
            </div>
          </Modal.Section>
        </Modal>
      </div>
    </IndexTable.Cell>
  );
};

export default ScopeListCell;
