/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from "react-redux";
import { Frame, Loading } from "@shopify/polaris";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const { isAuthorized, loading } = useSelector((state: any) => state?.auth);

  if (loading) {
    return (
      <Frame>
        <Loading />
      </Frame>
    );
  }

  if (!isAuthorized) {
    return <Navigate to={"/login"} />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
