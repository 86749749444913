import {
  Badge,
  BlockStack,
  Button,
  Card,
  Image,
  List,
  Page,
  Text,
} from "@shopify/polaris";
import { AlertTriangleIcon } from "@shopify/polaris-icons";
import mergeImage1 from "../assets/docs/merge-order-1.png";
import mergeImage2 from "../assets/docs/merge-order-2.png";
import mergeImage3 from "../assets/docs/merge-order-3.png";
import mergeImageCity from "../assets/docs/merge-order-city.png";
import mergeImageRemarks from "../assets/docs/merge-order-remarks.png";
import postBookingPage from "../assets/docs/post-booking-page.png";
import { useState } from "react";

const REQUIRED_SCOPES = [
  { title: "READ_ORDERS", explanation: "visit order(s) page" },
  { title: "WRITE_ORDERS", explanation: "book & merge order(s)" },
  { title: "UPDATE_ORDERS", explanation: "track order(s) live" },
];

const MergeOrdersDocs = () => {
  const [showScopes, setShowScopes] = useState(false);
  return (
    <Page
      title="Merge orders"
      titleMetadata={
        <Badge tone="info" icon={AlertTriangleIcon} size="large">
          Admin access only. Users need <b>READ_ORDERS</b> and{" "}
          <b>WRITE_ORDERS</b> scope to access this dashboard.
        </Badge>
      }
    >
      <Card padding={"500"}>
        <BlockStack gap={"400"}>
          <p>
            Here, you will find everything you need to know about{" "}
            <b>merge orders</b>
          </p>
          <Text as="h1" fontWeight="bold" variant="headingMd">
            How Merge Orders Work in BOOKEASE?
          </Text>
          <p>
            In <b>BOOKEASE</b>, <b>merge orders</b> simplifies the process of
            booking multiple orders as a single entity. This feature allows you
            to manage several orders together while maintaining a unified set of
            contact and shipping information.
          </p>
          <List type="bullet">
            <List.Item>
              When merging orders, a list of all selected orders is displayed
              for review. This makes it easy to select the orders you want to
              combine.
            </List.Item>
            <List.Item>
              From the list, you can choose one order whose address, phone, and
              email details will be used for the entire merged order. This
              ensures that the merged booking has accurate and up-to-date
              contact information.
            </List.Item>
            <List.Item>
              Once you've selected the details from one of the orders, the
              system will automatically merge the other orders under the chosen
              address, phone, and email, grouping them under a single tracking
              number.
            </List.Item>
            <List.Item>
              This helps streamline order management, reduces manual entry
              errors, and ensures the merged order is correctly tracked
              throughout the shipping process.
            </List.Item>
            <List.Item>
              Additionally, the merged order will be processed with the combined
              shipping details, making it easier to handle multiple items going
              to the same destination.
            </List.Item>
          </List>
          <div>
            <BlockStack gap={"300"}>
              <div>
                <Button
                  variant="plain"
                  dataPrimaryLink
                  onClick={() => setShowScopes((prev: boolean) => !prev)}
                >
                  Required scopes
                </Button>
              </div>
              {showScopes && (
                <List>
                  {REQUIRED_SCOPES.map((scope) => (
                    <List.Item>
                      <p>
                        <Badge tone="magic">{scope.title}</Badge>{" "}
                        {scope.explanation}
                      </p>
                    </List.Item>
                  ))}
                </List>
              )}
            </BlockStack>
          </div>
          <Image source={mergeImage1} alt="merge order docs screen shots" />
          <Text as="h1" fontWeight="bold" variant="headingMd">
            Select Orders to Merge
          </Text>
          <p>
            Select the orders from Shopify that you want to merge, then go to
            the options menu and click <b>Merge with BOOKEASE</b>. You will be
            redirected to <b>merge order booking form page.</b>
          </p>
          <Image source={mergeImage2} alt="merge order docs screen shots" />
          <Text as="h1" fontWeight="bold" variant="headingMd">
            Choose Primary Order
          </Text>
          <p>
            The consignee's email, phone, and address will be used from the
            selected primary order. Therefore, be cautious when selecting the
            primary order to ensure the correct contact and shipping details are
            applied.
          </p>
          <Image source={mergeImage3} alt="merge order docs screen shots" />
          <Text as="h1" fontWeight="bold" variant="headingMd">
            Verify Destination City
          </Text>
          <p>
            The consignee's email, phone, and address will be used from the
            selected primary order. Therefore, be cautious when selecting the
            primary order to ensure the correct contact and shipping details are
            applied.
          </p>
          <p>
            The destination city is auto-populated only when the city is valid,
            as the courier provides a limited list of cities to select from. If
            the city is auto-populated, it means it's valid. If not, try
            searching for the city from the available list of courier cities to
            ensure the correct destination.
          </p>
          <Image source={mergeImageCity} alt="merge order docs screen shots" />
          <Text as="h1" fontWeight="bold" variant="headingMd">
            Add remarks <Badge tone="success">Optional</Badge>
          </Text>
          <p>
            Add remarks for the courier if needed, such as "Call consignee
            before going to deliver." This field is optional, and you can add
            any specific instructions to assist the courier in the delivery
            process.
          </p>
          <Image
            source={mergeImageRemarks}
            alt="merge order docs screen shots"
          />
          <Text as="h1" fontWeight="bold" variant="headingMd">
            Click on save
          </Text>
          <p>
            Click on <b>Save</b> your orders will be booked as one and you will
            be redirected to <b>Post booking page</b>
          </p>
          <Image source={postBookingPage} alt="merge order docs screen shots" />
        </BlockStack>
      </Card>
    </Page>
  );
};

export default MergeOrdersDocs;
