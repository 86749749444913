import { Link } from "react-router-dom";
import { Button } from "@shopify/polaris";
import notFoundImage from "../assets/images/404.png";

const NotFound = () => {
  return (
    <div
      style={{
        height: "100vh",
        justifyContent: "center",
        gap: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ maxWidth: "500px", height: "auto" }}>
        <img
          src={notFoundImage}
          alt="page not found image"
          height={"auto"}
          width={"300px"}
        />
      </div>
      <Link to={"/dashboard"}>
        <Button variant="primary">GO TO DASHBOARD</Button>
      </Link>
    </div>
  );
};

export default NotFound;
