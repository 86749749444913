import {
  BlockStack,
  Button,
  LegacyCard,
  List,
  Page,
  Text,
} from "@shopify/polaris";
import React from "react";
import { Link } from "react-router-dom";

const OrderBookingGuide = () => {
  return (
    <Page>
      <LegacyCard sectioned>
        <BlockStack gap="600">
          {/* Section: Create User Account */}
          <Text as="h1" variant="headingLg" fontWeight="bold">
            Create User Account
          </Text>
          <Text as="p" variant="bodyMd">
            This guide will walk you through the process of creating a new user
            account in your system. Follow each step carefully to ensure the
            account is set up correctly.
          </Text>
          <List type="number" gap="loose">
            <List.Item>
              <Text as="span">
                Click <strong>Settings</strong> on the left bottom corner of the
                sidebar and select <strong>Accounts</strong>. This is the
                section where you can manage user accounts.
              </Text>
            </List.Item>
            <List.Item>
              <Text as="span">
                On the right top corner, click the{" "}
                <strong>Create Account</strong> button. This will allow you to
                start the process of adding a new user to the system.
              </Text>
            </List.Item>
            <img
              style={{
                height: "100%",
                maxWidth: "100%",
              }}
              src={""}
              alt="gotoAccount"
            />
            <List.Item>
              <Text as="span">
                Select the provider from the available options. This step is
                necessary to specify the source of the account.
              </Text>
            </List.Item>
            <List.Item>
              <Text as="span">
                Click <strong>Settings</strong> again on the left bottom corner
                of the sidebar and select <strong>Account</strong>. This will
                bring you back to the account settings section if further
                adjustments are needed.
              </Text>
            </List.Item>
            <List.Item>
              <Text as="span">
                On the right top corner, click the{" "}
                <strong>Create Account</strong> button again to proceed with the
                creation process.
              </Text>
            </List.Item>
            <List.Item>
              <Text as="span">
                Add details of your account, such as user information required
                for account creation. Ensure that all necessary fields are
                filled out.
              </Text>
            </List.Item>
            <List.Item>
              <Text as="span">
                Finally, click the <strong>Save</strong> button to create the
                account. This action will complete the account setup.
              </Text>
            </List.Item>
          </List>
          <img
            style={{
              height: "100%",
              maxWidth: "100%",
            }}
            src={"accountdetails"}
            alt="accountdetails"
          />

          {/* Section: Account Created */}
          <Text as="h1" variant="headingLg" fontWeight="bold">
            Account Created
          </Text>
          <Text as="p" variant="bodyMd">
            After saving, the new account will be added to the system. The
            account is now active and ready for use.
          </Text>

          <img
            style={{
              height: "100%",
              maxWidth: "100%",
            }}
            src={"accountcreated"}
            alt="accountcreated"
          />

          <div style={{ textAlign: "right" }}>
            <Link to="/bookorder">
              <Button variant="primary">Next Book order</Button>
            </Link>
          </div>
        </BlockStack>
      </LegacyCard>
    </Page>
  );
};

export default OrderBookingGuide;
