import { BlockStack, Label, TextField } from "@shopify/polaris";
import { TagEditModal } from "./TagEditModal";
import { PROFILE_INPUT_CARD } from "../../store/types/Profile";

const ProfileInputcard = ({
  label = "N/A",
  value = "N/A",
  id = "",
  readonly = true,
  helpText = false,
  loading = false,
}: PROFILE_INPUT_CARD) => {
  return (
    <BlockStack gap={"100"}>
      <Label id="">{label}</Label>
      <TextField
        label="Profile card input"
        labelHidden
        readOnly={readonly}
        focused={false}
        id={id}
        type="text"
        loading={loading}
        autoComplete="false"
        value={value || ""}
        helpText={
          <div style={{ display: "flex", justifyContent: "end" }}>
            {" "}
            {helpText ? (
              <>
                <TagEditModal />
              </>
            ) : (
              false
            )}
          </div>
        }
      />
    </BlockStack>
  );
};

export default ProfileInputcard;
