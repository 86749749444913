/* eslint-disable @typescript-eslint/no-explicit-any */
import { Frame, Page } from "@shopify/polaris";

const LandingPage = () => {
  return (
    <Frame>
      <Page title={"Welcome to bookease"}></Page>
    </Frame>
  );
};
export default LandingPage;
