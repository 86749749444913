import ModalComponent from "./Modal";

const NoAccount = () => {
  return (
    <ModalComponent
      heading="Action Required: No Tracking Account Found."
      open={true}
      text="A tracking account is essential for order booking and tracking. Please create one to proceed."
      type=""
      close={() => {}}
      id=""
      key={Math.random()}
    />
  );
};
export default NoAccount;
