/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@shopify/polaris";
import { closeModal, openModal } from "../../store/store/slices/ModalSlice";
import { useCancelBookingMutation } from "../../store/store/slices/OrderRTK";
import { CancelBookingPropsTypes } from "../../store/types/types";
import { showToast } from "../../store/store/slices/AlertSlice";

const CancelBooking = ({
  tracking_number,
  company,
}: CancelBookingPropsTypes) => {
  const dispatch = useDispatch();
  const [cancelBooking, { isError, isLoading, isSuccess }] =
    useCancelBookingMutation<any>();
  const cancelBookingHandler = async (event: any) => {
    event.stopPropagation();
    dispatch(
      openModal({
        content: `${
          company !== "MNP"
            ? "Do you really want to cancel the booking?"
            : "Mnp has no route to cancel booking. If you hit yes it will just cancel Shopify FULFILLMENT. \nDo you really want to cancel FULFILLMENT? "
        }`,
        heading: `${
          company !== "MNP"
            ? "Booking cancel confirmation"
            : "Fulfillent cancel confirmation"
        }`,
        onConfirm: async () => {
          await cancelBooking(tracking_number).unwrap();
          dispatch(closeModal());
        },
      })
    );
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showToast({
          message:
            "Order has been cancelled from courier and unfulfilled from shopify",
          error: false,
          visible: true,
        })
      );
    }
  }, [isLoading, isError, cancelBooking]);
  return (
    <>
      <Button
        onClick={cancelBookingHandler as any}
        variant="plain"
        tone="critical"
        size="micro"
        loading={isLoading}
        disabled={isLoading}
      >
        Cancel
      </Button>
    </>
  );
};

export default CancelBooking;
