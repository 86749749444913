import {
  Badge,
  BlockStack,
  CalloutCard,
  Card,
  Icon,
  Image,
  List,
  Page,
  Text,
} from "@shopify/polaris";
import { MoneyIcon, ArrowRightIcon } from "@shopify/polaris-icons";
import ffdImage from "../../docs/assets/docs/statuses/fulfilled.png";
import inpImage from "../../docs/assets/docs/statuses/inp.png";
import dvdImage from "../../docs/assets/docs/statuses/dvd.png";
import rtsImage from "../../docs/assets/docs/statuses/rts.png";
import cndImage from "../../docs/assets/docs/statuses/canclld.png";

const LiveTracking = () => {
  return (
    <Page
      title="Live tracking"
      titleMetadata={
        <Badge icon={MoneyIcon} tone="attention">
          Availble only in Standard plan
        </Badge>
      }
    >
      <BlockStack gap={"400"}>
        <Card>
          <BlockStack gap={"400"}>
            <Text as="span" fontWeight="bold">
              How <Badge tone="success">live tracking</Badge> works in{" "}
              <b>Bookease</b>?
            </Text>
            <p>
              The BookEase Live Tracking feature is designed to{" "}
              <b>
                automate order status updates, providing real-time accuracy for
                your store's orders
              </b>
              . Available exclusively under the <b>Standard Plan,</b> this
              feature ensures all orders are live-tracked and updated daily
              without manual intervention.
            </p>
            <Text as="span" fontWeight="bold">
              Process
            </Text>
            <List>
              <List.Item>
                Everyday, BookEase processes all your store’s orders with
                statuses FULFILLED and INPROCESS.
              </List.Item>
              <List.Item>
                The system communicates with the couriers to fetch the latest
                tracking details for these orders.
              </List.Item>
              <List.Item>
                Updated statuses are applied to your orders and reflected
                directly in Shopify, keeping your store in sync with courier
                data.
              </List.Item>
            </List>
          </BlockStack>
        </Card>
        <CalloutCard
          illustration="https://cdn.shopify.com/s/files/1/0735/7225/3914/files/statuses.png?v=1737108964"
          title="Status Badges"
          primaryAction={{ content: "Visit orders page" }}
        >
          <BlockStack gap={"300"}>
            <p>
              The order table in BookEase includes status badges for quick
              visibility of each order's state. These badges represent the
              real-time status fetched from the courier, such as:
            </p>
            <List>
              <List.Item>FULIFLLED</List.Item>
              <List.Item>INPROCESS</List.Item>
              <List.Item>DELIVERED</List.Item>
              <List.Item>CANCELLED</List.Item>
              <List.Item>RTS</List.Item>
            </List>
            <p>
              These badges update dynamically, allowing you and your customers
              to monitor orders with ease.
            </p>
          </BlockStack>
        </CalloutCard>
        <Card>
          <BlockStack gap={"400"}>
            <Text as="span" fontWeight="bold">
              Lifecycle of Order Statuses
            </Text>
            <p>
              BookEase makes it simple to track your orders by following these
              three status lifecycles:
            </p>
            <Text as="span" fontWeight="bold">
              1. Standard Delivery Process
            </Text>
            <div style={{ display: "flex" }}>
              <Image
                source={ffdImage}
                alt="Delivery case"
                height={"50px"}
                width={"200px"}
              />
              <Icon source={ArrowRightIcon} />
              <Image
                source={inpImage}
                alt="Delivery case"
                height={"50px"}
                width={"200px"}
              />
              <Icon source={ArrowRightIcon} />
              <Image
                source={dvdImage}
                alt="Delivery case"
                height={"50px"}
                width={"200px"}
              />
            </div>

            <List>
              <List.Item>
                <b>Fulfilled:</b> After booking, the order is marked as
                fulfilled in Shopify.
              </List.Item>
              <List.Item>
                <b>In Process:</b> The courier picks up the order, and its
                status updates to in process.
              </List.Item>
              <List.Item>
                <b>Delivered:</b> Once delivered to the customer, the status
                changes to delivered.
              </List.Item>
            </List>
            <Text as="span" fontWeight="bold">
              2. Return to Sender (RTS) Process
            </Text>
            <div style={{ display: "flex" }}>
              <Image
                source={ffdImage}
                alt="Delivery case"
                height={"50px"}
                width={"200px"}
              />
              <Icon source={ArrowRightIcon} />
              <Image
                source={inpImage}
                alt="Delivery case"
                height={"50px"}
                width={"200px"}
              />
              <Icon source={ArrowRightIcon} />
              <Image
                source={rtsImage}
                alt="Delivery case"
                height={"50px"}
                width={"200px"}
              />
            </div>
            <List>
              <List.Item>
                <b>Fulfilled:</b> The order is marked as fulfilled when booked.
              </List.Item>
              <List.Item>
                <b>In Process:</b> The courier processes the order, and its
                status updates to in process.
              </List.Item>
              <List.Item>
                <b>Rts:</b> If the customer refuses delivery, the order returns
                to the sender with a status of RTS (Return to Sender).
              </List.Item>
            </List>
            <Text as="span" fontWeight="bold">
              3. Cancelled Order Process
            </Text>
            <div style={{ display: "flex" }}>
              <Image
                source={ffdImage}
                alt="Delivery case"
                height={"50px"}
                width={"200px"}
              />
              <Icon source={ArrowRightIcon} />

              <Image
                source={cndImage}
                alt="Delivery case"
                height={"50px"}
                width={"200px"}
              />
            </div>
            <List>
              <List.Item>
                <b>Fulfilled:</b> The order is initially marked as fulfilled
                after booking.
              </List.Item>
              <List.Item>
                <b>Cancelled:</b> If you cancel the order, the status is updated
                to cancelled in both BookEase and Shopify.
              </List.Item>
            </List>
          </BlockStack>
        </Card>
      </BlockStack>
    </Page>
  );
};

export default LiveTracking;
