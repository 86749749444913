/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Card,
  FormLayout,
  Page,
  Text,
  TextField,
} from "@shopify/polaris";
import { Controller, useForm } from "react-hook-form";
import { TWO_FACTOR_FORM_TYPES } from "../store/types/TwoFac";
import { useVerifyAuthCodeMutation } from "../store/store/slices/ProfileRTK";
import { useNavigate } from "react-router-dom";

const TwoFactorPage = () => {
  const navigate = useNavigate();
  // HOOKS
  const [veirfyCode, { isError, isLoading, error }] =
    useVerifyAuthCodeMutation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TWO_FACTOR_FORM_TYPES>();
  //   FUNCTIONS
  const verifyAuthCodeHandler = async (code: TWO_FACTOR_FORM_TYPES) => {
    const data = await veirfyCode(code);
    if (data?.data?.auth_token) {
      localStorage.removeItem("auth_token");
      localStorage.setItem("auth_token", data?.data?.auth_token);
      return navigate("/dashboard");
    }
  };

  return (
    <Page title="Two-factor authentication" narrowWidth>
      <Card>
        <FormLayout>
          <Controller
            control={control}
            name="code"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                autoComplete="off"
                label="Enter authenticator code here"
                placeholder="XXXXXX"
                error={errors.code && "Code is required"}
              ></TextField>
            )}
          />
          <div>
            <Button
              onClick={handleSubmit(verifyAuthCodeHandler)}
              disabled={isLoading}
              loading={isLoading}
            >
              Submit
            </Button>
          </div>
        </FormLayout>
        <div>
          <Text as="p" tone="critical">
            {isError && error?.data?.errors[0]?.msg}
          </Text>
        </div>
      </Card>
    </Page>
  );
};

export default TwoFactorPage;
