/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BlockStack,
  Button,
  Card,
  FormLayout,
  Icon,
  Page,
  PageActions,
  Text,
  TextField,
  Tooltip,
} from "@shopify/polaris";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InfoIcon } from "@shopify/polaris-icons";
import { Controller, useForm } from "react-hook-form";
import { showToast } from "../store/store/slices/AlertSlice";
import ScopesCard from "../components/atoms/users/ScopesCard";
import { useCreateChildAccountsMutation } from "../store/store/slices/UsersRTK";

const CreateUser = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [selectedScopes, setSelectedScopes] = useState<string[]>([]);
  const [
    createUserMutation,
    {
      isLoading: creatingAccount,
      isSuccess: accountCreated,
      error: accountCreationError,
      isError: accountCreationFailed,
    },
  ] = useCreateChildAccountsMutation<any>();
  const {
    reset,
    control,
    handleSubmit,

    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (accountCreated) {
      dispatch(
        showToast({ error: false, message: "User created", visible: true })
      );
      navigateTo("/dashboard/users");
    }
  }, [
    creatingAccount,
    accountCreated,
    accountCreationError,
    accountCreationFailed,
  ]);
  const onCreateUserClickHandler = async (formData: FormData) => {
    if (selectedScopes?.length === 0) {
      dispatch(
        showToast({
          message: "Atleast one access scope is required",
          error: true,
          visible: true,
        })
      );
      return;
    }
    await createUserMutation({
      ...formData,
      scopes: selectedScopes,
      role: "USER",
    }).unwrap();
  };
  // =====================================================
  const pullSelectedScopes = (selectedScopes: [string]) => {
    setSelectedScopes(selectedScopes);
  };

  return (
    <Page
      narrowWidth
      title="Create user"
      backAction={{
        onAction: () => {
          navigateTo("/dashboard/users");
        },
      }}
      primaryAction={
        <Button
          size="micro"
          variant="primary"
          onClick={handleSubmit(onCreateUserClickHandler as any)}
          loading={creatingAccount}
          disabled={creatingAccount}
        >
          Create user
        </Button>
      }
    >
      <BlockStack gap={"200"}>
        <Card>
          <FormLayout>
            <BlockStack gap={"300"}>
              <FormLayout.Group>
                <Controller
                  control={control}
                  name="first_name"
                  rules={{ required: "First name is required" }}
                  render={({ field }) => (
                    <TextField
                      label="First name"
                      {...field}
                      autoComplete="Off"
                      error={errors.first_name && "First name is required"}
                      requiredIndicator
                      clearButton
                      onClearButtonClick={() =>
                        reset((prev) => {
                          return { ...prev, first_name: "" };
                        })
                      }
                    />
                  )}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Controller
                  control={control}
                  name="last_name"
                  rules={{ required: "Last name is required" }}
                  render={({ field }) => (
                    <TextField
                      label="Last name"
                      {...field}
                      autoComplete="Off"
                      error={errors.last_name && "Last name is required"}
                      requiredIndicator
                      clearButton
                      onClearButtonClick={() =>
                        reset((prev) => {
                          return { ...prev, last_name: "" };
                        })
                      }
                    />
                  )}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Controller
                  control={control}
                  name="email"
                  rules={{ required: "Email is required" }}
                  render={({ field }) => (
                    <TextField
                      label="Email"
                      {...field}
                      autoComplete="Off"
                      error={errors.email && "Email is required"}
                      requiredIndicator
                      clearButton
                      onClearButtonClick={() =>
                        reset((prev) => {
                          return { ...prev, email: "" };
                        })
                      }
                    />
                  )}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Controller
                  control={control}
                  name="password"
                  rules={{ required: "Password is required" }}
                  render={({ field }) => (
                    <TextField
                      label="Password"
                      type="password"
                      {...field}
                      autoComplete="Off"
                      error={errors.password && "Password is required"}
                      requiredIndicator
                      clearButton
                      onClearButtonClick={() =>
                        reset((prev) => {
                          return { ...prev, password: "" };
                        })
                      }
                    />
                  )}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Controller
                  control={control}
                  name="tag"
                  defaultValue={""}
                  rules={{ required: "Tag is required" }}
                  render={({ field }) => (
                    <TextField
                      label={
                        <div style={{ display: "flex", gap: ".5rem" }}>
                          Tag{" "}
                          <Tooltip
                            content={
                              "This will be used as order tag while booking the order"
                            }
                            width="wide"
                          >
                            <Icon source={InfoIcon}></Icon>
                          </Tooltip>
                        </div>
                      }
                      type="text"
                      {...field}
                      autoComplete="Off"
                      error={errors.tag && "Tag is required"}
                      clearButton
                      onClearButtonClick={() =>
                        reset((prev) => {
                          return { ...prev, tag: "" };
                        })
                      }
                    />
                  )}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Controller
                  control={control}
                  name="phone"
                  render={({ field }) => (
                    <TextField
                      label="Phone (optional)"
                      type="tel"
                      {...field}
                      autoComplete="Off"
                      clearButton
                      onClearButtonClick={() =>
                        reset((prev) => {
                          return { ...prev, phone: "" };
                        })
                      }
                    />
                  )}
                />
              </FormLayout.Group>
              <FormLayout.Group condensed>
                <Controller
                  control={control}
                  name="province"
                  render={({ field }) => (
                    <TextField
                      label="Province (optional)"
                      type="text"
                      {...field}
                      autoComplete="Off"
                      clearButton
                      onClearButtonClick={() =>
                        reset((prev) => {
                          return { ...prev, province: "" };
                        })
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="address"
                  render={({ field }) => (
                    <TextField
                      label="Address (optional)"
                      type="text"
                      {...field}
                      autoComplete="Off"
                      clearButton
                      onClearButtonClick={() =>
                        reset((prev) => {
                          return { ...prev, address: "" };
                        })
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="city"
                  render={({ field }) => (
                    <TextField
                      label="City (optional)"
                      type="text"
                      {...field}
                      autoComplete="Off"
                      clearButton
                      onClearButtonClick={() =>
                        reset((prev) => {
                          return { ...prev, city: "" };
                        })
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="country"
                  render={({ field }) => (
                    <TextField
                      label="Country (optional)"
                      type="text"
                      {...field}
                      autoComplete="Off"
                      clearButton
                      onClearButtonClick={() =>
                        reset((prev) => {
                          return { ...prev, country: "" };
                        })
                      }
                    />
                  )}
                />
              </FormLayout.Group>
              <Text as="h1" fontWeight="bold">
                Access scopes
              </Text>
              <ScopesCard
                scopes={undefined}
                pushSelectedScopesToParent={pullSelectedScopes}
              ></ScopesCard>
            </BlockStack>
          </FormLayout>
        </Card>
      </BlockStack>
      <PageActions
        primaryAction={
          <Button
            size="micro"
            variant="primary"
            onClick={handleSubmit(onCreateUserClickHandler as any)}
            loading={creatingAccount}
            disabled={creatingAccount}
          >
            Create user
          </Button>
        }
      ></PageActions>
    </Page>
  );
};

export default CreateUser;
