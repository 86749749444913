/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./BaseQuery";

const accountApi = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  refetchOnReconnect: true,
  tagTypes: ["Accounts"],
  endpoints: (builder) => ({
    getAccounts: builder.query<any, void>({
      query: () => "get-accounts",
      transformResponse: (response: any) => response,
      keepUnusedDataFor: 300,
      providesTags: ["Accounts"],
      async onQueryStarted() {},
    }),
    getActiveAccounts: builder.query<any, void>({
      query: () => "get-active-accounts",
      transformResponse: (response: any) => response,
      keepUnusedDataFor: 300,
      providesTags: ["Accounts"],
      async onQueryStarted() {},
    }),
    getTableAccounts: builder.query<any, void>({
      query: () => "get-accounts-table",
      transformResponse: (response: any) => response,
      keepUnusedDataFor: 300,
      providesTags: ["Accounts"],
    }),
    createAccount: builder.mutation<any, any>({
      query: (accountData) => ({
        url: "create-account",
        method: "POST",
        body: { ...accountData, weight: Number(accountData?.weight) },
      }),
      invalidatesTags: ["Accounts"],
    }),

    getAccountToEdit: builder.query<any, string>({
      query: (accountId) => `get-edit-account/${accountId}`,
      providesTags: ["Accounts"],
      keepUnusedDataFor: 300,
    }),
    updateAccount: builder.mutation<
      any,
      { accountId: string; accountData: any }
    >({
      query: ({ accountId, accountData }) => ({
        url: `update-account`,
        method: "PUT",
        body: {
          id: accountId,
          ...accountData,
        },
      }),
      invalidatesTags: ["Accounts"],
    }),
  }),
});

export const {
  useGetAccountsQuery,
  useGetActiveAccountsQuery,
  useGetTableAccountsQuery,
  useCreateAccountMutation,
  useGetAccountToEditQuery,
  useUpdateAccountMutation,
} = accountApi;

export default accountApi;
