/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Banner,
  BlockStack,
  Button,
  Card,
  Checkbox,
  FormLayout,
  Icon,
  Page,
  Text,
  TextField,
  Tooltip,
} from "@shopify/polaris";
import { AlertTriangleIcon, InfoIcon } from "@shopify/polaris-icons";
import { Controller, useForm } from "react-hook-form";
import { useCreateAndUpdateSettingsMutation } from "../store/store/slices/SettingsRTK";
import { useEffect, useState } from "react";
import { useGetStoreSettingsQuery } from "../store/store/slices/UsersRTK";
import { useDispatch } from "react-redux";
import { showToast } from "../store/store/slices/AlertSlice";
import { useNavigate } from "react-router-dom";

const SettingsPage = () => {
  const {
    control,
    handleSubmit,
    // watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [createAndUpdateSettings, { isLoading, isError, error, isSuccess }] =
    useCreateAndUpdateSettingsMutation<any>();
  const {
    data,
    isError: settingsFetchFailed,
    isLoading: gettingSettings,
    isSuccess: gotSettings,
    error: settingsError,
  } = useGetStoreSettingsQuery<any>(undefined);

  const onCreateStoreSettings = async () => {
    const settingsData = getValues();
    await createAndUpdateSettings({ ...settingsData, show_discount: checked });
  };
  // const handleSelectChange = useCallback(
  //   (value: string) => setSelected(value),
  //   []
  // );

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showToast({ message: "Settings saved", error: false, visible: true })
      );
    }
  }, [isLoading, isError, error, isSuccess]);
  useEffect(() => {
    reset({
      // default_weight: data?.default_weight,
      free_shipping_value: data?.free_shipping_value,
      cancelled_tag: data?.tags?.cancelled_tag || "",
      delivered_tag: data?.tags?.delivered_tag || "",
      inprocess_tag: data?.tags?.inprocess_tag || "",
      discount_name: data?.discount_name || "",
      rts_tag: data?.tags?.rts_tag || "",
    });
    setChecked(() => !!data?.show_discount);
    // setSelected(() => (data?.show_voucher_details ? "YES" : "NO"));
  }, [gettingSettings, data, settingsFetchFailed, gotSettings]);

  if (settingsError) {
    return (
      <Page narrowWidth>
        <Banner icon={AlertTriangleIcon} tone="warning">
          {settingsError?.data?.errors[0].msg}
        </Banner>
      </Page>
    );
  }

  return (
    <Page
      narrowWidth
      title="Settings"
      backAction={{
        onAction: () => {
          navigate("/dashboard/users");
        },
      }}
      // primaryAction={{
      //   content: "Save",
      //   loading: isLoading,
      //   disabled: isLoading,
      //   onAction: handleSubmit(onCreateStoreSettings as any),
      //   // disabled: !!buttonsDisabled,
      // }}
    >
      <BlockStack gap={"400"}>
        <Card>
          <BlockStack gap={"300"}>
            <Text as="h1" fontWeight="bold" variant="bodyLg">
              Order booking
            </Text>
            <FormLayout>
              {/* <FormLayout.Group condensed>
            <Controller
              control={control}
              name="default_weight"
              defaultValue={data?.default_weight}
              rules={{ required: "Default weight is required" }}
              render={({ field }) => (
                <TextField
                  type="number"
                  {...field}
                  prefix="grams"
                  // connectedRight={
                  //   <Button variant="primary" disabled>
                  //     grams
                  //   </Button>
                  // }
                  max={10000}
                  step={50}
                  min={0}
                  loading={gettingSettings}
                  error={errors.default_weight && "Default weight is required"}
                  label={
                    <Text as="h1" fontWeight="bold">
                      Default weight :{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        (
                        {watch("default_weight") > 0
                          ? Number((watch("default_weight") / 1000).toFixed(2))
                          : 0}{" "}
                        KG)
                      </span>
                    </Text>
                  }
                  autoComplete="off"
                />
              )}
            />
          </FormLayout.Group> */}
              <FormLayout.Group>
                <Controller
                  control={control}
                  name="free_shipping_value"
                  defaultValue={data?.free_shipping_value}
                  rules={{ required: "Default shipping value is required" }}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      {...field}
                      max={200000}
                      loading={gettingSettings}
                      min={0}
                      step={50}
                      prefix={"Rs."}
                      error={
                        errors.free_shipping_value &&
                        "Free shipping value is required"
                      }
                      label={
                        <Text as="h1" fontWeight="bold">
                          <p
                            style={{ display: "flex", justifyContent: "start" }}
                          >
                            <Text as="h1" fontWeight="bold">
                              Free shipping value :{" "}
                            </Text>
                            <span>
                              <Tooltip
                                content="0 will be considered as free shipping value is not set yet"
                                width="default"
                              >
                                <Icon source={InfoIcon} tone="primary" />
                              </Tooltip>
                            </span>
                            <Badge tone="success-strong">
                              ONLY FOR MERGED ORDERS
                            </Badge>
                            <span>
                              No shipping for orders over this amount.{" "}
                            </span>
                          </p>
                        </Text>
                      }
                      autoComplete="off"
                    />
                  )}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Controller
                  control={control}
                  name="discount_name"
                  defaultValue={data?.discount_name}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      disabled={!checked}
                      {...field}
                      loading={gettingSettings}
                      label={
                        <Text as="h1" fontWeight="bold">
                          Discount name :{" "}
                          <span style={{ fontWeight: "lighter" }}>
                            Optional! This will be added as discount name
                          </span>
                        </Text>
                      }
                      placeholder="'Loyalty'"
                      autoComplete="off"
                    />
                  )}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Checkbox
                  onChange={(val) => setChecked(val)}
                  checked={checked}
                  label={
                    <p>
                      <Text as="span" fontWeight="bold">
                        Show discount details:
                      </Text>{" "}
                      Applied discount details will be displayed with products
                      details
                    </p>
                  }
                />
              </FormLayout.Group>
            </FormLayout>
          </BlockStack>
          <div style={{ marginTop: "2rem" }}></div>
          <BlockStack gap={"300"}>
            <Text as="h1" fontWeight="bold" variant="bodyLg">
              Shopify order tags{" "}
              {/* <Badge tone="success-strong">
                AVAILBLE ONLY FOR PREMIUM PLAN
              </Badge> */}
            </Text>
            <FormLayout>
              <BlockStack gap={"300"}>
                {/* <FormLayout.Group>
                  <Controller
                    control={control}
                    name="fulfill_tag"
                    defaultValue={data?.discount_name}
                    render={({ field }) => (
                      <TextField
                        type="text"
                        disabled={!checked}
                        {...field}
                        loading={gettingSettings}
                        label={
                          <Text as="h1" fontWeight="bold">
                            Fulfilled tag:{" "}
                            <span style={{ fontWeight: "lighter" }}>
                              Specify the tag you want to add upon fulfilled.{" "}
                            </span>
                          </Text>
                        }
                        placeholder="'Loyalty'"
                        autoComplete="off"
                      />
                    )}
                  />
                </FormLayout.Group> */}
                <FormLayout.Group>
                  <Controller
                    control={control}
                    name="inprocess_tag"
                    defaultValue={data?.inprocess_tag}
                    render={({ field }) => (
                      <TextField
                        type="text"
                        {...field}
                        loading={gettingSettings}
                        label={
                          <Text as="h1" fontWeight="bold">
                            Inprocess tag:{" "}
                            <span style={{ fontWeight: "lighter" }}>
                              Specify the tag you want to add upon inprocess.{" "}
                            </span>
                          </Text>
                        }
                        placeholder="for example 'INP'"
                        autoComplete="off"
                      />
                    )}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Controller
                    control={control}
                    name="delivered_tag"
                    defaultValue={data?.tag?.delivered_tag}
                    render={({ field }) => (
                      <TextField
                        type="text"
                        {...field}
                        loading={gettingSettings}
                        label={
                          <Text as="h1" fontWeight="bold">
                            Delivered tag:{" "}
                            <span style={{ fontWeight: "lighter" }}>
                              Specify the tag you want to add upon delivery.{" "}
                            </span>
                          </Text>
                        }
                        placeholder="for example 'DVD'"
                        autoComplete="off"
                      />
                    )}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Controller
                    control={control}
                    name="cancelled_tag"
                    defaultValue={data?.tag?.cancelled_tag}
                    render={({ field }) => (
                      <TextField
                        type="text"
                        {...field}
                        loading={gettingSettings}
                        label={
                          <Text as="h1" fontWeight="bold">
                            Cancelled tag:{" "}
                            <span style={{ fontWeight: "lighter" }}>
                              Specify the tag you want to add on cancelling
                              fulfillment.{" "}
                            </span>
                          </Text>
                        }
                        placeholder="for example 'CANCLLD'"
                        autoComplete="off"
                      />
                    )}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Controller
                    control={control}
                    name="rts_tag"
                    defaultValue={data?.tag?.rts_tag}
                    render={({ field }) => (
                      <TextField
                        type="text"
                        {...field}
                        loading={gettingSettings}
                        label={
                          <Text as="h1" fontWeight="bold">
                            RTS tag:{" "}
                            <span style={{ fontWeight: "lighter" }}>
                              Specify the tag you want to add on returning
                              parcel.{" "}
                            </span>
                          </Text>
                        }
                        placeholder="for example 'RTS'"
                        autoComplete="off"
                      />
                    )}
                  />
                </FormLayout.Group>
              </BlockStack>
            </FormLayout>
          </BlockStack>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingTop: "1rem",
            }}
          >
            <Button
              variant="primary"
              loading={isLoading}
              disabled={isLoading}
              onClick={handleSubmit(onCreateStoreSettings)}
            >
              Save
            </Button>
          </div>
        </Card>
      </BlockStack>
    </Page>
  );
};

export default SettingsPage;
