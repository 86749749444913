import { useLocation, useNavigate } from "react-router-dom";
import { useFetchOrdersForMergeQuery } from "../store/store/slices/OrderRTK";
import {
  Badge,
  BlockStack,
  Box,
  Card,
  Grid,
  Icon,
  InlineGrid,
  Modal,
  Page,
  Spinner,
  Text,
} from "@shopify/polaris";
import {
  AlertDiamondIcon,
  DiscountFilledIcon,
  DeliveryFilledIcon,
} from "@shopify/polaris-icons";
import { useEffect, useRef, useState } from "react";
import MergeOrderForm from "../components/atoms/MergeOrderForm";
import PostBookingPage from "../components/atoms/PostBookingPage";
// import useToast from "../hooks/useToast";
import {
  CHILD_COMPONENT_REF,
  LINE_ITEM_WITH_NODE,
  POST_BOOKING_DATA,
  SINGLE_ORDER,
} from "../store/types/MergeOrder";
import { useDispatch } from "react-redux";
import { showToast } from "../store/store/slices/AlertSlice";

const MergeOrderWrapper = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const childRef = useRef<CHILD_COMPONENT_REF | null>(null);
  const [selectedOrderIndex, setSelectedOrderIndex] = useState<number>(-1);
  const [postBookingData, setPostBookingData] =
    useState<POST_BOOKING_DATA | null>(null);
  const [postBookingPage, setPostBookingPage] = useState<boolean>(false);
  const ordersIdsArray =
    [...new Set(new URLSearchParams(useLocation().search).getAll("ids[]"))] ||
    [];
  const [skip, setSkip] = useState<boolean>(
    ordersIdsArray?.length === 0 || true
  );
  const {
    data: fetchedShopifyOrder,
    isFetching,
    isLoading,
    isError,
    error: shopifyError,
  } = useFetchOrdersForMergeQuery({ ordersIdsArray }, { skip });

  useEffect(() => {
    setSelectedOrderIndex(-1);
  }, []);

  useEffect(() => {
    setSkip(() => ordersIdsArray?.length === 0);
  }, [ordersIdsArray]);

  // const changeSelectedOrder = () => {
  //   setSelectedOrderIndex(-1);
  // };
  const handleBookingSuccess = (data: POST_BOOKING_DATA) => {
    // dispatch alert
    dispatch(
      showToast({
        message: "Order has been booked and fulfilled",
        error: false,
        visible: true,
      })
    );
    setPostBookingPage(true);
    setPostBookingData(() => data);
  };
  const onBookOrderClickHandler = async () => {
    if (childRef?.current) {
      await childRef?.current?.bookingSubmitHandle();
    }
  };
  // CASE 1 - If orderIds array length is 1 && ordersIds array have no id || orderIds array length is 0
  // SHOWS error highlighting invalid id
  if (
    (ordersIdsArray.length === 1 && ordersIdsArray[0]?.trim() === "") ||
    ordersIdsArray.length === 0
  ) {
    return (
      <Page narrowWidth>
        <Card>
          <div
            style={{ display: "flex", justifyContent: "start", gap: "10px" }}
          >
            <div>
              <Icon source={AlertDiamondIcon} tone="critical"></Icon>
            </div>
            <Text as="span" fontWeight="regular">
              Invalid id entered
            </Text>
          </div>
        </Card>
      </Page>
    );
  }
  // CASE 2 ordersIds array length is 1 || there is no error from api response &&  orders from api is 1
  // nvaigating to single order booking
  if (
    (ordersIdsArray?.length === 1 && !isError) ||
    fetchedShopifyOrder?.orders?.length === 1
  ) {
    navigateTo(`/dashboard/order/book?id=${ordersIdsArray[0]}`);
  }

  // Modal content for error handling
  const modalContent: { heading: string; body: string } = {
    heading: "",
    body: "",
  };
  if (ordersIdsArray?.length === 0) {
    modalContent.heading = "We have found no order id(s) to search order(s)";
    modalContent.body = "We are redirecting you to orders page...";
    setTimeout(() => {
      navigateTo("/dashboard/orders");
    }, 5000);
  }
  // CASE 3 - Loading or refetching
  // Showing spinner
  if (isLoading || isFetching) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          height: "100%",
        }}
      >
        <Spinner accessibilityLabel="Order fetching loading..." size="small" />
        <p>Fetching orders...</p>
      </div>
    );
  }
  // CASE 4 - Handling error if isError is true || orderIds Array length is 0
  if (
    isError ||
    ordersIdsArray?.length === 0 ||
    (fetchedShopifyOrder && fetchedShopifyOrder?.orders?.length === 0)
  ) {
    return (
      <Modal
        title={
          modalContent?.heading
            ? modalContent?.heading
            : shopifyError?.data?.errors[0].msg
        }
        open={isError || ordersIdsArray?.length === 0}
        onClose={() => {}}
      >
        <Modal.Section>
          {modalContent?.body ? (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Spinner
                accessibilityLabel="No orders to merge loading"
                size="small"
              />
              {modalContent?.body}
            </div>
          ) : (
            <p>
              Oops! We have found no order(s) corresponding to these id(s).
              <span>Try adding valid id(s)</span>
            </p>
          )}
        </Modal.Section>
      </Modal>
    );
  }

  // CASE 5 - POST booking page
  if (postBookingPage) {
    return <PostBookingPage data={postBookingData} />;
  }

  return (
    <>
      <Page
        title="Book order(s)"
        narrowWidth
        primaryAction={{
          content: "Book order(s)",
          onAction: onBookOrderClickHandler,
        }}
      >
        <BlockStack gap={"200"}>
          {selectedOrderIndex < 0 && (
            <Text as="h1" fontWeight="bold" variant="headingMd">
              Select Order
            </Text>
          )}
          {fetchedShopifyOrder?.orders?.map(
            (order: SINGLE_ORDER, index: number) => (
              <div
                style={{ marginTop: ".3rem", cursor: "pointer" }}
                key={index}
                onClick={() => {
                  setSelectedOrderIndex(() => index);
                }}
              >
                <Card>
                  <BlockStack gap={"300"}>
                    <Grid>
                      <Grid.Cell columnSpan={{ md: 6, lg: 12 }}>
                        <BlockStack gap={"200"}>
                          <Text as="span" fontWeight="bold" variant="headingMd">
                            {order?.name}
                          </Text>

                          {order?.shipping !== 0 ? (
                            <Text as="span" fontWeight="regular">
                              <Badge
                                icon={DeliveryFilledIcon}
                                tone="magic"
                                size="small"
                              >
                                {`Shipping: Rs.${order?.shipping?.toString()}`}
                              </Badge>{" "}
                            </Text>
                          ) : (
                            <Text as="span" fontWeight="regular">
                              <Badge
                                icon={DeliveryFilledIcon}
                                tone="info"
                                size="small"
                              >
                                Free shipping
                              </Badge>{" "}
                            </Text>
                          )}
                          {order?.has_discount && (
                            <Text as="span" fontWeight="regular">
                              <Badge
                                icon={DiscountFilledIcon}
                                tone="success"
                                size="small"
                              >
                                {order?.applied_discount}
                              </Badge>{" "}
                            </Text>
                          )}
                          <BlockStack gap="0">
                            {order?.products_list?.map(
                              (item: LINE_ITEM_WITH_NODE) => (
                                <InlineGrid columns={{ md: 8, lg: 12 }}>
                                  <Grid.Cell columnSpan={{ lg: 7 }}>
                                    <Text as="span" variant="bodyMd">
                                      {item?.node?.product?.title}
                                      {item?.node?.variant?.title !==
                                      "Default Title"
                                        ? ` - ${item?.node?.variant?.title}`
                                        : ""}
                                    </Text>
                                  </Grid.Cell>
                                  <Grid.Cell columnSpan={{ lg: 3 }}>
                                    <Text as="p">
                                      ({item?.node?.currentQuantity} x{" "}
                                      {`Rs. ${item?.node?.originalUnitPriceSet?.presentmentMoney?.amount}`}
                                      )
                                    </Text>
                                  </Grid.Cell>
                                  <Grid.Cell columnSpan={{ lg: 2 }}>
                                    <Text as="p" alignment="end">
                                      Rs.{" "}
                                      {(
                                        item?.node?.currentQuantity *
                                        Number(
                                          item?.node?.originalUnitPriceSet
                                            ?.presentmentMoney?.amount
                                        )
                                      ).toFixed(2)}
                                    </Text>
                                  </Grid.Cell>
                                </InlineGrid>
                              )
                            )}
                          </BlockStack>
                          {/* <Text as="h2" variant="headingSm">
                            COD without shipping:
                            {order?.codWithoutShipping} PKR
                          </Text> */}
                        </BlockStack>
                      </Grid.Cell>
                      {/* <Grid.Cell columnSpan={{ lg: 5 }}>
                        <Text as="h2" variant="headingSm">
                          COD without shipping:
                          {order?.codWithoutShipping} PKR
                        </Text>
                      </Grid.Cell> */}
                    </Grid>
                    <Box
                      borderBlockStartWidth="0165"
                      borderColor="border"
                      paddingBlockStart={"200"}
                    >
                      <Grid columns={{ xs: 2, md: 2 }}>
                        {/* <Grid.Cell columnSpan={{ lg: 1 }}></Grid.Cell> */}
                        <Grid.Cell columnSpan={{ md: 4, lg: 10 }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              gap: ".5rem",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="radio"
                                name="orderSelect"
                                value={order?.id}
                                checked={index === selectedOrderIndex}
                                onChange={() => {
                                  setSelectedOrderIndex(() => index);
                                }}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>
                            <p>{order?.consignee_address}</p>
                          </div>
                        </Grid.Cell>
                        <Grid.Cell columnSpan={{ md: 2, lg: 2 }}>
                          <Text
                            as="h2"
                            fontWeight="bold"
                            variant="headingSm"
                            alignment="end"
                          >
                            Rs. {order?.cod.toString()}
                          </Text>
                        </Grid.Cell>
                      </Grid>
                    </Box>
                  </BlockStack>
                </Card>
              </div>
            )
          )}
        </BlockStack>
      </Page>
      {selectedOrderIndex !== -1 && (
        <MergeOrderForm
          selectedOrder={fetchedShopifyOrder?.orders[selectedOrderIndex]}
          orderMetaData={{
            name: fetchedShopifyOrder?.orderNames?.join(","),
            id: fetchedShopifyOrder?.orderids?.join(","),
            shippingIsZero: fetchedShopifyOrder?.shippingIsZero,
            codWithoutShipping: fetchedShopifyOrder?.codWithoutShipping,
            shippingPrice: fetchedShopifyOrder?.shippingPrice,
            avgShipping: fetchedShopifyOrder?.avgShipping,
            pieces: fetchedShopifyOrder?.pieces || 1,
            productsList: fetchedShopifyOrder?.productsList,
            cod: fetchedShopifyOrder?.cod,
            freeShipping: fetchedShopifyOrder?.shippingIsZero,
          }}
          fulfillments={fetchedShopifyOrder?.orders?.map(
            (order: SINGLE_ORDER) => order?.fulfillment
          )}
          pingOrdersMerged={handleBookingSuccess}
          ref={childRef}
          // onChangeSelectedOrder={changeSelectedOrder}
        />
      )}
    </>
  );
};

export default MergeOrderWrapper;
