/* eslint-disable @typescript-eslint/no-explicit-any */
// src/store/slices/CitiesSlice.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./BaseQuery";

export const settingsApi = createApi({
  reducerPath: "settingsApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createAndUpdateSettings: builder.mutation({
      query: (data) => ({
        url: `/store/default-settings`,
        method: "POST",
        body: {
          ...data,
          free_shipping_value: Number(data?.free_shipping_value),
          default_weight: Number(data?.default_weight),
        },
      }),
    }),
  }),
});

export const { useCreateAndUpdateSettingsMutation } = settingsApi;
