/* eslint-disable @typescript-eslint/no-explicit-any */
import { Filters, DatePicker, Icon, Tooltip } from "@shopify/polaris";
import { InfoIcon } from "@shopify/polaris-icons";
import dayjs from "dayjs";
import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function DateRange() {
  const navigateTo = useNavigate();
  const appliedFilters: any = [];
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    navigateTo(
      `/dashboard/?bookingfrom=${params.get("bookingfrom") || ""}&bookingto=${
        params.get("bookingto") || ""
      }&deliveredfrom=${params.get("deliveredfrom") || ""}&deliveredto=${
        params.get("deliveredto") || ""
      }`
    );
  }, []);
  const [{ month, year }, setBooking] = useState<any>({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState<any>(null);
  const [{ month: dmonth, year: dyear }, setDelivery] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDeliveryDates, setSelectedDeliveryDates] = useState<any>(null);
  const [lastSevenDays, setLastSevenDays] = useState<boolean>(
    !!selectedDates || !!selectedDeliveryDates
  );
  const handleBookingDateChange = useCallback(
    (month: number, year: number) => setBooking({ month, year }),
    []
  );
  const handleDeliveryDateChange = useCallback(
    (month: number, year: number) => setDelivery({ month, year }),
    []
  );
  useEffect(() => {
    setLastSevenDays(!!selectedDates || !!selectedDeliveryDates);
  }, [
    selectedDates,
    selectedDeliveryDates,
    handleBookingDateChange,
    handleDeliveryDateChange,
  ]);
  const handleRemoveBookingDate = useCallback(() => {
    params.set("bookingfrom", "");
    params.set("bookingto", "");
    setSelectedDates(null);
    navigateTo({ search: params.toString() });
  }, []);
  const handleRemoveDeliveryDate = useCallback(() => {
    params.set("deliveredfrom", "");
    params.set("deliveredto", "");
    setSelectedDeliveryDates(null);
    navigateTo({ search: params.toString() });
  }, []);
  const handleFiltersClearAll = () => {
    handleRemoveDeliveryDate();
    handleRemoveBookingDate();
  };
  const today = dayjs();
  const lastSevenDaysStart = today.subtract(6, "day");
  const todayFormatted = today.format("YYYY-MM-DD");
  const lastSevenDaysStartFormatted = lastSevenDaysStart.format("YYYY-MM-DD");
  const filters = [
    {
      key: "booking_date",
      label: "Pick booking date",
      filter: (
        <DatePicker
          month={month}
          year={year}
          onChange={(dt) => {
            params.set("bookingfrom", dt.start.toDateString());
            params.set("bookingto", dt.end.toDateString());
            setSelectedDates(dt);
            navigateTo({ search: params.toString() });
          }}
          onMonthChange={handleBookingDateChange}
          selected={selectedDates}
          multiMonth
          allowRange
        />
      ),
    },
    {
      key: "delivered_date",
      label: "Pick delivered date",
      filter: (
        <DatePicker
          month={dmonth}
          year={dyear}
          onChange={(dd) => {
            params.set("deliveredfrom", dd.start.toDateString());
            params.set("deliveredto", dd.end.toDateString());
            setSelectedDeliveryDates(dd);
            navigateTo({ search: params.toString() });
          }}
          onMonthChange={handleDeliveryDateChange}
          selected={selectedDeliveryDates}
          multiMonth
          allowRange
        />
      ),
    },
  ];
  const bookingFrom = params.get("bookingfrom") || null;
  const bookingto = params.get("bookingto") || null;
  const deliveredfrom = params.get("deliveredfrom") || null;
  const deliveredto = params.get("deliveredto") || null;
  if (bookingFrom && bookingto) {
    const booking_from = new Date(bookingFrom);
    const booking_to = new Date(bookingto);
    const key = "booking_date";
    appliedFilters.push({
      key,
      label: `Booking date ${booking_from.toDateString()} to ${booking_to.toDateString()}`,
      onRemove: handleRemoveBookingDate,
    });
  }
  if (deliveredfrom && deliveredto) {
    const deliver_from = new Date(deliveredfrom);
    const deliver_to = new Date(deliveredto);
    const key = "delivered_date";
    appliedFilters.push({
      key,
      label: `Delivery date ${deliver_from.toDateString()} to ${deliver_to.toDateString()}`,
      onRemove: handleRemoveDeliveryDate,
    });
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        className="last_seven_days"
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".5rem",
          justifyContent: "center",
        }}
      >
        {!lastSevenDays && (
          <>
            <div
              style={{
                fontWeight: "normal",
                fontSize: "16px",
                fontStyle: "italic",
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <span>
                {lastSevenDaysStartFormatted + " - " + todayFormatted}
              </span>
              <div style={{ cursor: "pointer" }}>
                <Tooltip
                  width="wide"
                  content={
                    <p style={{ textAlign: "center" }}>
                      By default, you will see last seven days booked orders
                      analytics. But you can 👉 change filters however you want.
                    </p>
                  }
                >
                  <Icon source={InfoIcon}></Icon>
                </Tooltip>
              </div>
            </div>
            <div
              style={{
                width: "clamp(90px, 90px, 200px)",
                height: "1px",
                background: "rgb(199 195 195)",
              }}
            ></div>
          </>
        )}
      </div>
      <Filters
        filters={filters}
        appliedFilters={appliedFilters}
        onQueryChange={() => {}}
        onQueryClear={() => {}}
        onClearAll={handleFiltersClearAll}
        hideQueryField
      ></Filters>
    </div>
  );
}
