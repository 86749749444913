import {
  Badge,
  BlockStack,
  Card,
  Image,
  List,
  Page,
  Text,
} from "@shopify/polaris";
import { AlertTriangleIcon } from "@shopify/polaris-icons";
import { useState } from "react";
import bookOrderGr1 from "../../docs/assets/docs/order-booking-1.gif";
import bookOrderGr2 from "../../docs/assets/docs/order-booking-2.gif";
import bookOrderGr3 from "../../docs/assets/docs/shopify-fulfillment.png";

const BookOrderDocs = () => {
  const [showScopes, setShowScopes] = useState(false);
  return (
    <Page
      title="Order booking"
      titleMetadata={
        <Badge tone="info" icon={AlertTriangleIcon} size="large">
          Admin access only. Users need <b>READ_ORDERS</b> and{" "}
          <b>WRITE_ORDERS</b> scope to access this resource.
        </Badge>
      }
    >
      <Card padding={"500"}>
        <BlockStack gap={"400"}>
          <p>
            Here, you will find everything you need to know about{" "}
            <b>order booking.</b>
          </p>
          <p>
            The <b>BookEase Order Booking </b> page simplifies the order
            fulfillment process by integrating directly with your{" "}
            <b> Shopify store </b>
            and <b>courier services</b>. This guide provides an easy-to-follow
            outline of the order booking process.
          </p>
          <Text as="span" fontWeight="bold">
            Steps to Follow to Book an Order
          </Text>
          <List type="bullet" gap="loose">
            <List.Item>
              Go to your <b>store</b> then <b>Orders</b>. (figure 1)
            </List.Item>
            <List.Item>
              <b>Click</b> the order you want to book. (figure 1)
            </List.Item>
            <List.Item>
              From <b>More Actions</b>, click on <b>BOOKEASE</b>. (figure 1)
            </List.Item>
            <List.Item>
              You will be redirected to <b>BookEase order booking form/page</b>.
              (figure 2)
            </List.Item>
            <List.Item>
              All the order data will be <b>already populated</b> (
              <b>you don’t have to add manually</b>). (figure 2)
            </List.Item>
            <List.Item>
              Verify courier & order data and hit the <b>Book Order button</b>.
              (figure 2)
            </List.Item>
            <List.Item>
              The order will be <b>booked</b> and <b>fulfilled</b> on Shopify as
              well. You will see the <b>post-booking page</b>. (figure 2)
            </List.Item>
          </List>
          <Text as="span" fontWeight="bold">
            Figure 1
          </Text>
          <Image source={bookOrderGr1} alt="merge order docs screen shots" />
          <Text as="span" fontWeight="bold">
            Figure 2
          </Text>
          <Image source={bookOrderGr2} alt="merge order docs screen shots" />
          <Text as="span" fontWeight="bold">
            Figure 3
          </Text>
          <Image source={bookOrderGr3} alt="merge order docs screen shots" />
        </BlockStack>
      </Card>
    </Page>
  );
};

export default BookOrderDocs;
