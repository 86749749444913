/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./BaseQuery";

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => "/get-user",
      transformResponse: (response: any) => response,
      providesTags: ["User"],
    }),
    getQrCode: builder.query({
      query: () => "/get-qr-code",
      transformResponse: (response: any) => response?.qrCodeDataUrl,
      keepUnusedDataFor: 0,
    }),
    verifyQrCode: builder.mutation<void, void>({
      query: (body) => ({
        url: "/verify-qr-code",
        method: "POST",
        body,
      }),
    }),
    verifyAuthCode: builder.mutation<void, void>({
      query: ({ code }) => {
        const twoFactorToken = localStorage.getItem("auth_token");
        return {
          url: "/verify-authenticator-code",
          method: "POST",
          body: { code, twoFactorToken },
        };
      },
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetQrCodeQuery,
  useVerifyQrCodeMutation,
  useVerifyAuthCodeMutation,
} = profileApi;
