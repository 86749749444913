/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./BaseQuery";

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Orders", "dashboard"],
  endpoints: (builder) => ({
    fetchOrders: builder.query({
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }: any) => ({ type: "Orders", id })),
              "Orders",
            ]
          : ["Orders"],
      query: ({
        query,
        track,
        couriers,
        page,
        limit,
        status,
        dest,
        bookingFrom,
        bookingTo,
        deliveredTo,
        bookedBy,
        deliveredFrom,
        days,
        op,
        tag,
        phone,
      }) => {
        return `/get-all-orders?query=${query || ""}&city=${
          dest || ""
        }&status=${status || ""}&bookingDateFrom=${
          bookingFrom || ""
        }&bookingDateTo=${bookingTo || ""}&deliveredDateFrom=${
          deliveredFrom || ""
        }&deliveredDateTo=${deliveredTo || ""}&trackingNumber=${
          track || ""
        }&company=${couriers || ""}&page=${page}&limit=${
          Number(limit) || 50
        }&days=${days}&op=${op}&tag=${tag}&phone=${phone}&bookedBy=${bookedBy}`;
      },
    }),
    trackOrder: builder.query({
      query: (id) => `/get-order-from-shopify/${id}`,
      transformResponse: (resp: any) => resp.orderData,
    }),
    fetchOrdersForMerge: builder.query({
      query: ({ ordersIdsArray }) => {
        return `/get-orders-from-shopify?id=${
          ordersIdsArray?.length > 0 ? ordersIdsArray?.join(",") : ""
        }`;
      },
      transformResponse: (resp: any) => resp,
      keepUnusedDataFor: 1,
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `/delete-order`,
        method: "DELETE",
        body: { orderId: id },
      }),
      invalidatesTags: ["Orders", "dashboard"],
    }),
    bookOrder: builder.mutation({
      query: (orderDetails) => {
        return {
          url: `/book-order`,
          method: "POST",
          body: orderDetails,
        };
      },
      invalidatesTags: ["Orders", "dashboard"],
    }),
    bookOrders: builder.mutation({
      query: (orderDetails) => {
        return {
          url: `/book-orders`,
          method: "POST",
          body: {
            ...orderDetails,
            order_id: orderDetails.order_id
              ? orderDetails?.order_id?.trim()?.split(",")
              : [],
            order_name: orderDetails.order_name
              ? orderDetails?.order_name?.trim()?.split(",")
              : [],
          },
        };
      },
      invalidatesTags: ["Orders", "dashboard"],
    }),
    cancelBooking: builder.mutation({
      query: (trackingNumber) => ({
        url: `/cancel-booking/${trackingNumber}`,
        method: "PUT",
      }),
      invalidatesTags: ["Orders", "dashboard"],
    }),
  }),
});

export const {
  useFetchOrdersQuery,
  useTrackOrderQuery,
  useFetchOrdersForMergeQuery,
  useDeleteOrderMutation,
  useBookOrderMutation,
  useBookOrdersMutation,
  useCancelBookingMutation,
} = orderApi;
