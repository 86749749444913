import {
  Badge,
  Banner,
  BlockStack,
  Card,
  Image,
  List,
  Page,
  Text,
} from "@shopify/polaris";
import userPageView from "../assets/docs/users1.png";
import newUserImage from "../assets/docs/create-account.gif";

const UsersManagement = () => {
  return (
    <Page
      title="Users management"
      titleMetadata={<Badge tone="success">Only for admin</Badge>}
    >
      <Card padding={"500"}>
        <BlockStack gap={"400"}>
          <p>
            Here, you will learn everything you need to know about{" "}
            <b>User Management</b> and how to create new users, assign them
            specific access scopes, and control their access to various
            functionalities within the system.
          </p>
          <Text as="h1" fontWeight="bold" variant="headingMd">
            What is user management?
          </Text>
          <p>
            <b>User Management in BookEase</b> refers to a feature where{" "}
            <b>admins can create multiple users</b> and{" "}
            <b>assign them limited access</b> based on their specific roles and
            responsibilities. Each user operates within their assigned scope,
            ensuring they only have access to the parts of the system necessary
            for their tasks. This functionality enhances <b>security</b> by
            protecting sensitive data and restricting unauthorized access, while
            also enabling users to perform their duties efficiently within the
            platform.
          </p>

          <Text as="h1" fontWeight="bold" variant="headingMd">
            Page view
          </Text>
          <Image source={userPageView} alt="users page view" />
          <Banner tone="info">
            <p>
              <b>User Management</b> is entirely accessible to the <b>admin</b>{" "}
              only, ensuring that control over user roles and permissions is
              restricted to authorized personnel.
            </p>
          </Banner>
          <Text as="h2" fontWeight="bold" variant="headingSm">
            Type
          </Text>
          <p>
            There are two types of users in Bookease: <b>Admin</b> and{" "}
            <b>User</b>. The <b>Admin</b> is the default user type with full
            access to the system, including managing user roles and permissions.
            All other users created by the <b>Admin</b> are assigned the{" "}
            <b>User</b> type, with limited access based on their granted
            permissions.
          </p>
          <Text as="h2" fontWeight="bold" variant="headingSm">
            Tag
          </Text>
          <p>
            The <b>Tag</b> will be added to the Shopify order that was booked by
            the user. This tag helps identify the order within Shopify and track
            the actions or user responsible for booking it.
          </p>
          <Text as="h2" fontWeight="bold" variant="headingSm">
            Status
          </Text>
          <p>
            There are two user statuses in Bookease: <b>Active</b> and{" "}
            <b>Deactivated</b>. Only an <b>Active</b> user can log in and use
            Bookease. A <b>Deactivated</b> user will not be able to access or
            use the platform until their status is changed back to <b>Active</b>
            .
          </p>
          <Text as="h2" fontWeight="bold" variant="headingSm">
            Edit Action
          </Text>
          <p>
            The <b>Edit</b> action allows you to modify user details. Only an{" "}
            <b>Admin</b> can edit users, including changing their roles,
            statuses, and other permissions.
          </p>
          <Text as="h2" fontWeight="bold" variant="headingSm">
            Deactivate Button
          </Text>
          <p>
            The <b>Deactivate</b> button is used to deactivate a user,
            preventing them from logging in and using Bookease. Once a user is
            deactivated, they will be logged out immediately if they request
            anything from the server, ensuring that they no longer have access
            to the platform.
          </p>
          <Text as="h2" fontWeight="bold" variant="headingSm">
            Access scopes
          </Text>
          <p>Orders</p>
          <List>
            <List.Item>
              <b>READ_ORDERS:</b> Allows the user to view orders and apply
              filters for efficient order management.
            </List.Item>
            <List.Item>
              <b>WRITE_ORDERS:</b> Grants the ability to book new orders, cancel
              existing bookings, and track orders.
            </List.Item>
            <List.Item>
              <b>UPDATE_ORDERS:</b> Enables users to cancel orders. <br />
              <i>(Note: Requires READ_ORDERS access.)</i>
            </List.Item>
            {/* <List.Item>
              <b>EXPORT_ORDERS:</b> Allows the export of orders. <br />
              <i>(Note: Requires READ_ORDERS access.)</i>
            </List.Item> */}
          </List>

          <p>Accounts</p>
          <List>
            <List.Item>
              <b>READ_ACCOUNTS:</b> Grants visibility to tracking accounts.
            </List.Item>
            <List.Item>
              <b>WRITE_ACCOUNTS:</b> Allows the creation of new tracking
              accounts.
            </List.Item>
          </List>

          <p>Settings</p>
          <List>
            <List.Item>
              <b>READ_SETTINGS:</b> Allows users to view the default settings of
              the store.
            </List.Item>
            <List.Item>
              <b>WRITE_SETTINGS:</b> Permits modifications to the store's
              default settings.
            </List.Item>
          </List>

          <h2>Analytics</h2>
          <List>
            <List.Item>
              <b>READ_ANALYTICS:</b> Enables the user to access and view the
              dashboard.
            </List.Item>
          </List>
          <div style={{ marginTop: "2rem" }}></div>
          <Text as="h1" fontWeight="bold" variant="headingMd">
            How to create a user?
          </Text>

          <Image source={newUserImage} alt="new user create" />
          <p>
            Click on the <b>Create User</b> button to open the new user form. In
            this form, you will need to enter the user’s credentials, including
            their username and a <b>strong password</b> to ensure account
            security.
          </p>
          <Text as="h2" fontWeight="bold" variant="headingSm">
            Required Fields
          </Text>
          <p>
            When creating a user, the following fields are <b>required</b>:
            <ul>
              <li>
                <b>First Name</b>
              </li>
              <li>
                <b>Last Name</b>
              </li>
              <li>
                <b>Email</b>
              </li>
              <li>
                <b>Password</b> (ensure a strong password for security)
              </li>
              <li>
                <b>Tag</b> (optional, for order identification)
              </li>
            </ul>
            Additionally, an <b>access scope</b> must be assigned to the user to
            define their permissions and access within Bookease.
          </p>
          <p>
            After that, click <b>Create user</b> button. It will create a user
            with selective scopes and redirect you to users page again.
            <br />
            User can login and change password from profile too.
          </p>
        </BlockStack>
      </Card>
    </Page>
  );
};

export default UsersManagement;
