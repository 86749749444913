import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Autocomplete,
  Badge,
  Button,
  FormLayout,
  Icon,
  LegacyCard,
  Modal,
  Page,
  PageActions,
  Select,
  TextField,
} from "@shopify/polaris";
import { SearchIcon, DeliveryFilledIcon } from "@shopify/polaris-icons";
import { escapeRegExp, isValidObjectId } from "../../utils/helpers";
import { Controller, useForm } from "react-hook-form";
import { useGetAccountsQuery } from "../../store/store/slices/AccountRTK";
import { useFetchCitiesQuery } from "../../store/store/slices/CitiesRTK";
import { useNavigate } from "react-router-dom";
import { useBookOrdersMutation } from "../../store/store/slices/OrderRTK";
import { ACCOUNT } from "../../store/types/BookOrder";
import { MERGE_ORDER_FORM_TYPES } from "../../store/types/MergeOrder";
import { AccountsDoc, recordsTypes } from "../../store/types/types";

const MergeOrderForm = forwardRef(
  (
    {
      // NEW
      orderMetaData,
      selectedOrder,
      pingOrdersMerged,
      fulfillments,
    }: MERGE_ORDER_FORM_TYPES,
    ref
  ) => {
    const {
      control,
      watch,
      reset,
      handleSubmit,
      getValues,
      setValue,
      formState: { errors },
    } = useForm();
    const navigateTo = useNavigate();
    const [options, setOptions] = useState([]);
    const [modalActivator, setModalActivator] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const { isLoading, data: accountsData } = useGetAccountsQuery(undefined);
    const [
      bookOrders,
      { isLoading: ordersBookingLoading, isSuccess, isError, data },
    ] = useBookOrdersMutation();
    const {
      data: cities,
      isLoading: cityFetchingLoading,
      isFetching: citiesRefetching,
      isError: isCityFetchingFailed,
      refetch,
    } = useFetchCitiesQuery(watch("tracking_account"), {
      skip: !isValidObjectId(watch("tracking_account")),
    });
    const trackingAccount = watch("tracking_account");
    const updateText = useCallback(
      (value: string) => {
        setInputValue(value);
        if (value === "") {
          setOptions(cities);
          return;
        }
        const filterRegex = new RegExp(escapeRegExp(value), "i");
        const resultOptions = cities.filter((option: recordsTypes) =>
          option.label.match(filterRegex)
        );
        setOptions(resultOptions);
      },
      [cities]
    );

    const updateSelection = useCallback(
      (selected: string[]) => {
        const selectedId = selected[0];
        const matchedOption = cities?.find((option: recordsTypes) => {
          if (typeof option?.value === "number")
            return option?.value === Number(selectedId);
          return option?.value === selectedId;
        });
        if (matchedOption) {
          setSelectedOptions([matchedOption.value]);
          setInputValue(matchedOption.label);
        } else {
          setSelectedOptions([]);
          setInputValue("");
        }
      },
      [cities]
    );

    useEffect(() => {
      if (trackingAccount) {
        refetch();
        const selectedAccount = accountsData?.accounts?.find(
          (account: ACCOUNT) => account._id === trackingAccount
        );
        setValue("weight", selectedAccount?.weight || "100");
      }
    }, [trackingAccount]);

    useEffect(() => {
      if (accountsData?.accounts?.length === 0) {
        setModalActivator(true);
      } else {
        reset((prev) => {
          return {
            ...prev,
            tracking_account: accountsData?.accounts[0]?._id,
          };
        });
      }
    }, [accountsData]);

    useEffect(() => {
      if (selectedOrder) {
        reset({
          order_id: orderMetaData?.id,
          pieces: orderMetaData?.pieces || 1,
          tracking_account: accountsData?.accounts[0]?._id,
          cod_amount: orderMetaData?.cod,
          total_shipping_price: orderMetaData?.shippingPrice,
          average_shipping_price: orderMetaData?.shippingIsZero
            ? 0
            : orderMetaData?.avgShipping,
          cod_without_shipping: orderMetaData?.codWithoutShipping,
          order_name: orderMetaData?.name,
          weight: accountsData?.accounts[0]?.weight?.toString() || "100",
          consignee_name: selectedOrder?.consignee_fullname || "",
          consignee_email: selectedOrder?.consignee_email || "",
          consignee_phone: selectedOrder?.consignee_phone1 || "",
          consignee_address: selectedOrder?.consignee_address || "",
          consignee_phone2: selectedOrder?.consignee_phone2 || "",
          consignee_phone3: selectedOrder?.consignee_phone3 || "",
          remarks: "",
          products_list: orderMetaData?.productsList,
        });
      }
    }, [selectedOrder, orderMetaData]);

    useEffect(() => {
      if (cities && selectedOrder) {
        setOptions(cities);
        const city = cities?.filter(
          (city: recordsTypes) =>
            city?.label?.toLowerCase() ===
            selectedOrder?.consignee_city?.trim()?.toLowerCase()
        );
        if (city?.length > 0) {
          updateSelection([city[0]?.value]);
        } else {
          updateSelection("");
        }
      }
    }, [cities, selectedOrder]);

    const textField = (
      <Autocomplete.TextField
        onChange={updateText}
        label="City"
        value={inputValue}
        prefix={<Icon source={SearchIcon} tone="base" />}
        placeholder="Search"
        autoComplete="off"
        disabled={
          cityFetchingLoading || citiesRefetching || isCityFetchingFailed
        }
        loading={cityFetchingLoading || citiesRefetching}
        requiredIndicator
        error={
          isCityFetchingFailed
            ? "City fetching failed"
            : inputValue?.trim() === ""
            ? "Please add destination city"
            : ""
        }
      />
    );
    const tracking_account_value = watch("tracking_account");

    const onBookOrderHandler = async () => {
      const formData = getValues();
      await bookOrders({
        ...formData,
        // line_items: fetchedShopifyOrder?.orderData?.data?.order?.lineItems,
        city: selectedOptions[0],
        destination_city: inputValue,
        courier_company: tracking_account_value,
        fulfillments,
        tracking_account: tracking_account_value,
      });
    };
    useEffect(() => {
      if (isSuccess) {
        pingOrdersMerged(data?.postBookingData);
      }
    }, [isError, isLoading, data]);
    const bookingSubmitHandle = async () => {
      handleSubmit(onBookOrderHandler)();
    };
    useImperativeHandle(ref, () => ({
      bookingSubmitHandle,
    }));
    return (
      <Page narrowWidth>
        <Modal
          onClose={() => {
            setModalActivator(false);
            navigateTo("/dashboard/accounts/create-account");
          }}
          open={modalActivator}
          title="Ooops! You have no tracking account."
          primaryAction={{
            content: "Create Account",
            onAction: () => {
              setModalActivator(false);
              navigateTo("/dashboard/accounts/create-account");
            },
          }}
        >
          <Modal.Section>
            <p>
              A tracking account is necessary for booking and tracking orders,
              as it links your shipments with real-time updates on their status.
              Without it, managing deliveries and providing accurate support
              isn’t possible. Please set up a tracking account to proceed.
            </p>
          </Modal.Section>
        </Modal>
        <LegacyCard sectioned title="Tracking Account">
          <FormLayout>
            <FormLayout.Group>
              <Controller
                control={control}
                name="tracking_account"
                disabled={ordersBookingLoading}
                defaultValue={tracking_account_value}
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={isLoading}
                    label="Tracking Account"
                    options={accountsData?.accounts.map(
                      (account: AccountsDoc) => ({
                        label: `${account.name} - ${account.company}`,
                        value: account._id,
                      })
                    )}
                  />
                )}
              />
            </FormLayout.Group>
          </FormLayout>
        </LegacyCard>
        {tracking_account_value?.trim() !== "" && (
          <>
            <LegacyCard sectioned title="Order Information">
              <FormLayout>
                <FormLayout.Group condensed>
                  {/* shipping type */}
                  <Controller
                    control={control}
                    name="shipping_type"
                    defaultValue={"overnight"}
                    render={({ field }) => (
                      <Select
                        {...field}
                        disabled={isLoading}
                        label="Shipping Type"
                        options={[{ label: "overnight", value: "overnight" }]}
                        value={"overnight"}
                        error={
                          errors.shipping_type && "Shipping type  is required"
                        }
                      />
                    )}
                  />
                  {/* order id */}
                  <Controller
                    control={control}
                    name="order_id"
                    rules={{ required: "Order id required" }}
                    render={({ field }) => (
                      <TextField
                        readOnly
                        label="Order Id"
                        {...field}
                        autoComplete="Off"
                        disabled={isLoading}
                        error={errors.order_id && "Order id is required"}
                        requiredIndicator
                      />
                    )}
                  />
                  {/* pieces */}
                  <Controller
                    control={control}
                    name="pieces"
                    rules={{ required: "Piece is required" }}
                    render={({ field }) => (
                      <TextField
                        label="No. of pieces"
                        type="number"
                        {...field}
                        disabled={isLoading}
                        autoComplete="Off"
                        requiredIndicator
                        min={1}
                        error={errors.pieces && "Please specify no. of piece"}
                      />
                    )}
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  {/* weight */}
                  <Controller
                    control={control}
                    name="weight"
                    rules={{ required: "Weight is required" }}
                    render={({ field }) => (
                      <TextField
                        label="Net Weight"
                        type="number"
                        {...field}
                        disabled={isLoading}
                        autoComplete="Off"
                        connectedRight={<Button disabled>grams</Button>}
                        requiredIndicator
                        min={1}
                        error={errors.weight && "Weight is required"}
                        step={1}
                      />
                    )}
                  />
                  {/* order name */}
                  <Controller
                    control={control}
                    name="order_name"
                    rules={{ required: "Order name is required" }}
                    render={({ field }) => (
                      <TextField
                        label="Order Name"
                        type="text"
                        {...field}
                        disabled={isLoading}
                        autoComplete="Off"
                        requiredIndicator
                        min={0}
                        error={errors.order_name && "Order name is required"}
                        step={100}
                      />
                    )}
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  {/* cod */}
                  <Controller
                    control={control}
                    name="cod_without_shipping"
                    rules={{ required: "COD is required" }}
                    render={({ field }) => (
                      <TextField
                        label="COD (without shipping)"
                        type="number"
                        {...field}
                        onChange={(value) => {
                          setValue(
                            "cod_amount",
                            Number(watch("average_shipping_price")) +
                              Number(value)
                          );
                          field.onChange(value);
                        }}
                        disabled={isLoading}
                        autoComplete="off"
                        connectedRight={<Button disabled>Rs</Button>}
                        requiredIndicator
                        min={0}
                        step={100}
                        error={
                          errors.cod_without_shipping &&
                          "Cod amount is required"
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="average_shipping_price"
                    rules={{ required: "shipping is required" }}
                    render={({ field }) => (
                      <TextField
                        label={
                          <div style={{ display: "inline" }}>
                            {orderMetaData?.shippingIsZero ? (
                              <Badge
                                tone="info"
                                size="small"
                                icon={DeliveryFilledIcon}
                              >
                                Free shipping
                              </Badge>
                            ) : (
                              "Avg. shipping"
                            )}
                          </div>
                        }
                        type="number"
                        {...field}
                        onChange={(value) => {
                          setValue(
                            "cod_amount",
                            Number(watch("cod_without_shipping")) +
                              Number(value)
                          );
                          field.onChange(value);
                        }}
                        disabled={isLoading}
                        autoComplete="off"
                        connectedRight={<Button disabled>Rs</Button>}
                        requiredIndicator
                        min={0}
                        step={100}
                        error={
                          errors.average_shipping_price && "Avg is required"
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="cod_amount"
                    rules={{ required: "COD is required" }}
                    defaultValue={(
                      Number(watch("cod_without_shipping")) +
                      Number(watch("average_shipping_price"))
                    ).toFixed(2)}
                    render={({ field }) => (
                      <TextField
                        label="Total COD"
                        type="number"
                        readOnly
                        {...field}
                        disabled={isLoading}
                        autoComplete="off"
                        connectedRight={<Button disabled>Rs</Button>}
                        requiredIndicator
                        min={0}
                        step={100}
                        error={errors.cod_amount && "Cod amount is required"}
                      />
                    )}
                  />
                </FormLayout.Group>
              </FormLayout>
            </LegacyCard>
            <LegacyCard sectioned title="Consignee Information">
              <FormLayout>
                <FormLayout.Group condensed>
                  {/* consignee name */}
                  <Controller
                    control={control}
                    name="consignee_name"
                    rules={{ required: "Name is required" }}
                    render={({ field }) => (
                      <TextField
                        label="Consignee Name"
                        type="text"
                        {...field}
                        disabled={isLoading}
                        error={
                          errors.consignee_name && "Consignee name is required"
                        }
                        autoComplete="Off"
                        requiredIndicator
                        min={1}
                      />
                    )}
                  />
                  {/* consignee email */}
                  <Controller
                    control={control}
                    name="consignee_email"
                    render={({ field }) => (
                      <TextField
                        label="Consignee Email"
                        type="text"
                        {...field}
                        disabled={isLoading}
                        autoComplete="Off"
                      />
                    )}
                  />
                  {/* courier cities */}
                  <Autocomplete
                    options={options || []}
                    selected={selectedOptions}
                    onSelect={updateSelection}
                    textField={textField}
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <Controller
                    control={control}
                    name="consignee_phone"
                    rules={{ required: "Phone is required" }}
                    render={({ field }) => (
                      <TextField
                        label="Consignee Phone 1"
                        type="text"
                        {...field}
                        disabled={
                          isLoading || citiesRefetching || cityFetchingLoading
                        }
                        error={
                          errors.consignee_phone && "Phone number is required"
                        }
                        autoComplete="Off"
                        requiredIndicator
                        min={1}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="consignee_phone2"
                    render={({ field }) => (
                      <TextField
                        label="Consignee Phone 2"
                        type="text"
                        {...field}
                        disabled={
                          isLoading || citiesRefetching || cityFetchingLoading
                        }
                        autoComplete="Off"
                        min={1}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="consignee_phone3"
                    render={({ field }) => (
                      <TextField
                        label="Consignee Phone 3"
                        type="text"
                        {...field}
                        disabled={
                          isLoading || citiesRefetching || cityFetchingLoading
                        }
                        autoComplete="Off"
                        min={1}
                      />
                    )}
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <Controller
                    control={control}
                    rules={{ required: "Address is required" }}
                    name="consignee_address"
                    render={({ field }) => (
                      <TextField
                        label="Consignee Address"
                        type="text"
                        {...field}
                        disabled={
                          isLoading || citiesRefetching || cityFetchingLoading
                        }
                        autoComplete="Off"
                        requiredIndicator
                        min={3}
                        multiline={2}
                        error={
                          errors.consignee_address && "Address is required"
                        }
                      />
                    )}
                  />
                </FormLayout.Group>
              </FormLayout>
            </LegacyCard>
            <LegacyCard sectioned title="Product(s) Information">
              <FormLayout>
                <FormLayout.Group>
                  <Controller
                    control={control}
                    name="products_list"
                    rules={{ required: "Products detail is required" }}
                    render={({ field }) => (
                      <TextField
                        label={`Order Products List`}
                        multiline={3}
                        type="text"
                        {...field}
                        disabled={
                          isLoading || citiesRefetching || cityFetchingLoading
                        }
                        autoComplete="Off"
                        error={
                          errors.products_list && "Products details are missing"
                        }
                        requiredIndicator
                      />
                    )}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Controller
                    control={control}
                    name="remarks"
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        label="Remarks"
                        type="text"
                        {...field}
                        disabled={
                          isLoading || citiesRefetching || cityFetchingLoading
                        }
                        autoComplete="Off"
                        min={1}
                        multiline={2}
                      />
                    )}
                  />
                </FormLayout.Group>
              </FormLayout>
            </LegacyCard>
          </>
        )}
        <PageActions
          primaryAction={{
            content: "Book Order",
            disabled:
              selectedOptions?.length === 0 ||
              isLoading ||
              ordersBookingLoading,
            onAction: handleSubmit(onBookOrderHandler),
            loading: isLoading || ordersBookingLoading,
          }}
          // secondaryActions={
          //   <Button onClick={onChangeOrderHandler}>Change Order</Button>
          // }
        />
      </Page>
    );
  }
);

export default MergeOrderForm;
