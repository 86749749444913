import {
  Badge,
  BlockStack,
  Button,
  Checkbox,
  FormLayout,
  Modal,
  TextField,
} from "@shopify/polaris";
import { Controller, useForm } from "react-hook-form";
import { useChangePasswordByAdminMutation } from "../../store/store/slices/UsersRTK";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../store/store/slices/AlertSlice";

const ChangePassword = ({ user_id }: { user_id: string }) => {
  const {
    reset,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [changePassword, { isLoading, isError, isSuccess, error }] =
    useChangePasswordByAdminMutation();
  const [passwordConfirmationModal, setPasswordConfirmationModal] =
    useState(false);
  const [logoutAllDevices, setLogoutAllDevices] = useState<boolean>(false);
  const onCheckBoxChange = (checkStatus: boolean) => {
    setLogoutAllDevices(() => checkStatus);
  };
  const BUTTON_DISABLED = isLoading || !user_id || !watch("password");
  const onChangePasswordHandler = async () => {
    const values = getValues();
    await changePassword({
      id: user_id,
      ...values,
      logoutFromAllDevices: logoutAllDevices,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showToast({
          message: "Password has been changed",
          error: false,
          visible: true,
        })
      );
      reset({ password: "" });
    }
    if (isError) {
      dispatch(
        showToast({
          message: error?.data?.errors[0]?.msg,
          error: true,
          visible: true,
        })
      );
    }
  }, [isLoading, isError, isSuccess, error]);
  return (
    <>
      <BlockStack gap={"200"}>
        <FormLayout.Group>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                label={"Password"}
                type="password"
                autoComplete="Off"
                requiredIndicator={true}
                {...field}
                clearButton
                error={errors.password && "Password is required"}
                onClearButtonClick={() =>
                  reset((prev) => {
                    return { ...prev, password: "" };
                  })
                }
              />
            )}
          />
        </FormLayout.Group>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            size="micro"
            onClick={() => {
              setPasswordConfirmationModal(true);
            }}
            loading={isLoading}
            disabled={isLoading || BUTTON_DISABLED}
          >
            Change password
          </Button>
        </div>
      </BlockStack>
      <Modal
        onClose={() => setPasswordConfirmationModal(false)}
        open={passwordConfirmationModal}
        title="Password change confirmation"
        primaryAction={{
          content: "Change password",
          onAction: async () => {
            setPasswordConfirmationModal(false);
            await onChangePasswordHandler();
          },
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              setPasswordConfirmationModal(false);
            },
          },
        ]}
      >
        <Modal.Section>
          <BlockStack gap={"300"}>
            <p>Are you sure you want to change the password?</p>
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <div>
                <Checkbox
                  checked={logoutAllDevices}
                  onChange={onCheckBoxChange}
                  label={
                    <Badge tone="attention">Logout from all devices?</Badge>
                  }
                ></Checkbox>
              </div>
            </div>
          </BlockStack>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default ChangePassword;
