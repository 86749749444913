import { Pagination } from "@shopify/polaris";
// import RecordsRange from "./Range";

import { useState } from "react";
import { OrderPaginationTypes } from "../../store/types/types";

export default function OrderPagination({
  currentPage,
  totalPages,
  onPageChange,
  totalOrders,
}: OrderPaginationTypes) {
  const params = new URLSearchParams(window.location.search);
  const [recordsLimit] = useState(params.get("limit"));
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(String(currentPage - 1), recordsLimit || "50");
    }
  };
  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(String(currentPage + 1), recordsLimit || "50");
    }
  };
  return (
    <div
      style={{
        background: "none",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Pagination
        nextTooltip="Next"
        onPrevious={handlePrevious}
        onNext={handleNext}
        hasPrevious={currentPage > 1}
        type="page"
        hasNext={currentPage < totalPages}
        label={`${currentPage} - ${totalPages} of ${totalOrders} orders`}
      />
    </div>
  );
}
