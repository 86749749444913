import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const csvApi = createApi({
  reducerPath: "csvApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1",
  }),
  endpoints: (builder) => ({
    downloadCsv: builder.mutation({
      query: (idsArray) => ({
        url: "/download-csv",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
        body: JSON.stringify(idsArray),
      }),
      transformResponse: (response) => response,
    }),
  }),
});

export const { useDownloadCsvMutation } = csvApi;
