import {
  Badge,
  BlockStack,
  Box,
  Card,
  Grid,
  InlineStack,
  Link,
  Page,
  Text,
  Thumbnail,
} from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import {
  LINE_ITEM,
  POST_BOOKING_DATA_MAPPED,
} from "../../store/types/MergeOrder";

const PostBookingPage = ({ data }: POST_BOOKING_DATA_MAPPED) => {
  const navigateTo = useNavigate();
  return (
    <Page
      title={data?.name}
      titleMetadata={
        <Badge progress="complete" tone="success" size="small">
          Fullfilled
        </Badge>
      }
      subtitle={"Booked at  " + data?.bookedDate}
      backAction={{
        onAction: () => {
          navigateTo("/dashboard/orders");
        },
      }}
    >
      <Grid columns={{ xs: 1, md: 2 }}>
        <Grid.Cell columnSpan={{ md: 6, lg: 8 }}>
          <BlockStack gap={"400"}>
            <Card>
              <BlockStack gap={"200"}>
                <Text as="h1" fontWeight="medium">
                  Items detail
                </Text>
                {data?.lineItems?.map((product: LINE_ITEM) => (
                  <Box
                    borderRadius="200"
                    borderWidth="025"
                    borderColor="border-disabled"
                    paddingInline={"300"}
                    paddingBlock={"300"}
                    background="bg-fill"
                  >
                    <div style={{ display: "flex", gap: "1rem" }}>
                      <div>
                        <Thumbnail
                          size="small"
                          source={product?.image?.url}
                          alt="product image"
                        ></Thumbnail>
                      </div>
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: "3px",
                          }}
                        >
                          <BlockStack gap={"100"}>
                            <Text
                              variant="bodyMd"
                              fontWeight="medium"
                              as="span"
                            >
                              {product?.product?.title}{" "}
                              {product?.variant?.title !== "Default Title" && (
                                <Badge size="small" tone="info">
                                  {product?.variant?.title}
                                </Badge>
                              )}
                            </Text>
                            {/* <div> </div> */}
                          </BlockStack>
                          <Text as="span" variant="bodyMd">
                            Rs.
                            {
                              product?.originalUnitPriceSet?.presentmentMoney
                                ?.amount
                            }
                          </Text>
                        </div>
                        <BlockStack gap={"200"}>
                          <Text as="span" variant="bodyMd">
                            Qty : {product?.currentQuantity}
                          </Text>
                        </BlockStack>
                      </div>
                    </div>
                  </Box>
                ))}
              </BlockStack>
            </Card>
          </BlockStack>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ md: 4, lg: 4 }}>
          <BlockStack gap={"400"}>
            <Card>
              <BlockStack gap={"300"}>
                <Text as="h1" fontWeight="medium">
                  Remarks
                </Text>
                <p>
                  {data?.remarks?.trim() ? data?.remarks.trim() : "No remarks"}
                </p>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"300"}>
                <Text as="h1" fontWeight="medium">
                  Tracking details <Badge tone="new">{data?.courier}</Badge>
                </Text>
                <BlockStack gap={"100"}>
                  <InlineStack gap={"100"}>
                    <Link url={`/track/${data?.trackingNumber}`}>
                      {data?.trackingNumber}
                    </Link>
                  </InlineStack>
                </BlockStack>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"300"}>
                <Text as="h1" fontWeight="medium">
                  Consignee details
                </Text>
                <BlockStack gap={"100"}>
                  <p>{data?.consignee?.name}</p>
                  <p>{data?.consignee?.email}</p>
                  <p>{data?.consignee?.phone}</p>
                  {data?.consignee?.phone2 && <p>{data?.consignee?.phone2}</p>}
                  <p>{data?.consignee?.address}</p>
                  <p>{data?.destination}</p>
                </BlockStack>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"200"}>
                <Text as="h2" fontWeight="semibold" variant="headingSm">
                  Payment
                </Text>

                <BlockStack gap={"150"}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text as="span" variant="bodyMd">
                      COD amount
                    </Text>
                    <Text as="span" variant="headingSm">
                      Rs.{Number(data?.cod).toFixed(2)}
                    </Text>
                  </div>
                </BlockStack>
              </BlockStack>
            </Card>
            {/* <Card>
              <BlockStack gap={"300"}>
                <Text as="h1" fontWeight="medium">
                  Products list
                </Text>
                <BlockStack gap={"100"}>
                  {data?.products_list?.split(",")?.map((detail: string) => (
                    <p>{detail}</p>
                  ))}
                </BlockStack>
              </BlockStack>
            </Card> */}
          </BlockStack>
        </Grid.Cell>
      </Grid>
    </Page>
  );
};

export default PostBookingPage;
