/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Badge,
  Button,
  Icon,
  Tooltip,
  Banner,
} from "@shopify/polaris";
import { StarIcon, AlertTriangleIcon } from "@shopify/polaris-icons";
import { EditIcon } from "@shopify/polaris-icons";
import { AccountsDoc } from "../../store/types/types";
import { useNavigate } from "react-router-dom";
import { useGetTableAccountsQuery } from "../../store/store/slices/AccountRTK";

export default function AccountsTable() {
  const navigate = useNavigate();
  const {
    data: accounts,
    isLoading,
    isFetching,
    error,
    isError,
  } = useGetTableAccountsQuery<any>(undefined);
  const { handleSelectionChange } = useIndexResourceState(accounts);
  const rowMarkup =
    !isLoading &&
    accounts?.accounts?.map(
      (
        {
          _id,
          name,
          company,
          is_default,
          in_active,
          address_code,
          weight,
        }: AccountsDoc,
        index: number
      ) => (
        <IndexTable.Row
          id={_id}
          key={_id}
          selected={false}
          position={index}
          rowType="data"
        >
          <IndexTable.Cell className="capitalize">{name}</IndexTable.Cell>
          <IndexTable.Cell>
            <Badge
              tone={`${
                !in_active
                  ? company === "LEOPARD"
                    ? "info"
                    : company === "MNP"
                    ? "magic"
                    : "success"
                  : "enabled"
              }`}
            >
              {company}
            </Badge>
          </IndexTable.Cell>
          <IndexTable.Cell className="capitalize">
            {address_code}
          </IndexTable.Cell>
          <IndexTable.Cell>{weight}</IndexTable.Cell>
          <IndexTable.Cell>
            {is_default ? (
              <Tooltip
                content={
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Default account is automatically selected while booking the
                    order
                  </p>
                }
                hasUnderline
                width="wide"
              >
                <span style={{ fontSize: "6px", cursor: "pointer" }}>
                  <Badge tone="success-strong" icon={StarIcon} size="small">
                    DEFAULT
                  </Badge>
                </span>
              </Tooltip>
            ) : (
              ""
            )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Badge tone={(!in_active && "magic") || "enabled"}>
              {in_active ? "Inactive" : "Active"}
            </Badge>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Button
              variant="monochromePlain"
              accessibilityLabel="edit account button"
              onClick={() => navigate(`/dashboard/edit-account/${_id}`)}
            >
              {(<Icon source={EditIcon} tone="base" />) as any}
            </Button>
          </IndexTable.Cell>
        </IndexTable.Row>
      )
    );

  if (isError) {
    return (
      <Banner icon={AlertTriangleIcon} tone="warning">
        {error?.data?.errors[0].msg}
      </Banner>
    );
  }

  return (
    <LegacyCard>
      <IndexTable
        hasZebraStriping
        selectable={false}
        resourceName={{ singular: "account", plural: "accounts" }}
        itemCount={accounts?.accounts?.length || 0}
        onSelectionChange={handleSelectionChange}
        loading={isFetching || isLoading}
        headings={[
          { title: "Account Name" },
          { title: "Account Company" },
          { title: "Address code" },
          {
            title: "Weight",
            tooltipContent: "Standard weight of corresponding courier",
            tooltipWidth: "wide",
          },
          { title: "Type" },
          {
            title: "Activity",
            tooltipWidth: "wide",
            tooltipContent: (
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: 1.3,
                  textAlign: "center",
                }}
              >
                In-active accounts will not be appeared on booking page.
              </p>
            ),
          },
          { title: "Actions" },
        ]}
      >
        {rowMarkup}
      </IndexTable>
    </LegacyCard>
  );
}
