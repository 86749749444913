import { Button } from "@shopify/polaris";
import { EditIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";

interface EditUserIdType {
  id: string;
}

const EditUserPopover = ({ id }: EditUserIdType) => {
  const navigate = useNavigate();
  return (
    <Button
      size="slim"
      onClick={() => navigate("/dashboard/users/" + id)}
      icon={EditIcon}
    >
      Edit
    </Button>
  );
};

export default EditUserPopover;
