import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export interface User {
  username: string;
  storeName: string;
  free_shipping_value: number;
  default_shipping: number;
  default_weight: number;
  access_scopes: [string];
}

export interface AuthState {
  user: User | null;
  authToken: string | null;
  loading: boolean;
  error: string | null;
  isAuthorized: boolean | string;
}

const initialState: AuthState = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  authToken: null,
  isAuthorized: localStorage.getItem("auth_token") ? true : false,
  loading: false,
  error: null,
};

export const login = createAsyncThunk(
  "auth/userData",
  async (credentials: { token: string }, { rejectWithValue }) => {
    try {
      const response = await fetch("/api/v1/me", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${credentials?.token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to log in");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);

// Updated logout action: hitting the API without waiting for the response
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      // Hit the logout API route but don't wait for the response
      fetch("/api/v1/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("auth_token") || "",
        },
      }).catch((error) => console.error("Logout API error:", error));
      // Reset the state after hitting the logout route
      state.user = null;
      state.authToken = null;
      state.isAuthorized = false;
      localStorage.removeItem("auth_token");
      localStorage.removeItem("user");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isAuthorized = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthorized = true;
        state.user = action.payload.data;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.isAuthorized = false;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
