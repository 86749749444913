import {
  Autocomplete,
  Banner,
  Button,
  FormLayout,
  Icon,
  LegacyCard,
  Modal,
  Page,
  PageActions,
  Select,
  TextField,
} from "@shopify/polaris";
import {
  useBookOrderMutation,
  useTrackOrderQuery,
} from "../store/store/slices/OrderRTK";
import {
  ACCOUNT,
  BOOK_ORDER_FORM_TYPES,
  CITY_OPTIONS_LIST,
  CITY_TYPE,
} from "../store/types/BookOrder";

import { useDispatch } from "react-redux";
import { escapeRegExp } from "../utils/helpers";
import { SearchIcon } from "@shopify/polaris-icons";
import { Controller, useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../store/store/slices/AlertSlice";
import PostBookingPage from "../components/atoms/PostBookingPage";
import { useFetchCitiesQuery } from "../store/store/slices/CitiesRTK";
import { useGetActiveAccountsQuery } from "../store/store/slices/AccountRTK";

const CreateOrder = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [options, setOptions] = useState<CITY_OPTIONS_LIST[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [postBookingPage, setPostBookingPage] = useState<boolean>(false);
  const {
    data: userAccounts,
    isSuccess,
    isError: isAccountError,
  } = useGetActiveAccountsQuery(undefined);
  const id = new URLSearchParams(useLocation().search).get("id");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const {
    data: fetchedShopifyOrder,
    isFetching,
    isError,
    error: shopifyError,
  } = useTrackOrderQuery(id);
  const {
    reset,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<BOOK_ORDER_FORM_TYPES>();
  const trackingAccount = watch("tracking_account");
  const {
    data: cities,
    isLoading: cityFetchingLoading,
    isError: isCityFetchingFailed,
    isFetching: citiesRefetching,
    refetch,
  } = useFetchCitiesQuery(trackingAccount, {
    skip: !trackingAccount,
  });

  const [modalActivator, setModalActivator] = useState<boolean>(false);
  const [
    bookOrder,
    {
      isLoading,
      isError: bookingFailed,
      error,
      isSuccess: isBooked,
      data: postBookedOrderData,
    },
  ] = useBookOrderMutation();
  useEffect(() => {
    if (fetchedShopifyOrder) {
      reset({
        remarks: "",
        weight: userAccounts?.accounts[0]?.weight?.toString() || "100",
        order_id: fetchedShopifyOrder.order_id,
        pieces: fetchedShopifyOrder?.pieces || "",
        cod_amount: fetchedShopifyOrder?.cod_amount,
        order_name: fetchedShopifyOrder?.order_name,
        products_list: fetchedShopifyOrder?.products_list,
        tracking_account: userAccounts?.accounts[0]?._id,
        consignee_phone: fetchedShopifyOrder?.consignee_phone1,
        consignee_phone2: fetchedShopifyOrder?.consignee_phone2,
        consignee_phone3: fetchedShopifyOrder?.consignee_phone3,
        consignee_address: fetchedShopifyOrder?.consignee_address,
        consignee_email: fetchedShopifyOrder?.consignee_email || "",
        consignee_name: fetchedShopifyOrder?.consignee_fullname || "",
      });
    }
  }, [fetchedShopifyOrder]);
  useEffect(() => {
    if (userAccounts?.accounts?.length === 0) setModalActivator(true);
  }, [isSuccess, isAccountError, userAccounts]);
  useEffect(() => {
    if (isBooked) {
      dispatch(
        showToast({
          message: "Order has been booked.",
          error: false,
          visible: true,
        })
      );
      // booking successfull
      setPostBookingPage(true);
    }
    if (bookingFailed) {
      // booking failed
      setPostBookingPage(false);
    }
  }, [isBooked, bookingFailed, error]);
  useEffect(() => {
    setOptions(cities);
    const city = cities?.filter(
      (city: CITY_TYPE) =>
        city?.label?.toLowerCase() ===
        fetchedShopifyOrder?.consignee_city?.toLowerCase()
    );
    if (city?.length > 0) {
      updateSelection([city[0]?.value]);
    } else {
      updateSelection([""]);
    }
  }, [cities]);

  useEffect(() => {
    if (trackingAccount) {
      refetch();
      const selectedAccount = userAccounts?.accounts?.find(
        (account: ACCOUNT) => account._id === trackingAccount
      );
      setValue("weight", selectedAccount?.weight || "100");
    }
  }, [trackingAccount]);

  const onBookOrderHandler = async (formData: BOOK_ORDER_FORM_TYPES) => {
    await bookOrder({
      ...formData,
      line_items: fetchedShopifyOrder?.orderData?.data?.order?.lineItems,
      city: selectedOptions[0],
      destination_city: inputValue,
      courier_company:
        userAccounts?.accounts[
          userAccounts?.accounts?.findIndex(
            (el: ACCOUNT) => el?._id === formData?.tracking_account
          )
        ]?.company,
    }).unwrap();
  };

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);
      if (value === "") {
        setOptions(cities);
        return;
      }
      const filterRegex = new RegExp(escapeRegExp(value), "i");
      const resultOptions = cities.filter((option: CITY_TYPE) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [cities]
  );
  const updateSelection = useCallback(
    (selected: string[]) => {
      const selectedId = selected[0];
      const matchedOption = cities?.find((option: CITY_TYPE) => {
        if (typeof option?.value === "number")
          return option?.value === Number(selectedId);
        return option?.value === selectedId;
      });
      if (matchedOption) {
        setSelectedOptions([matchedOption.value]);
        setInputValue(matchedOption.label);
      } else {
        setSelectedOptions([]);
        setInputValue("");
      }
    },
    [cities]
  );

  if (isError && !isLoading) {
    const alreadyFulfilled =
      shopifyError?.data?.errors[0]?.msg?.toLowerCase()?.trim() ===
      "order is already fulfilled on shopify.";

    return (
      <Page
        title="Book Order"
        narrowWidth
        primaryAction={{
          content: "Book Order",
          disabled: isLoading || selectedOptions?.length === 0,
          loading: isLoading,
        }}
      >
        <Banner
          // title="Error occurred"
          // onDismiss={() => {}}
          tone={alreadyFulfilled ? "success" : "warning"}
          // ref={banner}
        >
          <p>{shopifyError?.data?.errors[0]?.msg}</p>
        </Banner>
      </Page>
    );
  }
  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label="City"
      value={inputValue}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder="Search"
      autoComplete="off"
      disabled={cityFetchingLoading || citiesRefetching || isCityFetchingFailed}
      loading={cityFetchingLoading || citiesRefetching}
      requiredIndicator
      error={
        isCityFetchingFailed
          ? "City fetching failed"
          : inputValue?.trim() === ""
          ? "Please add destination city"
          : ""
      }
    />
  );

  // if order is booked
  if (postBookingPage) {
    return <PostBookingPage data={postBookedOrderData?.postBookingData} />;
  }

  return (
    <Page
      title="Book Order"
      narrowWidth
      primaryAction={{
        content: "Book Order",
        disabled: isLoading || selectedOptions?.length === 0,
        onAction: handleSubmit(onBookOrderHandler),
        loading: isFetching || isLoading || citiesRefetching,
      }}
    >
      <Modal
        onClose={() => {
          setModalActivator(false);
          navigateTo("/dashboard/accounts/create-account");
        }}
        open={modalActivator}
        title="Ooops! You have no tracking account."
        primaryAction={{
          content: "Create Account",
          onAction: () => {
            setModalActivator(false);
            navigateTo("/dashboard/accounts/create-account");
          },
        }}
      >
        <Modal.Section>
          <p>
            A tracking account is necessary for booking and tracking orders, as
            it links your shipments with real-time updates on their status.
            Without it, managing deliveries and providing accurate support isn’t
            possible. Please set up a tracking account to proceed.
          </p>
        </Modal.Section>
      </Modal>
      <LegacyCard sectioned title="Tracking Account">
        <FormLayout>
          <FormLayout.Group>
            <Controller
              control={control}
              name="tracking_account"
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={isFetching || isLoading}
                  label="Tracking Account"
                  options={userAccounts?.accounts.map((account: ACCOUNT) => ({
                    label: `${account.name} - ${account.company}`,
                    value: account._id,
                  }))}
                />
              )}
            />
          </FormLayout.Group>
        </FormLayout>
      </LegacyCard>
      {trackingAccount?.trim() !== "" && (
        <>
          <LegacyCard sectioned title="Order Information">
            <FormLayout>
              <FormLayout.Group condensed>
                <Controller
                  control={control}
                  name="shipping_type"
                  defaultValue={"overnight"}
                  render={({ field }) => (
                    <Select
                      {...field}
                      disabled={
                        isFetching ||
                        isLoading ||
                        citiesRefetching ||
                        cityFetchingLoading ||
                        !trackingAccount
                      }
                      label="Shipping Type"
                      options={[{ label: "overnight", value: "overnight" }]}
                      value={"overnight"}
                      error={
                        errors.shipping_type && "Shipping type  is required"
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="order_id"
                  rules={{ required: "Order id required" }}
                  render={({ field }) => (
                    <TextField
                      readOnly
                      label="Order Id"
                      {...field}
                      autoComplete="Off"
                      disabled={
                        isFetching ||
                        isLoading ||
                        citiesRefetching ||
                        cityFetchingLoading ||
                        !trackingAccount
                      }
                      error={errors.order_id && "Order id is required"}
                      requiredIndicator
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="pieces"
                  rules={{ required: "Piece is required" }}
                  render={({ field }) => (
                    <TextField
                      label="No. of pieces"
                      type="number"
                      {...field}
                      disabled={
                        isFetching ||
                        isLoading ||
                        citiesRefetching ||
                        cityFetchingLoading ||
                        !trackingAccount
                      }
                      autoComplete="Off"
                      requiredIndicator
                      min={1}
                      error={errors.pieces && "Please specify no. of piece"}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="weight"
                  rules={{ required: "Weight is required" }}
                  render={({ field }) => (
                    <TextField
                      label="Net Weight (grams)"
                      type="number"
                      {...field}
                      disabled={
                        isFetching ||
                        isLoading ||
                        citiesRefetching ||
                        cityFetchingLoading ||
                        !trackingAccount
                      }
                      autoComplete="Off"
                      // connectedRight={<Button disabled>Grams</Button>}
                      requiredIndicator
                      min={1}
                      error={errors.weight && "Weight is required"}
                      step={1}
                    />
                  )}
                />
              </FormLayout.Group>
              <FormLayout.Group condensed>
                <Controller
                  control={control}
                  name="cod_amount"
                  rules={{ required: "COD is required" }}
                  render={({ field }) => (
                    <TextField
                      label={<div style={{ display: "inline-block" }}>COD</div>}
                      type="number"
                      {...field}
                      disabled={
                        isFetching ||
                        isLoading ||
                        citiesRefetching ||
                        cityFetchingLoading ||
                        !trackingAccount
                      }
                      autoComplete="off"
                      connectedRight={<Button disabled>Rs</Button>}
                      requiredIndicator
                      min={0}
                      step={100}
                      error={errors.cod_amount && "Cod amount is required"}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="order_name"
                  rules={{ required: "Order name is required" }}
                  render={({ field }) => (
                    <TextField
                      label="Order Name"
                      type="text"
                      {...field}
                      disabled={
                        isFetching ||
                        isLoading ||
                        citiesRefetching ||
                        cityFetchingLoading ||
                        !trackingAccount
                      }
                      autoComplete="Off"
                      requiredIndicator
                      min={0}
                      error={errors.order_name && "Order name is required"}
                      step={100}
                    />
                  )}
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
          <LegacyCard sectioned title="Consignee Information">
            <FormLayout>
              <FormLayout.Group condensed>
                <Controller
                  control={control}
                  name="consignee_name"
                  rules={{ required: "Name is required" }}
                  render={({ field }) => (
                    <TextField
                      label="Consignee Name"
                      type="text"
                      {...field}
                      disabled={
                        isFetching ||
                        isLoading ||
                        citiesRefetching ||
                        cityFetchingLoading ||
                        !trackingAccount
                      }
                      error={
                        errors.consignee_name && "Consignee name is required"
                      }
                      autoComplete="Off"
                      requiredIndicator
                      min={1}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="consignee_email"
                  render={({ field }) => (
                    <TextField
                      label="Consignee Email"
                      type="text"
                      {...field}
                      disabled={
                        isFetching ||
                        isLoading ||
                        citiesRefetching ||
                        cityFetchingLoading ||
                        !trackingAccount
                      }
                      autoComplete="Off"
                      min={1}
                    />
                  )}
                />
                <Autocomplete
                  options={options || []}
                  selected={selectedOptions}
                  onSelect={updateSelection}
                  textField={textField}
                />
              </FormLayout.Group>
              <FormLayout.Group condensed>
                <Controller
                  control={control}
                  name="consignee_phone"
                  rules={{ required: "Phone is required" }}
                  render={({ field }) => (
                    <TextField
                      label="Consignee Phone 1"
                      type="text"
                      {...field}
                      disabled={
                        isFetching ||
                        isLoading ||
                        citiesRefetching ||
                        cityFetchingLoading ||
                        !trackingAccount
                      }
                      error={
                        errors.consignee_phone && "Phone number is required"
                      }
                      autoComplete="Off"
                      requiredIndicator
                      min={1}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="consignee_phone2"
                  render={({ field }) => (
                    <TextField
                      label="Consignee Phone 2"
                      type="text"
                      {...field}
                      disabled={
                        isFetching ||
                        isLoading ||
                        citiesRefetching ||
                        cityFetchingLoading ||
                        !trackingAccount
                      }
                      autoComplete="Off"
                      min={1}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="consignee_phone3"
                  render={({ field }) => (
                    <TextField
                      label="Consignee Phone 3"
                      type="text"
                      {...field}
                      disabled={
                        isFetching ||
                        isLoading ||
                        citiesRefetching ||
                        cityFetchingLoading ||
                        !trackingAccount
                      }
                      autoComplete="Off"
                      min={1}
                    />
                  )}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Controller
                  control={control}
                  rules={{ required: "Address is required" }}
                  name="consignee_address"
                  render={({ field }) => (
                    <TextField
                      label="Consignee Address"
                      type="text"
                      {...field}
                      disabled={
                        isFetching ||
                        isLoading ||
                        citiesRefetching ||
                        cityFetchingLoading ||
                        !trackingAccount
                      }
                      autoComplete="Off"
                      requiredIndicator
                      multiline={3}
                      error={errors.consignee_address && "Address is required"}
                    />
                  )}
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
          <LegacyCard sectioned title="Product(s) Information">
            <FormLayout>
              <FormLayout.Group>
                <Controller
                  control={control}
                  name="products_list"
                  rules={{ required: "Products detail is required" }}
                  render={({ field }) => (
                    <TextField
                      label={`Order Products List`}
                      multiline={3}
                      type="text"
                      {...field}
                      disabled={
                        isFetching ||
                        isLoading ||
                        citiesRefetching ||
                        cityFetchingLoading ||
                        !trackingAccount
                      }
                      autoComplete="Off"
                      error={
                        errors.products_list && "Products details are missing"
                      }
                      requiredIndicator
                    />
                  )}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Controller
                  control={control}
                  name="remarks"
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      label="Remarks"
                      type="text"
                      {...field}
                      disabled={
                        isFetching ||
                        isLoading ||
                        citiesRefetching ||
                        cityFetchingLoading ||
                        !trackingAccount
                      }
                      autoComplete="Off"
                      min={1}
                      multiline={2}
                    />
                  )}
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </>
      )}
      <PageActions
        primaryAction={{
          content: "Book Order",
          disabled: selectedOptions?.length === 0,
          onAction: handleSubmit(onBookOrderHandler),
          loading: isFetching || isLoading,
        }}
      />
    </Page>
  );
};

export default CreateOrder;
