/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link as ReactRouterLink } from "react-router-dom";

function Link({ children, url = "", ...rest }: any) {
  // Use an regular a tag for external and download links
  if (isOutboundLink(url) || rest.download) {
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
}

function isOutboundLink(url: string) {
  return /^(?:[a-z][a-z\d+.-]*:|\/\/)/.test(url);
}

export default Link;
