import { Page, BlockStack, List, Card, Badge, Button } from "@shopify/polaris";

const GettingStarted = () => {
  return (
    <Page title="Welcome" secondaryActions={<Button>Read docs</Button>}>
      <Card>
        <BlockStack gap="500">
          <p>
            <Badge tone="magic">BOOKEASE</Badge> is a powerful Shopify app
            designed to streamline order management and enhance logistics
            operations for your store. It offers a range of features to provide
            security, efficiency, and control over your business processes.
          </p>
          <div>
            Here are following <b>features</b> of bookease:
          </div>
          <List type="bullet">
            <List.Item>
              <b>Order Management</b>:
              <List type="bullet">
                <List.Item>
                  Streamlines <b>order booking</b> and enhances{" "}
                  <b>logistics operations</b> for Shopify stores.
                </List.Item>{" "}
              </List>
            </List.Item>
            <List.Item>
              <b>Multi-user System</b>:
              <List type="bullet">
                <List.Item>
                  Admins can create multiple <b>users</b> with defined{" "}
                  <b>roles</b> and <b>access scopes</b>.
                </List.Item>{" "}
                <List.Item>
                  Enhances <b>security</b> by limiting user access to sensitive
                  data based on their responsibilities.
                </List.Item>{" "}
              </List>
            </List.Item>
            <List.Item>
              <b>Two-Factor Authentication (2FA)</b>:
              <List type="bullet">
                <List.Item>
                  Adds an extra layer of <b>security</b> by requiring a{" "}
                  <b>time-based code</b> from an <b>authentication app</b>.
                </List.Item>{" "}
                <List.Item>
                  Ensures only authorized users can access the system,
                  protecting critical <b>information</b>.
                </List.Item>{" "}
              </List>
            </List.Item>
            <List.Item>
              <b>Tracking Accounts Management</b>:
              <List type="bullet">
                <List.Item>
                  Simplifies integration of multiple{" "}
                  <b>courier tracking accounts</b>.
                </List.Item>{" "}
                <List.Item>
                  Enables users to manage and monitor <b>tracking accounts</b>,
                  centralizing logistics operations.
                </List.Item>{" "}
              </List>
            </List.Item>
            <List.Item>
              <b>Security & Efficiency</b>:
              <List type="bullet">
                <List.Item>
                  Combines <b>security features</b> (<b>2FA</b>,{" "}
                  <b>role-based access</b>) with <b>operational efficiencies</b>{" "}
                  (<b>order booking</b>, <b>tracking account management</b>).
                </List.Item>{" "}
                <List.Item>
                  Optimizes <b>courier management</b> for better logistics{" "}
                  <b>accuracy</b> and <b>customer satisfaction</b>.
                </List.Item>{" "}
              </List>
            </List.Item>
            <List.Item>
              <b>Live tracking</b>:
              <List type="bullet">
                <List.Item>
                  The app automatically tracks all <b>INPROCESS</b> and{" "}
                  <b>FULFILLED</b> orders every 24 hours, ensuring that order
                  tracking details are always up to date without manual
                  intervention.
                </List.Item>{" "}
              </List>
            </List.Item>
          </List>

          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button variant="primary">Read docs</Button>
          </div>
        </BlockStack>
      </Card>
    </Page>
  );
};

export default GettingStarted;
