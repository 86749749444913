/* eslint-disable @typescript-eslint/no-explicit-any */
import { Banner, IndexTable, LegacyCard, Page } from "@shopify/polaris";
import { AlertTriangleIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { useGetChildAccountsQuery } from "../store/store/slices/UsersRTK";
import { ChildAccountTypes } from "../store/types/types";
import UserTableRow from "../components/atoms/users/UserTableRow";

export default function UsersTable() {
  const navigateTo = useNavigate();
  const {
    data: childAccounts,
    isLoading,
    isFetching,
    error,
    isError,
  } = useGetChildAccountsQuery<any>({});

  if (isError) {
    return (
      <Page title="Users">
        <Banner icon={AlertTriangleIcon} tone="warning">
          {error?.data?.errors[0].msg}
        </Banner>
      </Page>
    );
  }
  return (
    <Page
      title="Users"
      backAction={{
        onAction: () => {
          navigateTo("/dashboard/accounts");
        },
      }}
      primaryAction={{
        content: "Create user",
        onAction: () => {
          navigateTo("/dashboard/create-user");
        },
      }}
    >
      <LegacyCard>
        <IndexTable
          loading={isLoading || isFetching}
          hasZebraStriping
          selectable={false}
          resourceName={{ singular: "user", plural: "users" }}
          itemCount={childAccounts?.length || 0}
          headings={[
            { title: "Name" },
            { title: "Email" },
            { title: "Type" },
            { title: "Tag" },
            { title: "Scopes" },
            {
              title: "Status",
              tooltipContent: (
                <p style={{ textAlign: "center" }}>
                  Restrcited won't be able to login
                </p>
              ),
              flush: true,
              tooltipWidth: "wide",
            },
            { title: "Actions" },
          ]}
        >
          {childAccounts?.map((account: ChildAccountTypes, index: number) => (
            <UserTableRow key={index} account={account} index={index} />
          ))}
        </IndexTable>
      </LegacyCard>
    </Page>
  );
}
