/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  Text,
  Badge,
  Page,
  Button,
  BlockStack,
  ChoiceList,
  TextField,
  DatePicker,
  IndexFiltersMode,
  Select,
  Banner,
  Tooltip,
  Combobox,
  Listbox,
  Icon,
  AutoSelection,
} from "@shopify/polaris";
import {
  AlertTriangleIcon,
  PersonIcon,
  RefreshIcon,
  SearchIcon,
} from "@shopify/polaris-icons";
import dayjs from "dayjs";
import { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import formatDate from "../hooks/useDate";
import CancelBooking from "../components/atoms/CancelBooking";
import ModalComponent from "../components/atoms/Modal";
import OrderPagination from "../components/atoms/Pagination";
import { useFetchOrdersQuery } from "../store/store/slices/OrderRTK";
import AppModal from "../components/atoms/Popup";
import "./../styles/Filter.css";
import { removeEmptyElements } from "../utils/helpers";
import { useGetChildAccountsWithLimitedDataQuery } from "../store/store/slices/UsersRTK";
import { UserListFiltersType, UserStr } from "../store/types/Orders";
import { recordsTypes } from "../store/types/types";

export default function IndexFiltersDefaultExample() {
  const { data: childAccounts, isSuccess: accountsFetched } =
    useGetChildAccountsWithLimitedDataQuery<any>({});
  const params = new URLSearchParams(window.location.search);
  const appliedFilters: any = [];
  const navigateTo = useNavigate();
  const [cod, setCod] = useState("");
  const [trackId, setTrackId] = useState("");
  const [selected, setSelected] = useState(0);
  const [status, setStatus] = useState<string[]>(
    params.get("status")?.split(",") || []
  );
  const [courier, setCourier] = useState<string[]>(
    params.get("courier")?.split(",") || []
  );
  const [queryValue, setQueryValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [bookedBy, setBookedBy] = useState<string[]>(
    params?.get("bookedBy")?.split(",") || []
  );
  const [deliveryDaysOp, setDeliveryDaysOp] = useState("");
  const [sortSelected, setSortSelected] = useState(["order desc"]);
  const [dest, setDestination] = useState(params.get("dest") || "");
  const [track_number, setTrackNumber] = useState(params.get("track") || "");
  const [deliveryDays, setDeliveryDays] = useState(params.get("days") || "");
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);
  const [phoneNumber, setPhoneNumber] = useState(params.get("phone_num") || "");
  const [tag, setTag] = useState<string[]>(params.get("tag")?.split(",") || []);
  // booking date - CHANGE
  const [{ month, year }, setDate] = useState<any>({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState<any>(null);
  const [{ month: dmonth, year: dyear }, setDelivery] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDeliveryDates, setSelectedDeliveryDates] = useState<any>(null);
  const deselectedOptions = accountsFetched
    ? childAccounts?.map((user: UserListFiltersType) => ({
        label: user?.first_name + " " + user?.last_name,
        value: user?._id,
      }))
    : [];
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(deselectedOptions);
  const { data, refetch, isLoading, isError, isFetching, error } =
    useFetchOrdersQuery<any>({
      limit: params.get("limit"),
      tag: params.get("tag") || "",
      status: params.get("status"),
      page: params.get("page") || 1,
      dest: params.get("dest") || "",
      days: params.get("days") || "",
      query: params.get("query") || "",
      track: params.get("track") || "",
      op: params.get("op") || "GREATER",
      phone: params.get("phone_num") || "",
      couriers: params.get("courier") || "",
      bookedBy: params.get("bookedBy") || "",
      bookingTo: params.get("bookingto") || "",
      bookingFrom: params.get("bookingfrom") || "",
      deliveredTo: params.get("deliveredto") || "",
      deliveredFrom: params.get("deliveredfrom") || "",
    });

  useEffect(() => {
    setQueryValue(params.get("query") || "");
    // Define an object with all the parameters
    const queryParams = {
      query: params.get("query"),
      track: params.get("track"),
      courier: params.get("courier"),
      status: params.get("status"),
      bookingfrom: params.get("bookingfrom"),
      bookingto: params.get("bookingto"),
      dest: params.get("dest"),
      deliveredfrom: params.get("deliveredfrom"),
      deliveredto: params.get("deliveredto"),
      page: params.get("page") || "1",
      limit: params.get("limit") || "50",
      days: params.get("days"),
      op: params.get("op") || "GREATER",
      tag: params.get("tag"),
      bookedBy: params.get("bookedBy"),
    };

    // Filter out any parameters that are null or empty
    const queryString = Object.entries(queryParams)
      .filter(([, value]) => value !== null && value !== "")
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    navigateTo(`/dashboard/orders?${queryString}`);
  }, []);

  useEffect(() => {
    const bookedByText = bookedBy?.filter((str: string) => str.trim());
    let bookedbyStr = "";
    if (bookedByText?.length > 0) {
      bookedbyStr = bookedByText?.join(",");
    }
    params.set("bookedBy", bookedbyStr);
    navigateTo({ search: params.toString() });
  }, [bookedBy]);

  const openModalHandler = (id: string) => {
    setTrackId(id);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const paginationChangeHandler = (page: string, limit: string) => {
    params.set("page", page);
    params.set("limit", limit);
    navigateTo(`?${params.toString()}`);
    refetch();
  };

  const handleMonthChange = useCallback(
    (month: number, year: number) => setDate({ month, year }),
    []
  );
  const handleDeliveryDateChange = useCallback(
    (month: number, year: number) => setDelivery({ month, year }),
    []
  );
  const handleStatusChange = useCallback((value: string[]) => {
    params.set("status", value.toString());
    setStatus(value);
    // navigateTo({ search: params.toString() });
    onFilterChangeRefetchHandler();
  }, []);

  const handleCourierChange = useCallback((value: string[]) => {
    params.set("courier", value.toString());
    setCourier(value);
    // navigateTo({ search: params.toString() });
    onFilterChangeRefetchHandler();
  }, []);
  // const handleBookedByChange = useCallback((value: string[]) => {
  //   params.set("bookedBy", value.toString());
  //   setBookedBy(value);
  //   // navigateTo({ search: params.toString() });
  //   onFilterChangeRefetchHandler();
  // }, []);
  const handlePhoneChange = useCallback((value: string) => {
    params.set("phone_num", value);
    setPhoneNumber(value);
    // navigateTo({ search: params.toString() });
    onFilterChangeRefetchHandler();
  }, []);
  const handleTagChange = useCallback((value: string[]) => {
    params.set("tag", removeEmptyElements(value).toString());
    setTag(value);
    onFilterChangeRefetchHandler();
  }, []);
  const removeTag = useCallback(() => {
    params.set("tag", "");
    setTag([]);
    onFilterChangeRefetchHandler();
  }, []);
  const debounceTimerRef = useRef<any | null>(null);
  const handleFiltersQueryChange = useCallback((value: string) => {
    const str = value?.trim() ? value?.replace("#", "") : value;
    setQueryValue(value);

    params.set("query", str);
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(() => {
      onFilterChangeRefetchHandler();
    }, 500);
  }, []);
  const handleRemoveDate = useCallback(() => {
    params.set("bookingfrom", "");
    params.set("bookingto", "");
    setSelectedDates(null);
    onFilterChangeRefetchHandler();
  }, []);
  const handleRemoveDeliveryDate = useCallback(() => {
    params.set("deliveredfrom", "");
    params.set("deliveredto", "");
    setSelectedDeliveryDates(null);
    onFilterChangeRefetchHandler();
  }, []);
  const handleAvailabilityRemove = useCallback(() => {
    setStatus([]);
    params.set("status", "");
    onFilterChangeRefetchHandler();
  }, []);
  const handleBookedByRemove = useCallback(() => {
    setBookedBy([]);
    params.set("bookedBy", "");
    onFilterChangeRefetchHandler();
  }, []);
  const handleProductTypeRemove = useCallback(() => {
    setCourier([]);
    params.set("courier", "");
    onFilterChangeRefetchHandler();
  }, []);
  const handleTrackNumberRemove = useCallback(() => {
    params.set("track", "");
    setTrackNumber("");
    onFilterChangeRefetchHandler();
  }, []);
  const handlePhoneNumberRemove = useCallback(() => {
    params.set("phone_num", "");
    setPhoneNumber("");
    onFilterChangeRefetchHandler();
  }, []);
  const handleDestinationRemove = useCallback(() => {
    params.set("dest", "");
    setDestination("");
    onFilterChangeRefetchHandler();
  }, []);
  const handleCodRemove = useCallback(() => {
    params.set("cod", "");
    setCod("");
    onFilterChangeRefetchHandler();
  }, []);
  const handleDaysRemove = useCallback(() => {
    params.set("days", "");
    params.set("op", "GREATER");
    setDeliveryDays("");
    setDeliveryDaysOp("GREATER");
    onFilterChangeRefetchHandler();
  }, []);
  const onFilterChangeRefetchHandler = () => {
    navigateTo({ search: params.toString() });
  };

  const escapeSpecialRegExCharacters = useCallback(
    (value: string) => value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
    []
  );

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);
      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }
      const filterRegex = new RegExp(escapeSpecialRegExCharacters(value), "i");
      const resultOptions = deselectedOptions.filter((option: recordsTypes) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions, escapeSpecialRegExCharacters]
  );

  const updateSelection = useCallback(
    (selected: string) => {
      if (bookedBy.includes(selected)) {
        setBookedBy(() => bookedBy.filter((id) => id !== selected));
      } else {
        setBookedBy((prev) => {
          if (prev?.length === 0) {
            return [selected];
          }
          return [...prev, selected];
        });
      }
    },
    [bookedBy, updateText]
  );

  const optionsMarkup =
    options.length > 0
      ? options.map((option: recordsTypes) => {
          const { label, value } = option;
          return (
            <Listbox.Option
              key={`${value}`}
              value={value}
              selected={bookedBy.includes(value)}
              accessibilityLabel={label}
            >
              {label}
            </Listbox.Option>
          );
        })
      : null;
  const bookedByFilterMarkup = (
    <div style={{ height: "max-content" }}>
      <Combobox
        allowMultiple
        activator={
          <Combobox.TextField
            prefix={<Icon source={SearchIcon} />}
            onChange={updateText}
            label="Search users"
            labelHidden
            value={inputValue}
            placeholder="Search users"
            autoComplete="off"
          />
        }
      >
        {optionsMarkup ? (
          <Listbox
            autoSelection={AutoSelection.None}
            onSelect={updateSelection}
          >
            {optionsMarkup}
          </Listbox>
        ) : null}
      </Combobox>
    </div>
  );

  const handleFiltersClearAll = useCallback(() => {
    handleRemoveDeliveryDate();
    handleAvailabilityRemove();
    handleProductTypeRemove();
    handleTrackNumberRemove();
    handlePhoneNumberRemove();
    handleDestinationRemove();
    handleBookedByRemove();
    handleRemoveDate();
    handleCodRemove();
    removeTag();
  }, [
    handleRemoveDeliveryDate,
    handleAvailabilityRemove,
    handleProductTypeRemove,
    handleTrackNumberRemove,
    handleDestinationRemove,
    handlePhoneNumberRemove,
    handleBookedByRemove,
    handleRemoveDate,
    handleCodRemove,
    removeTag,
  ]);

  const filters = [
    {
      key: "status",
      label: "Status",
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={[
            { label: "FULFILLED", value: "FULFILLED" },
            { label: "INPROCESS", value: "INPROCESS" },
            { label: "DELIVERED", value: "DELIVERED" },
            { label: "CANCELLED", value: "CANCELLED" },
            { label: "RTS", value: "RTS" },
          ]}
          selected={status || []}
          onChange={handleStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "bookedBy",
      label: "Booked by",
      filter: bookedByFilterMarkup,
      shortcut: true,
    },
    // {
    //   key: "bookedBy",
    //   label: "Booked by",
    //   filter: (
    //     <ChoiceList
    //       title="bookedBy"
    //       titleHidden
    //       choices={usersOptions}
    //       selected={bookedBy || []}
    //       onChange={handleBookedByChange}
    //       allowMultiple
    //     />
    //   ),
    //   shortcut: true,
    // },
    {
      key: "tag",
      label: "Tag",
      filter: (
        <ChoiceList
          title="Tag"
          titleHidden
          choices={[{ label: "MERGED", value: "MERGED" }]}
          selected={removeEmptyElements(tag) || []}
          onChange={handleTagChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "courier",
      label: "Courier",
      filter: (
        <ChoiceList
          title="Product type"
          titleHidden
          choices={[
            { label: "LEOPARD", value: "LEOPARD" },
            { label: "MNP", value: "MNP" },
            { label: "POSTEX", value: "POSTEX" },
          ]}
          selected={courier || []}
          onChange={handleCourierChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "track_number",
      label: "Track #",
      filter: (
        <TextField
          value={track_number}
          onChange={(val) => {
            params.set("track", val);
            setTrackNumber(val);
            onFilterChangeRefetchHandler();
          }}
          autoComplete="Off"
          labelHidden
          label="track number"
        />
      ),
    },
    {
      key: "phone_num",
      label: "Phone number",
      filter: (
        <TextField
          value={phoneNumber}
          onChange={handlePhoneChange}
          autoComplete="Off"
          labelHidden
          label="Phone number"
        />
      ),
    },
    {
      key: "days",
      label: "Delivery days",
      filter: (
        <TextField
          value={deliveryDays}
          type="number"
          onChange={(val) => {
            params.set("days", val);
            setDeliveryDays(val);
            onFilterChangeRefetchHandler();
          }}
          autoComplete="Off"
          connectedRight={
            <Select
              label="delivery days"
              labelHidden
              options={[
                { label: "Select operator", value: "" },
                { label: "Greater than", value: "GREATER" },
                { label: "Less than", value: "LESS" },
                { label: "Equal", value: "EQUAL" },
              ]}
              onChange={(val) => {
                params.set("op", val);
                setDeliveryDaysOp(val);
                onFilterChangeRefetchHandler();
              }}
              value={deliveryDaysOp}
            />
          }
          labelHidden
          label="delivery days"
        />
      ),
    },
    {
      key: "dest",
      label: "Destination city",
      filter: (
        <TextField
          value={dest}
          onChange={(val) => {
            params.set("dest", val);
            setDestination(val);
            onFilterChangeRefetchHandler();
          }}
          autoComplete="Off"
          labelHidden
          label="track number"
        />
      ),
    },
    {
      key: "booking_date",
      label: "Order booking date",
      filter: (
        <DatePicker
          month={month}
          year={year}
          onChange={(dt) => {
            params.set("bookingfrom", dt.start.toDateString());
            params.set("bookingto", dt.end.toDateString());
            setSelectedDates(dt);
            onFilterChangeRefetchHandler();
          }}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
          multiMonth
          allowRange
        />
      ),
    },
    {
      key: "delivered_date",
      label: "Order delivered date",
      filter: (
        <DatePicker
          month={dmonth}
          year={dyear}
          onChange={(dd) => {
            params.set("deliveredfrom", dd.start.toDateString());
            params.set("deliveredto", dd.end.toDateString());
            setSelectedDeliveryDates(dd);
            onFilterChangeRefetchHandler();
          }}
          onMonthChange={handleDeliveryDateChange}
          selected={selectedDeliveryDates}
          multiMonth
          allowRange
        />
      ),
    },
  ];
  const statuses = params.get("status") || "";
  const track_num = params.get("track") || "";
  const courier_array: any = params.get("courier") || [];
  const dest_city = params.get("dest") || "";
  const bookingFrom = params.get("bookingfrom") || null;
  const bookingto = params.get("bookingto") || null;
  const deliveredfrom = params.get("deliveredfrom") || null;
  const deliveredto = params.get("deliveredto") || null;
  const days = params.get("days") || "";
  const op = params.get("op") || "";
  const tags = params.get("tag") || "";
  const phonenum = params.get("phone_num") || "";
  const bookedByStr = params.get("bookedBy") || "";
  if (!isEmptyString(statuses)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: "Status : " + disambiguateLabel(key, statuses?.split(",") || []),
      onRemove: handleAvailabilityRemove,
    });
  }

  if (!isEmptyString(bookedByStr)) {
    const selectedUsers = childAccounts?.filter((user: UserStr) =>
      bookedByStr?.split(",")?.includes(user?._id)
    );

    const key = "bookedBy";
    appliedFilters.push({
      key,
      label:
        "Booked by : " +
        disambiguateLabel(
          key,
          selectedUsers?.length > 0
            ? selectedUsers.map(
                (user: UserListFiltersType) =>
                  ` ${user?.first_name} ${user?.last_name}`
              )
            : []
        ),
      onRemove: handleBookedByRemove,
    });
  }
  if (!isEmptyString(tags)) {
    const key = "tag";
    appliedFilters.push({
      key,
      label: "Tag : " + disambiguateLabel(key, tags?.split(",") || []),
      onRemove: removeTag,
    });
  }

  if (!isEmptyString(days)) {
    const key = "days";
    appliedFilters.push({
      key,
      label: `Delivery : ${days}`,
      onRemove: handleDaysRemove,
    });
  }
  if (!isEmptyString(phonenum)) {
    const key = "phone_num";
    appliedFilters.push({
      key,
      label: `Phone : ${phonenum}`,
      onRemove: handlePhoneNumberRemove,
    });
  }
  if (!isEmptyString(op)) {
    const key = "op";
    appliedFilters.push({
      key,
      label: `Days condition : ${deliveryDaysOp}`,
      onRemove: handleDaysRemove,
    });
  }

  if (!isEmpty(courier_array)) {
    const key = "courier";
    appliedFilters.push({
      key,
      label: `Courier : ${disambiguateLabel(
        key,
        courier_array?.split(",") || []
      )}`,
      onRemove: handleProductTypeRemove,
    });
  }
  if (!isEmptyString(track_num)) {
    const key = "track_number";
    appliedFilters.push({
      key,
      label: `Tracking number : ${track_num}`,
      onRemove: handleTrackNumberRemove,
    });
  }
  if (!isEmptyString(cod)) {
    const key = "cod";
    appliedFilters.push({
      key,
      label: `COD : ${cod}`,
      onRemove: handleCodRemove,
    });
  }
  if (!isEmptyString(dest_city)) {
    const key = "dest";
    appliedFilters.push({
      key,
      label: `City : ${dest_city}`,
      onRemove: handleDestinationRemove,
    });
  }
  if (bookingFrom && bookingto) {
    const booking_from = new Date(bookingFrom);
    const booking_to = new Date(bookingto);
    const key = "booking_date";
    appliedFilters.push({
      key,
      label: `Booking Date : ${booking_from.toDateString()} to ${booking_to.toDateString()}`,
      onRemove: handleRemoveDate,
    });
  }
  if (deliveredfrom && deliveredto) {
    const deliver_from = new Date(deliveredfrom);
    const deliver_to = new Date(deliveredto);
    const key = "delivered_date";
    appliedFilters.push({
      key,
      label: `Delivery Date : ${deliver_from.toDateString()} to ${deliver_to.toDateString()}`,
      onRemove: handleRemoveDeliveryDate,
    });
  }

  const renderOrders = () => {
    return data?.data.map(
      ({
        _id,
        tracking_number,
        booking_date,
        booked_packet_status,
        destination_city,
        delivered_date,
        tracking_account,
        order_name,
        cod,
        createdAt,
        booked_by,
        tag,
      }: any) => (
        <IndexTable.Row
          id={_id}
          key={_id}
          position={_id}
          onClick={() => openModalHandler(tracking_number)}
          tone={`${booked_packet_status === "CANCELLED" && "subdued"}` as any}
        >
          <IndexTable.Cell>
            <p style={{ maxWidth: "179px", textWrap: "wrap" }}>
              <Text
                variant="bodyMd"
                fontWeight="bold"
                as="span"
                textDecorationLine={
                  `${
                    booked_packet_status === "CANCELLED" && "line-through"
                  }` as any
                }
              >
                {order_name}
              </Text>
            </p>
          </IndexTable.Cell>
          <IndexTable.Cell>{tracking_number}</IndexTable.Cell>
          <IndexTable.Cell className="line">
            <Badge
              tone={
                tracking_account?.company === "LEOPARD"
                  ? "info"
                  : tracking_account?.company === "MNP"
                  ? "magic"
                  : "success"
              }
            >
              {tracking_account?.company}
            </Badge>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {createdAt ? formatDate(createdAt) : "N/A"}
          </IndexTable.Cell>
          <IndexTable.Cell>{cod}</IndexTable.Cell>
          <IndexTable.Cell className="capitalize">
            {destination_city}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Badge
              progress={
                booked_packet_status === "DELIVERED"
                  ? "complete"
                  : booked_packet_status === "CANCELLED"
                  ? "complete"
                  : booked_packet_status === "FULFILLED"
                  ? "incomplete"
                  : "partiallyComplete"
              }
              tone={
                booked_packet_status === "DELIVERED"
                  ? "success"
                  : booked_packet_status === "CANCELLED" ||
                    booked_packet_status === "RTS"
                  ? "critical"
                  : "enabled"
              }
            >
              {booked_packet_status}
            </Badge>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {delivered_date ? formatDate(delivered_date) : "N/A"}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {booked_by?.tag && (
              <Badge tone="new" icon={PersonIcon}>
                {booked_by?.tag}
              </Badge>
            )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {delivered_date
              ? dayjs(delivered_date).diff(dayjs(booking_date), "day")
              : "N/A"}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Button
              onClick={() => navigateTo(`/track/${tracking_number}`)}
              variant="primary"
              size="micro"
              accessibilityLabel="order tracking button"
            >
              Track
            </Button>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {booked_packet_status !== "CANCELLED" &&
              booked_packet_status !== "DELIVERED" && (
                <CancelBooking
                  tracking_number={tracking_number}
                  company={tracking_account?.company}
                />
              )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {booked_packet_status !== "CANCELLED" &&
              booked_packet_status !== "DELIVERED" && (
                <Badge
                  tone={
                    `${
                      dayjs().diff(dayjs(booking_date), "day") > 3
                        ? "critical"
                        : ""
                    }` as any
                  }
                >
                  {booking_date
                    ? dayjs()
                        .startOf("day")
                        .diff(dayjs(booking_date).startOf("day"), "day")
                    : ("N/A" as any)}
                </Badge>
              )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {tag && <Badge progress="complete">{tag}</Badge>}
          </IndexTable.Cell>
        </IndexTable.Row>
      )
    );
  };
  if (isError) {
    return (
      <Page title="Orders">
        <Banner icon={AlertTriangleIcon} tone="warning">
          {error?.data?.errors[0].msg}
        </Banner>
      </Page>
    );
  }
  return (
    <>
      <Page
        title="Orders"
        fullWidth
        secondaryActions={
          <Tooltip content="Refresh orders">
            <Button
              loading={isFetching}
              disabled={isFetching}
              onClick={() => {
                refetch();
              }}
              icon={RefreshIcon}
              accessibilityLabel="refresh order page"
            ></Button>
          </Tooltip>
        }
        backAction={{
          onAction: () => {
            navigateTo("/dashboard");
          },
        }}
      >
        <AppModal />

        <ModalComponent
          heading={"Order Tracking Info"}
          type={trackId && "TRACKING"}
          open={modalOpen}
          text={"Order Tracking Info"}
          key={Math.random()}
          close={closeModal}
          id={trackId}
        />
        <>
          <BlockStack gap="200">
            <LegacyCard>
              <IndexFilters
                sortOptions={[]}
                sortSelected={sortSelected}
                queryValue={queryValue}
                queryPlaceholder="Search by order id, tracking number, destination city, status and type e.g 'merged'"
                onQueryChange={handleFiltersQueryChange}
                onQueryClear={() => {
                  params.set("query", "");
                  setQueryValue("");
                  onFilterChangeRefetchHandler();
                }}
                onSort={setSortSelected}
                tabs={[]}
                selected={selected}
                borderlessQueryField
                onSelect={setSelected}
                canCreateNewView
                onCreateNewView={async () => true}
                filters={filters}
                appliedFilters={appliedFilters}
                onClearAll={handleFiltersClearAll}
                autoFocusSearchField={false}
                mode={mode}
                setMode={setMode}
              />
              <IndexTable
                resourceName={{ singular: "order", plural: "orders" }}
                loading={isLoading || isFetching}
                itemCount={
                  isLoading || isFetching ? 1 : data?.data?.length || 0
                }
                selectable={false}
                headings={[
                  {
                    title: "Order id",
                    tooltipContent: (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          lineHeight: 1,
                        }}
                      >
                        Order id given by shopify
                      </p>
                    ),
                    tooltipWidth: "default",
                  },
                  {
                    title: "Track number",
                  },

                  {
                    title: "Courier",
                  },
                  {
                    title: "Booking date",
                    tooltipContent: (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          lineHeight: 1,
                        }}
                      >
                        When order was booked
                      </p>
                    ),
                    tooltipWidth: "default",
                  },
                  {
                    title: "Cash on delivery",
                    tooltipContent: (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          lineHeight: 1,
                        }}
                      >
                        Cash of delivery amount
                      </p>
                    ),
                    tooltipWidth: "default",
                  },
                  {
                    title: "Destination",
                  },
                  {
                    title: "Status",
                    tooltipContent: (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          lineHeight: 1,
                        }}
                      >
                        Generalized status
                      </p>
                    ),
                    tooltipWidth: "default",
                  },
                  {
                    title: "Delivered date",
                    tooltipContent: (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          lineHeight: 1,
                        }}
                      >
                        The day when parcel is delivered
                      </p>
                    ),
                    tooltipWidth: "default",
                  },
                  {
                    title: "Booked by",
                  },
                  {
                    title: "Days",
                    tooltipContent: (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          lineHeight: 1,
                        }}
                      >
                        Number of days from booking till delivery
                      </p>
                    ),
                    tooltipWidth: "default",
                  },
                  {
                    title: "Track",
                    tooltipContent: "Track your order live",
                    tooltipWidth: "default",
                  },
                  {
                    title: "Cancel",
                    tooltipContent:
                      "This will cancel booking and mark as unfulfilled",
                    tooltipWidth: "wide",
                  },
                  {
                    title: "Booked days",
                  },
                  {
                    title: "Tags",
                  },
                ]}
              >
                {renderOrders()}
              </IndexTable>
            </LegacyCard>
          </BlockStack>
          {!isLoading && !isError && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem 0px",
              }}
            >
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <OrderPagination
                  currentPage={data?.page}
                  onPageChange={paginationChangeHandler}
                  totalOrders={data?.totalOrders}
                  totalPages={data?.totalPages}
                  limit={data?.pagination?.limit}
                  key={Math.random()}
                />
                {/* <RecordsRange onRangeChange={() => {}} key={nanoid()} /> */}
              </div>
              {/* <DownloadCsv /> */}
            </div>
          )}
        </>
      </Page>
    </>
  );

  function disambiguateLabel(key: string, value: string[]): string {
    switch (key) {
      case "status":
        return `${value}`;
      case "courier":
        return value.map((val) => `${val}`).join(", ");
      case "productType":
        return value.join(" ");
      default:
        return value.toString();
    }
  }

  function isEmpty(value: string | string[]): boolean {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function isEmptyString(value: string): boolean {
    return value.trim() === "";
  }
}
