import {
  Grid,
  LegacyCard,
  SkeletonBodyText,
  SkeletonDisplayText,
  TextContainer,
} from "@shopify/polaris";

const SkeletonPage = () => {
  const width: number = 100;
  return (
    <LegacyCard>
      <LegacyCard.Section>
        <TextContainer>
          <Grid>
            <Grid.Cell columnSpan={{ sm: 4 }}>
              <SkeletonBodyText lines={1} />
              <div style={{ margin: "10px" }}></div>
              <SkeletonDisplayText maxWidth={`${width}%`} size="medium" />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ sm: 4 }}>
              <SkeletonBodyText lines={1} />
              <div style={{ margin: "10px" }}></div>
              <SkeletonDisplayText maxWidth={`${width}%`} size="medium" />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ sm: 4 }}>
              <SkeletonBodyText lines={1} />
              <div style={{ margin: "10px" }}></div>
              <SkeletonDisplayText maxWidth={`${width}%`} size="medium" />
            </Grid.Cell>
          </Grid>
          <Grid>
            <Grid.Cell columnSpan={{ sm: 4 }}>
              <SkeletonBodyText lines={1} />
              <div style={{ margin: "10px" }}></div>
              <SkeletonDisplayText maxWidth={`${width}%`} size="medium" />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ sm: 4 }}>
              <SkeletonBodyText lines={1} />
              <div style={{ margin: "10px" }}></div>
              <SkeletonDisplayText maxWidth={`${width}%`} size="medium" />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ sm: 4 }}>
              <SkeletonBodyText lines={1} />
              <div style={{ margin: "10px" }}></div>
              <SkeletonDisplayText maxWidth={`${width}%`} size="medium" />
            </Grid.Cell>
          </Grid>
        </TextContainer>
      </LegacyCard.Section>
    </LegacyCard>
  );
};

export default SkeletonPage;
