import {
  BlockStack,
  Button,
  Icon,
  Image,
  InlineStack,
  LegacyCard,
  Spinner,
  TextField,
} from "@shopify/polaris";
import { CheckIcon } from "@shopify/polaris-icons";
import {
  useGetQrCodeQuery,
  useVerifyQrCodeMutation,
} from "../../store/store/slices/ProfileRTK";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../store/store/slices/AlertSlice";
import { TWO_FACTOR_PROPS_TYPES } from "../../store/types/TwoFac";

const TwoFactorAuth = ({ refetch, enabled }: TWO_FACTOR_PROPS_TYPES) => {
  const dispatch = useDispatch();
  const [verifyQRHandler, { isLoading: verifying, isSuccess, data }] =
    useVerifyQrCodeMutation();
  const {
    data: qrCodeSrc,
    isError: qrFailed,
    isLoading: qrFetching,
  } = useGetQrCodeQuery(undefined);
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const verifyQrCodeHandler = async () => {
    const values = getValues();
    await verifyQRHandler(values);
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showToast({
          error: false,
          message: "Two factor authentication enabled",
          visible: true,
        })
      );
      refetch();
    }
  }, [isSuccess, data]);
  return (
    <LegacyCard title="Two factor authentication" sectioned>
      {!qrFetching && !enabled ? (
        <BlockStack gap={"500"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              width={200}
              height={200}
              source={qrCodeSrc}
              alt="qr code image"
            />
          </div>
          <div
            style={{
              gap: "1rem",
              width: "70%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Controller
              name="code"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoComplete="Off"
                  label="Enter code"
                  placeholder="XXXXXX"
                  error={errors.code && "Enter code to verify"}
                ></TextField>
              )}
            />
            <Button
              variant="primary"
              loading={verifying}
              disabled={verifying}
              onClick={handleSubmit(verifyQrCodeHandler)}
            >
              Verify code
            </Button>
          </div>
        </BlockStack>
      ) : (
        <div style={{ marginTop: "1rem" }}>
          <InlineStack align="center">
            <div style={{ marginRight: ".5rem" }}>
              <Icon source={CheckIcon}></Icon>
            </div>
            <span>Two factor authentication enabled</span>
          </InlineStack>
        </div>
      )}
      {qrFetching && (
        <div
          style={{
            marginRight: ".5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner accessibilityLabel="qr code fetching spinner" size="large" />
        </div>
      )}
    </LegacyCard>
  );
};

export default TwoFactorAuth;
