/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { showToast } from "./AlertSlice";
// import Cookies from "js-cookie";
// Custom base query that handles 401 responses
const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: "/api/v1",
    prepareHeaders: (headers) => {
      const authToken = localStorage.getItem("auth_token");
      if (authToken) {
        headers.set("Authorization", `Bearer ${authToken}`);
      }
      return headers;
    },
  });
  const result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 400 || result?.error?.status === 403) {
    api.dispatch(
      showToast({
        message: result?.error?.data?.errors[0]?.msg || "Something went wrong",
        error: true,
        visible: true,
      })
    );
  }
  if (result.error && result.error.status === 401) {
    api.dispatch(
      showToast({ message: "Unauthorized", error: true, visible: true })
    );
    // Remove the token from localStorage
    localStorage.removeItem("auth_token");
    window.location.search = "/login";
    // const refreshToken = Cookies.get("refreshToken");
    // console.log("Refresh Token:", refreshToken);
    // if (refreshToken) {
    //   try {
    //     const response = await fetch("/api/v1/refresh-token", {
    //       method: "POST",
    //       headers: { "Content-Type": "application/json" },
    //       body: JSON.stringify({ refreshToken }),
    //     });

    //     if (!response.ok) throw new Error("Failed to refresh token");
    //     const { auth_token } = await response.json();
    //     localStorage.setItem("auth_token", auth_token);

    //     result = await baseQuery(args, api, extraOptions);
    //   } catch (refreshError) {
    //     console.error("Refresh token error:", refreshError);
    //     localStorage.removeItem("auth_token");
    //     window.location.href = "/login";
    //   }
    // } else {
    //   console.log("No refresh token found.");
    //   localStorage.removeItem("auth_token");
    //   window.location.href = "/login";
    // }
  }

  return result;
};
export default baseQueryWithReauth;
