import { createSlice, nanoid } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
  data: [],
};

export const alerts = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    setAlert(state, action) {
      const { msg, alertType } = action.payload;
      const id = nanoid();
      state.data.push({
        id,
        msg,
        alertType,
      });

      setTimeout(() => {
        state.data = state.data.filter((item) => item?.id !== id);
      }, 5000);
    },
    removeAlert(state, action) {
      const id = action.payload;
      state.data = state.data.filter((alert) => alert?.id !== id);
    },
  },
});

export const { setAlert, removeAlert } = alerts.actions;

export const getAlerts = (state: RootState) => state.alerts;
export default alerts.reducer;
