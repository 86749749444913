import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TOAST_PAYLOAD_TYPE } from "../../types/Toast";

const initialState: TOAST_PAYLOAD_TYPE = {
  message: null,
  visible: false,
  error: false,
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<TOAST_PAYLOAD_TYPE>) => {
      state.message = action.payload.message;
      state.error = action.payload.error;
      state.visible = true;
    },
    hideToast: (state) => {
      state.message = null;
      state.error = false;
      state.visible = false;
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;
export default toastSlice.reducer;
