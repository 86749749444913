// src/store/store.ts

import { configureStore } from "@reduxjs/toolkit";
import { orderApi } from "./slices/OrderRTK";
import { citiesApi } from "./slices/CitiesRTK";
import { dashboardApi } from "./slices/DashboardRTK";
import accountApi from "./slices/AccountRTK";
import { trackOrderApi } from "./slices/TrackOrderRTK";
import { profileApi } from "./slices/ProfileRTK";
import { csvApi } from "./slices/FileDownloadRTK";
import { dataReducer } from "./slices/MergeOrdersSlice";
import authReducer from "./slices/AuthSlice";
import sendMailApi from "./slices/MailRTK";
import alerts from "./slices/Alerts";
import modal from "./slices/ModalSlice";
import { usersApi } from "./slices/UsersRTK";
import { settingsApi } from "./slices/SettingsRTK";
import toastReducer from "./slices/AlertSlice";
import { loginApi } from "./slices/LoginRTK";
import { authApi } from "./slices/AuthRTK";

const store = configureStore({
  reducer: {
    [trackOrderApi.reducerPath]: trackOrderApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [sendMailApi.reducerPath]: sendMailApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [citiesApi.reducerPath]: citiesApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [csvApi.reducerPath]: csvApi.reducer,
    alerts,
    modal: modal,
    data: dataReducer,
    auth: authReducer,
    toast: toastReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      csvApi.middleware,
      authApi.middleware,
      usersApi.middleware,
      orderApi.middleware,
      loginApi.middleware,
      citiesApi.middleware,
      accountApi.middleware,
      profileApi.middleware,
      sendMailApi.middleware,
      settingsApi.middleware,
      dashboardApi.middleware,
      trackOrderApi.middleware
    ),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
