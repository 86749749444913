/* eslint-disable @typescript-eslint/no-explicit-any */

import { childAccountTypes } from "../../../store/types/types";
import { Badge, Icon, IndexTable, Modal, Tooltip } from "@shopify/polaris";
import { StarIcon, QuestionCircleIcon } from "@shopify/polaris-icons";
import RestrictUser from "./RestrictUser";

import { useState } from "react";
import ScopeListCell from "./ScopeListCell";
import EditTag from "./EditTag";
import EditUserPopover from "./EditUserPopover";
import { usersStatus } from "../../../utils/enums";

const UserTableRow = ({ account, index }: childAccountTypes) => {
  const [accessModal, setAccessModal] = useState<boolean>(false);

  return (
    <>
      <Modal
        title="Manage access scopes"
        open={accessModal}
        onClose={() => setAccessModal(false)}
      >
        <Modal.Section></Modal.Section>
      </Modal>
      <IndexTable.Row
        id={account._id}
        key={account._id}
        selected={false}
        position={index}
        tone={`${!account?.is_active ? "subdued" : ""}` as any}
      >
        <IndexTable.Cell>
          {account.first_name + " " + account.last_name}
        </IndexTable.Cell>
        <IndexTable.Cell>{account?.email}</IndexTable.Cell>
        <IndexTable.Cell>
          <Badge
            icon={account?.role === "ADMIN" ? StarIcon : undefined}
            tone={account?.role === "ADMIN" ? "success-strong" : "magic"}
          >
            {account?.role}
          </Badge>{" "}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <EditTag tag={account?.tag} />
        </IndexTable.Cell>
        {account?.role === usersStatus.user ? (
          <ScopeListCell scopes={account.access_scopes} />
        ) : (
          <IndexTable.Cell>
            {
              <Tooltip
                width="wide"
                content={
                  <p style={{ textAlign: "center" }}>
                    Admin has all scopes by default
                  </p>
                }
              >
                <Icon source={QuestionCircleIcon}></Icon>
              </Tooltip>
            }
          </IndexTable.Cell>
        )}
        <IndexTable.Cell>
          {account?.is_active ? (
            <Badge tone="magic">Active</Badge>
          ) : (
            <Badge tone="critical-strong">Deactivated</Badge>
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{ display: "flex", alignItems: "center" }}>
            <EditUserPopover id={account?._id} />
            {account?.role === "USER" && (
              <RestrictUser id={account?._id} active={account?.is_active} />
            )}
            {/* <DeleteUser id={account?._id} /> */}
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    </>
  );
};

export default UserTableRow;
