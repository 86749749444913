import {
  Badge,
  Button,
  ButtonGroup,
  FormLayout,
  LegacyCard,
  Modal,
  Page,
  TextField,
} from "@shopify/polaris";
import ProfileInputcard from "../components/atoms/ProfileInputCard";
import SkeletonPage from "../components/atoms/ProfileSkelton";
import { useGetUserQuery } from "../store/store/slices/ProfileRTK";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useChangePasswordMutation } from "../store/store/slices/UsersRTK";
import { useDispatch } from "react-redux";
import { showToast } from "../store/store/slices/AlertSlice";
import TwoFactorAuth from "../components/atoms/TwoFactorAuth";

const Profile = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  // const [showTwoFactorCard, setShowTwoFactorCard] = useState<boolean>(false);
  const [
    changePasswordMutation,
    { data: passwordChangedResp, isLoading, isSuccess },
  ] = useChangePasswordMutation();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [changePasswordModal, setChangePasswordModal] =
    useState<boolean>(false);
  const {
    data: user,
    isLoading: profileLoading,
    isFetching,
    refetch,
  } = useGetUserQuery(undefined);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showToast({
          message: "Password has been changed",
          error: false,
          visible: true,
        })
      );
      reset({ current_password: "", new_password: "", confirm_password: "" });
      setChangePasswordModal(false);
    }
  }, [isLoading, isSuccess, passwordChangedResp]);

  const onSubmitPasswordChangeHandler = async (data: FormData) => {
    await changePasswordMutation(data).unwrap();
  };
  const onRefetchProfileHandler = async () => {
    // setShowTwoFactorCard(false);
    refetch();
  };
  return (
    <Page
      title={
        user?.user?.first_name
          ? "Hi, " + user?.user?.first_name + " " + user?.user?.last_name
          : ""
      }
      backAction={{
        onAction: () => {
          navigateTo("/dashboard/accounts");
        },
      }}
      primaryAction={
        <ButtonGroup>
          <>
            {user?.user?.role === "USER" && (
              <Button
                variant="secondary"
                onClick={() => {
                  setChangePasswordModal(true);
                }}
              >
                Change password
              </Button>
            )}
          </>
          <Button
            variant="primary"
            onClick={() => {
              navigateTo("/dashboard/accounts");
            }}
          >
            Accounts
          </Button>
        </ButtonGroup>
      }
      narrowWidth
    >
      {profileLoading ? (
        <>
          <SkeletonPage />
          <SkeletonPage />
        </>
      ) : (
        <>
          <LegacyCard title="Shop Information" sectioned>
            <FormLayout>
              <FormLayout.Group>
                <ProfileInputcard
                  id={Math.random().toString()}
                  label="Name"
                  value={`${user?.user?.shop?.name}`}
                  helpText={false}
                  loading={false}
                  readonly={true}
                />
                <ProfileInputcard
                  id={Math.random().toString()}
                  label="Domain"
                  value={user?.user?.shop?.domain}
                  helpText={false}
                  loading={false}
                  readonly={true}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <ProfileInputcard
                  id={Math.random().toString()}
                  label="Time Zone"
                  value={user?.user?.shop?.iana_time_zone}
                  helpText={false}
                  loading={false}
                  readonly={true}
                />
                <ProfileInputcard
                  id={Math.random().toString()}
                  label="Currency"
                  value={user?.user?.shop?.currency}
                  helpText={false}
                  loading={false}
                  readonly={true}
                />
                <ProfileInputcard
                  id={Math.random().toString()}
                  label="Activity Status"
                  value={user?.user?.is_active ? "ACTIVE" : "OFF"}
                  helpText={false}
                  loading={false}
                  readonly={true}
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
          <LegacyCard title="Owner Information" sectioned>
            <FormLayout>
              <FormLayout.Group condensed>
                <ProfileInputcard
                  id={Math.random().toString()}
                  label="First Name"
                  value={user?.user?.first_name}
                  helpText={false}
                  loading={false}
                  readonly={true}
                />
                <ProfileInputcard
                  id={Math.random().toString()}
                  label="Last Name"
                  value={user?.user?.last_name}
                  helpText={false}
                  loading={false}
                  readonly={true}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <ProfileInputcard
                  id={Math.random().toString()}
                  label="Email"
                  value={user?.user?.email}
                  helpText={false}
                  loading={false}
                  readonly={true}
                />
                <ProfileInputcard
                  id={Math.random().toString()}
                  label="Phone #"
                  value={user?.user?.phone}
                  helpText={false}
                  loading={false}
                  readonly={true}
                />
                <ProfileInputcard
                  id={Math.random().toString()}
                  label="Role"
                  value={user?.user?.role}
                  helpText={false}
                  loading={false}
                  readonly={true}
                />

                <ProfileInputcard
                  id={Math.random().toString()}
                  label="Tag"
                  value={user?.user?.tag}
                  loading={profileLoading || isFetching}
                  helpText={false}
                  readonly={true}
                />
              </FormLayout.Group>
              {/* <InlineStack align="end">
                <Button
                  dataPrimaryLink
                  variant="plain"
                  icon={KeyIcon}
                  onClick={() => setShowTwoFactorCard((prev) => !prev)}
                  disabled={user?.two_factor_enabled}
                >
                  {user?.two_factor_enabled
                    ? "Two factor authenticated"
                    : "Two factor authentication"}
                </Button>
              </InlineStack> */}
            </FormLayout>
          </LegacyCard>

          <TwoFactorAuth
            refetch={onRefetchProfileHandler}
            enabled={user?.two_factor_enabled}
          />

          {user?.user?.role === "USER" && (
            <LegacyCard title="Scopes" sectioned>
              <div style={{ display: "flex", gap: ".8rem", flexWrap: "wrap" }}>
                {user?.user?.access_scopes?.map(
                  (scope: string, index: number) => (
                    <Badge
                      key={index}
                      tone={
                        scope.includes("ORDERS")
                          ? "success-strong"
                          : scope.includes("ACCOUNTS")
                          ? "attention"
                          : scope.includes("USERS")
                          ? "magic"
                          : "enabled"
                      }
                    >
                      {scope}
                    </Badge>
                  )
                )}
              </div>
            </LegacyCard>
          )}
        </>
      )}
      <Modal
        title="Password change"
        open={changePasswordModal}
        onClose={() => setChangePasswordModal(false)}
        primaryAction={{
          content: "Save",
          onAction: handleSubmit(onSubmitPasswordChangeHandler),
          loading: isLoading,
          disabled: isLoading,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              setChangePasswordModal(false);
            },
          },
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <Controller
              name="current_password"
              control={control}
              rules={{ required: "Current password is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="password"
                  autoComplete="Off"
                  label="Current password"
                  requiredIndicator
                  error={
                    errors.current_password && "Current password is required"
                  }
                ></TextField>
              )}
            ></Controller>
            <Controller
              name="new_password"
              control={control}
              rules={{ required: "New password is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="password"
                  autoComplete="Off"
                  label="New password"
                  requiredIndicator
                  error={errors.current_password && "New password is required"}
                ></TextField>
              )}
            ></Controller>
            <Controller
              name="confirm_password"
              control={control}
              rules={{ required: "Confirm password is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="password"
                  autoComplete="Off"
                  label="New password"
                  requiredIndicator
                  error={
                    errors.current_password && "Confirm password is required"
                  }
                ></TextField>
              )}
            ></Controller>
          </FormLayout>
        </Modal.Section>
      </Modal>
    </Page>
  );
};

export default Profile;
