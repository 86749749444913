/* eslint-disable @typescript-eslint/no-explicit-any */
// src/store/slices/DashboardApi.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./BaseQuery";

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["dashboard"],
  endpoints: (builder) => ({
    fetchDashboardAnalytics: builder.query({
      query: ({
        query,
        track,
        couriers,
        page,
        limit,
        status,
        dest,
        bookingFrom,
        bookingTo,
        deliveredTo,
        deliveredFrom,
      }) => {
        return `/get-dashboard-analytics?query=${query || ""}&city=${
          dest || ""
        }&status=${status || ""}&bookingDateFrom=${
          bookingFrom || ""
        }&bookingDateTo=${bookingTo || ""}&deliveredDateFrom=${
          deliveredFrom || ""
        }&deliveredDateTo=${deliveredTo || ""}&trackingNumber=${
          track || ""
        }&company=${couriers || ""}&page=${page}&limit=${limit}`;
      },
      providesTags: ["dashboard"],
      keepUnusedDataFor: 200,
      transformResponse: (response: any) => {
        return response;
      },
    }),
    fetchDashboardAverage: builder.query({
      query: ({
        startDate,
        endDate,
        city,
      }: {
        startDate: string;
        endDate: string;
        city: string;
      }) => ({
        url: "/cities-delivery-average",
        params: { startDate, endDate, city },
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const {
  useFetchDashboardAnalyticsQuery,
  useFetchDashboardAverageQuery,
} = dashboardApi;
