import {
  Page,
  Button,
  LegacyCard,
  Text,
  FormLayout,
  TextField,
  Select,
  BlockStack,
} from "@shopify/polaris";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AccountAlert from "../components/atoms/AccountAlert";
import { useCreateAccountMutation } from "../store/store/slices/AccountRTK";
import { useDispatch } from "react-redux";
import { showToast } from "../store/store/slices/AlertSlice";

type FormData = {
  account_name: string;
  username?: string;
  account_no?: string;
  api_password?: string;
  api_key?: string;
  token?: string;
  is_default?: string;
  weight: string;
  address_code?: string;
};

export default function Settings() {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [company, setCompany] = useState<string>("");
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormData>();

  const [
    createAccount,
    {
      isLoading: creating,
      error: creatingError,
      isSuccess: accountCreatingSuccess,
    },
  ] = useCreateAccountMutation();

  const onSubmit = async (formData: FormData) => {
    if (company.trim() === "") {
      return;
    }
    const accountData = { ...formData, company };
    await createAccount(accountData).unwrap();
    reset();
    setTimeout(() => {
      navigateTo("/dashboard/accounts");
    }, 700);
  };

  useEffect(() => {
    if (accountCreatingSuccess) {
      dispatch(
        showToast({
          message: "Account has been created",
          error: false,
          visible: true,
        })
      );
    }
  }, [creatingError, creating]);

  return (
    <Page
      backAction={{
        content: "Settings",
        url: "",
        onAction: () => navigateTo("/dashboard/accounts"),
      }}
      title="Settings"
      primaryAction={
        <Button
          variant="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={creating}
          loading={creating}
        >
          Save
        </Button>
      }
    >
      <BlockStack gap="500">
        <Text as="h1" fontWeight="bold">
          <span style={{ fontSize: "20px" }}>TRACKING CREDENTIALS</span>
        </Text>
        <AccountAlert />
        <LegacyCard sectioned>
          <FormLayout>
            <Select
              label="Select Provider"
              options={[
                { label: "Select a company", value: "" },
                { label: "MNP", value: "MNP" },
                { label: "LEOPARD", value: "LEOPARD" },
                { label: "POSTEX", value: "POSTEX" },
              ]}
              onChange={(val) => setCompany(val)}
              value={company}
              requiredIndicator
              disabled={creating}
              error={company === "" ? "Company name is required" : ""}
            />
            {company && (
              <BlockStack gap="400">
                <Controller
                  control={control}
                  name="account_name"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Account name: You can write any name of your choice to uniquely identify your account"
                      autoComplete="Off"
                      value={value || ""}
                      onChange={onChange}
                      placeholder="Enter account name"
                      disabled={creating}
                      requiredIndicator
                      error={
                        errors.account_name ? "Account name is required" : ""
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="weight"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Weight: (in grams) This weight will displayed on order booking page so you don't have to add manually"
                      autoComplete="Off"
                      value={value || ""}
                      onChange={onChange}
                      type="number"
                      placeholder="Enter default weight"
                      min={0}
                      disabled={creating}
                      requiredIndicator
                      error={errors.account_name ? "Weight is required" : ""}
                    />
                  )}
                />
                {company === "MNP" && (
                  <>
                    <Controller
                      control={control}
                      name="username"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="M&P Username"
                          autoComplete="Off"
                          value={value || ""}
                          onChange={onChange}
                          placeholder="Enter username provided by M&P"
                          disabled={creating}
                          requiredIndicator
                          error={errors.username ? "Username is required" : ""}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="account_no"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Account number: Given by m&p, you can find it on mnp dashboard > reports > consignee profile"
                          value={value || ""}
                          autoComplete="Off"
                          onChange={onChange}
                          placeholder="Enter account number"
                          disabled={creating}
                          requiredIndicator
                          error={
                            errors.account_no
                              ? "Account number is required"
                              : ""
                          }
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="api_password"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          autoComplete="off"
                          label="M&P Password"
                          value={value || ""}
                          type="password"
                          onChange={onChange}
                          placeholder="Enter password provided by M&P"
                          disabled={creating}
                          requiredIndicator
                          error={
                            errors.api_password
                              ? "API password is required"
                              : ""
                          }
                        />
                      )}
                    />
                  </>
                )}

                {company === "LEOPARD" && (
                  <>
                    <Controller
                      control={control}
                      name="api_key"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Api Key"
                          value={value || ""}
                          autoComplete="Off"
                          onChange={onChange}
                          placeholder="Enter API key provided by leopard"
                          disabled={creating}
                          requiredIndicator
                          error={errors.api_key ? "API key is required" : ""}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="api_password"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Api Password"
                          value={value || ""}
                          autoComplete="Off"
                          type="password"
                          onChange={onChange}
                          placeholder="Enter API password provided by leopard"
                          disabled={creating}
                          requiredIndicator
                          error={
                            errors.api_password
                              ? "API password is required"
                              : ""
                          }
                        />
                      )}
                    />
                  </>
                )}

                {company === "POSTEX" && (
                  <>
                    <Controller
                      control={control}
                      name="token"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Token"
                          value={value || ""}
                          autoComplete="Off"
                          onChange={onChange}
                          placeholder="Enter postex token"
                          disabled={creating}
                          requiredIndicator
                          error={errors.token ? "Token is required" : ""}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="address_code"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Pickup Address ID"
                          value={value || ""}
                          autoComplete="Off"
                          onChange={onChange}
                          placeholder="Enter postex address code"
                          disabled={creating}
                          requiredIndicator
                          error={errors.token ? "Address code is required" : ""}
                        />
                      )}
                    />
                  </>
                )}

                <Controller
                  control={control}
                  name="is_default"
                  defaultValue={"false"}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label="Set As Default: Default account will be automatically selected on booking page"
                      onChange={onChange}
                      value={value || "false"}
                      options={[
                        { label: "No", value: "false" },
                        { label: "Default", value: "default" },
                      ]}
                      disabled={creating}
                    />
                  )}
                />
              </BlockStack>
            )}
          </FormLayout>
        </LegacyCard>
      </BlockStack>
    </Page>
  );
}
