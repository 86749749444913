import {
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Image,
  Layout,
  LegacyCard,
  List,
  Page,
  TextContainer,
} from "@shopify/polaris";
import { AlertTriangleIcon } from "@shopify/polaris-icons";
import dashboardGif from "../assets/gif/dashboard.gif";
import { useState } from "react";
const REQUIRED_SCOPES = [
  { title: "READ_ANALYTICS", explanation: "visit analytics page" },
];

const DashboardDocs = () => {
  const [showScopes, setShowScopes] = useState(false);
  return (
    <Page
      title="Analytics"
      titleMetadata={
        <Badge tone="info" icon={AlertTriangleIcon} size="large">
          Admin access only. Users need <b>READ_ANALYTICS</b> scope to access
          this dashboard.
        </Badge>
      }
    >
      <Layout>
        {/* Analytics Section */}
        <Layout.Section>
          <LegacyCard title="Graphical representation" sectioned>
            <div>
              <BlockStack gap={"300"}>
                <div>
                  <Button
                    variant="plain"
                    dataPrimaryLink
                    onClick={() => setShowScopes((prev: boolean) => !prev)}
                  >
                    Required scopes
                  </Button>
                </div>
                {showScopes && (
                  <List>
                    {REQUIRED_SCOPES.map((scope) => (
                      <List.Item>
                        <p>
                          <Badge tone="magic">{scope.title}</Badge>{" "}
                          {scope.explanation}
                        </p>
                      </List.Item>
                    ))}
                  </List>
                )}
              </BlockStack>
            </div>
            <div>
              <Image source={dashboardGif} alt="Dashboard image" width="100%" />
            </div>
          </LegacyCard>

          <LegacyCard title="Dashboard Section" sectioned>
            <TextContainer>
              <p>
                The dashboard displays key analytics in the form of{" "}
                <strong>summary cards</strong>, providing an overview of your
                orders across different statuses:
              </p>
              <List type="bullet">
                <List.Item>
                  <strong>Fulfilled Orders</strong>: The number of orders marked
                  as fulfilled.
                </List.Item>
                <List.Item>
                  <strong>Pending Orders</strong>: The count of orders in
                  process (neither delivered nor returned).
                </List.Item>
                <List.Item>
                  <strong>Delivered Orders</strong>: The total number of orders
                  successfully delivered to customers.
                </List.Item>
                <List.Item>
                  <strong>Cancelled Orders</strong>: The count of orders that
                  were canceled.
                </List.Item>
                <List.Item>
                  <strong>Total Orders</strong>: A summary of all orders
                  combined, irrespective of their status.
                </List.Item>
              </List>
              <p>
                Each card dynamically updates based on the selected filters,
                giving you tailored insights.
              </p>
            </TextContainer>
          </LegacyCard>
        </Layout.Section>

        {/* Filters Section */}
        <Layout.Section>
          <LegacyCard title="Filters Button" sectioned>
            <TextContainer>
              <p>
                The filters allow you to refine the analytics displayed on the
                dashboard. You can <Badge tone="magic">filter</Badge> the data
                by:
              </p>
              <List type="bullet">
                <List.Item>
                  <strong>Booking Date Range</strong>: Specify a start and end
                  date to view orders booked within that period.
                </List.Item>
                <List.Item>
                  <strong>Delivery Date Range</strong>: Select a date range to
                  analyze orders delivered during the specified time.
                </List.Item>
                {/* <List.Item>
                  <strong>Additional Filters</strong>: Use the "Add Filter"
                  button to extend filtering options, such as:
                  <List type="bullet">
                    <List.Item>Status (e.g., Delivered, Cancelled)</List.Item>
                    <List.Item>Destination City</List.Item>
                    <List.Item>Tracking Number</List.Item>
                    <List.Item>Company or Carrier</List.Item>
                  </List>
                </List.Item> */}
              </List>
              <Banner>
                <p>
                  If no filters are selected, the dashboard automatically
                  displays analytics for the{" "}
                  <strong>
                    <Badge tone="attention">last seven days</Badge>
                  </strong>
                  . This ensures you always have meaningful insights, even if
                  you don’t apply any filters.
                </p>
                <p>
                  <strong>Example:</strong> If today is{" "}
                  <em>January 13, 2025</em>, the dashboard will show data for
                  orders booked, delivered, or updated from{" "}
                  <em>January 6, 2025</em> to <em>January 12, 2025</em>.
                </p>
                <p>
                  This default view provides a quick snapshot of recent
                  activity, keeping you informed without requiring manual input.
                </p>
              </Banner>
              <p>
                <strong>Actions:</strong>
              </p>
              <List type="bullet">
                <List.Item>
                  <strong>Add Filter</strong>: Add new filters for a more
                  granular view of the data.
                </List.Item>
                <List.Item>
                  <strong>Clear All</strong>: Resets all filters, removing the
                  applied date ranges or additional criteria.
                </List.Item>
              </List>
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default DashboardDocs;
