/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./BaseQuery";

const sendMailApi = createApi({
  reducerPath: "sendMailApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Mail"],
  endpoints: (builder) => ({
    sendMail: builder.mutation<any, { orderId: string }>({
      query: ({ orderId }) => ({
        url: `send-email/${orderId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Mail"],
    }),
  }),
});

export const { useSendMailMutation } = sendMailApi;

export default sendMailApi;
