import {
  Badge,
  Banner,
  BlockStack,
  Button,
  Card,
  Image,
  List,
  Page,
  Text,
} from "@shopify/polaris";
import { AlertTriangleIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import settingsImage from "../assets/docs/settings.png";
import { useState } from "react";
const REQUIRED_SCOPES = [
  { title: "READ_SETTINGS", explanation: "visit settings page" },
  { title: "WRITE_SETTINS", explanation: "create and update settings" },
];

const SettingsDocs = () => {
  const [showScopes, setShowScopes] = useState(false);

  const navigate = useNavigate();
  return (
    <Page
      title="Settings"
      titleMetadata={
        <Badge tone="info" icon={AlertTriangleIcon} size="large">
          Admin access only. Users need <b>READ_SETTINGS</b> scope to access
          this page.
        </Badge>
      }
    >
      <Card padding={"500"}>
        <BlockStack gap={"400"}>
          <p>
            Here, you will find everything you need to know about{" "}
            <b>general settings</b> and how to configure them for your
            application.
          </p>
          <Text as="h1" fontWeight="bold" variant="headingMd">
            What are settings?
          </Text>
          <p>
            <List type="bullet">
              <List.Item>
                You can control whether <b>discounts</b> should be shown or not
                .
              </List.Item>
              <List.Item>
                Specify the <b>free shipping order value</b>.
              </List.Item>
              <List.Item>
                Manage and implement <b>tags</b> for better shopify order's
                organization.
              </List.Item>
            </List>
          </p>
          <div>
            <BlockStack gap={"300"}>
              <div>
                <Button
                  variant="plain"
                  dataPrimaryLink
                  onClick={() => setShowScopes((prev) => !prev)}
                >
                  Required scopes
                </Button>
              </div>
              {showScopes && (
                <List>
                  {REQUIRED_SCOPES.map((scope) => (
                    <List.Item>
                      <p>
                        <Badge tone="magic">{scope.title}</Badge>{" "}
                        {scope.explanation}
                      </p>
                    </List.Item>
                  ))}
                </List>
              )}
            </BlockStack>
          </div>
          <Image source={settingsImage} alt="settings page image" />
          <Text as="h1" fontWeight="bold" variant="headingMd">
            Free shipping value
          </Text>
          <Banner tone="info">
            {" "}
            This feature is applicable only for <b>merge orders</b>, meaning it
            will only be considered when multiple orders are merged into one.
          </Banner>
          <List type="bullet">
            <List.Item>
              This value represents the minimum order price required to qualify
              for free shipping. If the total price of the order is equal to or
              greater than the specified free shipping value, the shipping cost
              will be automatically deducted from the order, and the customer
              will not be charged for shipping.
            </List.Item>
            <List.Item>
              If you set the free shipping value to <b>0</b>, it will be
              considered that this feature is not yet being used. In this case,
              shipping charges will apply as usual.
            </List.Item>
            <List.Item>
              In the case of <b>merge orders</b>, if any order within the merged
              group qualifies for free shipping, the entire merged order will be
              considered eligible for free shipping.
            </List.Item>
            <List.Item>
              If every order in the merge order group has a shipping cost and{" "}
              <b>Free Shipping</b> is either not set or set to <b>0</b>, the
              system will calculate and apply the average of the shipping costs
              across all orders in the group.
            </List.Item>
            <List.Item>
              The calculated average ensures a balanced distribution of shipping
              costs among the merged orders.
            </List.Item>
            <List.Item>
              If <b>Free Shipping</b> is enabled and any order in the group
              qualifies, the entire merge order will be considered eligible for
              free shipping instead.
            </List.Item>
            <List.Item>
              <Badge tone="success">
                You can adjust how this calculation works based on your
                preferences or business requirements.
              </Badge>
            </List.Item>
          </List>
          <Text as="h1" fontWeight="bold" variant="headingMd">
            Discount Name
          </Text>
          <Banner tone="info">
            The "Show Discount Details" option must be checked for the discount
            to be displayed.
          </Banner>
          <List type="bullet">
            <List.Item>
              You can specify a discount title of your choice, which will appear
              as the label for the discount details on the order.
            </List.Item>
            <List.Item>
              If no discount title is provided and "Show Discount Details" is
              checked, the default title <b>"Discount"</b> will be used.
            </List.Item>
            <List.Item>
              Make sure to check "Show Discount Details" to enable and display
              the discount information on the order.
            </List.Item>
          </List>
          <Text as="h1" fontWeight="bold" variant="headingMd">
            Shopify Order Tags
          </Text>
          <Banner tone="info">
            The tags <b>INPRCS</b>, <b>DVD</b>, and others are used as examples.
            Feel free to specify your own tags based on your preferences and
            requirements.
          </Banner>
          <List type="bullet">
            <List.Item>
              <b>Inprocess Tag:</b> Specify the tag to add when an order is in
              process, e.g., <b>INPRCS</b>.
            </List.Item>
            <List.Item>
              <b>Delivered Tag:</b> Specify the tag to add when an order is
              delivered, e.g., <b>DVD</b>.
            </List.Item>
            <List.Item>
              <b>Cancelled Tag:</b> Specify the tag to add when fulfillment is
              canceled, e.g., <b>CANCELLED</b>.
            </List.Item>
            <List.Item>
              <b>RTS Tag:</b> Specify the tag to add when a parcel is returned
              to sender, e.g., <b>RTS</b>.
            </List.Item>
          </List>
        </BlockStack>
      </Card>
    </Page>
  );
};

export default SettingsDocs;
