/* eslint-disable @typescript-eslint/no-explicit-any */
// src/components/Dashboard.tsx
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Banner,
  Card,
  Grid,
  Icon,
  Page,
  SkeletonTabs,
  Text,
} from "@shopify/polaris";
import DateRange from "../components/atoms/DateRange";
import { convertOrderDataToChartData } from "../utils/helpers";
import { AlertTriangleIcon, BookIcon } from "@shopify/polaris-icons";
import { useFetchDashboardAnalyticsQuery } from "../store/store/slices/DashboardRTK";

const Dashboard = () => {
  const navigateTo = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const {
    data: analytics,
    isError,
    error,
    isLoading,
    isFetching,
  } = useFetchDashboardAnalyticsQuery<any>({
    bookingFrom: params.get("bookingfrom") || "",
    bookingTo: params.get("bookingto") || "",
    deliveredFrom: params.get("deliveredfrom") || "",
    deliveredTo: params.get("deliveredto") || "",
  });
  useEffect(() => {
    navigateTo(`/dashboard?${params.toString()}`);
  }, []);

  const cardsMarkup =
    analytics &&
    convertOrderDataToChartData(analytics).map((analytic: any) => (
      <Grid.Cell key={analytic.name} columnSpan={{ xs: 6, md: 3 }}>
        <Card padding={"500"}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Text as="h1" variant="headingLg">
              {analytic.name}
            </Text>
            <div style={{ scale: "2", opacity: 1 }}>
              <Icon
                tone={analytic.base ? analytic.base : "subdued"}
                source={analytic.icon}
              ></Icon>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1.5rem",
              height: "30px",
            }}
          >
            <Text as="h1" alignment="end" variant="headingXl">
              {isLoading || isFetching ? (
                <SkeletonTabs count={1}></SkeletonTabs>
              ) : (
                analytic.y
              )}
            </Text>
          </div>
        </Card>
      </Grid.Cell>
    ));
  if (isError) {
    return (
      <Page title="Dashboard">
        <Banner icon={AlertTriangleIcon} tone="warning">
          {error?.data?.errors[0].msg}
        </Banner>
      </Page>
    );
  }
  return (
    <div
      style={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div
        style={{
          padding: "10px 0px",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <DateRange />
      </div>

      {
        <Grid>
          <>{cardsMarkup}</>
          <Grid.Cell key={"total orders"} columnSpan={{ xs: 6, md: 3 }}>
            <Card padding={"500"}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Text as="h1" variant="headingLg">
                  {"Total orders"}
                </Text>
                <div style={{ scale: "2", opacity: 1 }}>
                  <Icon
                    // tone={analytic.base ? analytic.base : "subdued"}
                    source={BookIcon}
                  ></Icon>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "1.5rem",
                  height: "30px",
                }}
              >
                <Text as="h1" alignment="end" variant="headingXl">
                  {isLoading || isFetching ? (
                    <SkeletonTabs count={1}></SkeletonTabs>
                  ) : (
                    analytics?.totalOrders
                  )}
                </Text>
              </div>
            </Card>
          </Grid.Cell>
        </Grid>
      }
      {/* {<Grid>{companiesCardsMarkup}</Grid>} */}
    </div>
  );
};

export default Dashboard;
