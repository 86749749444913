/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Banner,
  BlockStack,
  Button,
  Card,
  FormLayout,
  Grid,
  Icon,
  Page,
  TextField,
  Tooltip,
} from "@shopify/polaris";
import { InfoIcon } from "@shopify/polaris-icons";
import { Controller, useForm } from "react-hook-form";
import ScopesCard from "../components/atoms/users/ScopesCard";
import { useEffect, useState } from "react";
import {
  useEditChildAccountsMutation,
  useGetChildAccountQuery,
} from "../store/store/slices/UsersRTK";
import { useNavigate, useParams } from "react-router-dom";
import { usersStatus } from "../utils/enums";
import { useDispatch } from "react-redux";
import { showToast } from "../store/store/slices/AlertSlice";
import ChangeEmail from "../components/atoms/ChangeEmail";
import ChangePassword from "../components/atoms/ChangePassword";

const EditUser = () => {
  const { user_id } = useParams();
  const dispatch = useDispatch();
  const {
    data: editUser,
    isError: editedUserFetchingFailed,
    isLoading: editedUserFetching,
    error: editedUserFetchingError,
  } = useGetChildAccountQuery<any>({ id: user_id }, { skip: !user_id });
  const navigateTo = useNavigate();
  const [selectedScopes, setSelectedScopes] = useState<string[]>([]);
  const [
    editUserMutation,
    {
      isLoading: creatingAccount,
      isSuccess: accountCreated,
      error: accountCreationError,
      isError: accountCreationFailed,
    },
  ] = useEditChildAccountsMutation<any>();

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onCreateUserClickHandler = async (formData: FormData) => {
    if (selectedScopes?.length === 0 && editUser?.role === usersStatus.user) {
      dispatch(
        showToast({
          message: "At lease one scope should be assigned to update user",
          error: true,
          visible: true,
        })
      );
      return;
    }
    await editUserMutation({
      ...formData,
      scopes: selectedScopes,
      id: user_id,
      role: editUser?.role,
    }).unwrap();
  };

  const pullSelectedScopes = (selectedScopes: string[]) => {
    setSelectedScopes(selectedScopes);
  };
  // ===================== EDIT USER +++++++++++++++++++++++
  useEffect(() => {
    if (editUser) {
      reset({
        first_name: editUser?.first_name,
        last_name: editUser?.last_name,
        tag: editUser?.tag,
      });
      setSelectedScopes(editUser?.access_scopes || []);
    }
  }, [editUser]);
  useEffect(() => {
    if (accountCreated) {
      navigateTo("/dashboard/users");
    }
  }, [
    creatingAccount,
    accountCreated,
    accountCreationError,
    accountCreationFailed,
  ]);

  // IF fetching user gets failed
  if (editedUserFetchingFailed) {
    return (
      <Page narrowWidth>
        <Card>
          <Banner>
            <p>{editedUserFetchingError?.data?.errors[0]?.msg}</p>
          </Banner>
        </Card>
      </Page>
    );
  }
  return (
    <Page
      title="Update user"
      backAction={{
        onAction() {
          navigateTo("/dashboard/users");
        },
      }}
      // primaryAction={
      //   <Button
      //     size="micro"
      //     variant="primary"
      //     onClick={handleSubmit(onCreateUserClickHandler as any)}
      //     loading={editedUserFetching || creatingAccount}
      //     disabled={editedUserFetching || creatingAccount || !editedUserFetched}
      //   >
      //     Update user
      //   </Button>
      // }
    >
      <Grid>
        <Grid.Cell columnSpan={{ xs: 6, md: 4, lg: 8 }}>
          <BlockStack gap={"300"}>
            <Card>
              <FormLayout>
                <BlockStack gap={"300"}>
                  <FormLayout.Group>
                    <Controller
                      control={control}
                      name="first_name"
                      rules={{ required: "First name is required" }}
                      render={({ field }) => (
                        <TextField
                          label="First name"
                          {...field}
                          autoComplete="Off"
                          error={errors.first_name && "First name is required"}
                          requiredIndicator
                          loading={editedUserFetching}
                          disabled={editedUserFetching}
                          clearButton
                          onClearButtonClick={() =>
                            reset((prev) => {
                              return { ...prev, first_name: "" };
                            })
                          }
                        />
                      )}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Controller
                      control={control}
                      name="last_name"
                      rules={{ required: "Last name is required" }}
                      render={({ field }) => (
                        <TextField
                          label="Last name"
                          {...field}
                          autoComplete="Off"
                          error={errors.last_name && "Last name is required"}
                          requiredIndicator
                          loading={editedUserFetching}
                          disabled={editedUserFetching}
                          clearButton
                          onClearButtonClick={() =>
                            reset((prev) => {
                              return { ...prev, last_name: "" };
                            })
                          }
                        />
                      )}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Controller
                      control={control}
                      name="tag"
                      defaultValue={""}
                      rules={{ required: "Tag is required" }}
                      render={({ field }) => (
                        <TextField
                          label={
                            <div style={{ display: "flex", gap: ".5rem" }}>
                              Tag{" "}
                              <Tooltip
                                content={
                                  "This will be used as order tag while booking the order"
                                }
                                width="wide"
                              >
                                <Icon source={InfoIcon}></Icon>
                              </Tooltip>
                            </div>
                          }
                          type="text"
                          {...field}
                          autoComplete="Off"
                          error={errors.tag && "Tag is required"}
                          clearButton
                          onClearButtonClick={() =>
                            reset((prev) => {
                              return { ...prev, tag: "" };
                            })
                          }
                        />
                      )}
                    />
                  </FormLayout.Group>
                  {/* <FormLayout.Group>
                <Controller
                  control={control}
                  defaultValue={"self"}
                  name="phone"
                  render={({ field }) => (
                    <TextField
                      label="Phone"
                      loading={editedUserFetching}
                      disabled={editedUserFetching}
                      type="tel"
                      {...field}
                      autoComplete="Off"
                      clearButton
                      onClearButtonClick={() =>
                        reset((prev) => {
                          return { ...prev, phone: "" };
                        })
                      }
                    />
                  )}
                />
              </FormLayout.Group> */}
                  {editUser?.role === usersStatus.user && (
                    <ScopesCard
                      pushSelectedScopesToParent={pullSelectedScopes}
                      scopes={editUser?.access_scopes}
                    ></ScopesCard>
                  )}
                  <div>
                    <Button
                      size="micro"
                      variant="primary"
                      onClick={handleSubmit(onCreateUserClickHandler as any)}
                      loading={editedUserFetching || creatingAccount}
                      disabled={editedUserFetching || creatingAccount}
                    >
                      Update user
                    </Button>
                  </div>
                </BlockStack>
              </FormLayout>
            </Card>
          </BlockStack>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 6, md: 2, lg: 4 }}>
          <BlockStack gap={"300"}>
            <Card>
              <BlockStack gap={"200"}>
                {/* <Text as="h1" variant="headingSm">
                  Change email
                </Text> */}
                <ChangeEmail
                  email={editUser?.email}
                  loading={editedUserFetching}
                  id={user_id || ""}
                  isAdmin={editUser?.role === usersStatus.admin}
                />
              </BlockStack>
            </Card>
            <Card>
              <ChangePassword user_id={user_id || ""} />
            </Card>
          </BlockStack>
        </Grid.Cell>
      </Grid>
    </Page>
  );
};

export default EditUser;
