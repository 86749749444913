import {
  Page,
  Button,
  LegacyCard,
  FormLayout,
  TextField,
  Select,
  Banner,
} from "@shopify/polaris";
import {
  useGetAccountToEditQuery,
  useUpdateAccountMutation,
} from "../store/store/slices/AccountRTK";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { AccountFormData } from "../store/types/Accounts";

export default function EditAccount() {
  const navigateTo = useNavigate();
  const { account_id } = useParams<{ account_id: string }>();
  const {
    data: account,
    isLoading: gettingAccount,
    isError: gettingAccountFailed,
    error: gettingAccountError,
  } = useGetAccountToEditQuery(account_id!);
  const [updateAccount, { isLoading: updating, isError, isSuccess, error }] =
    useUpdateAccountMutation();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<AccountFormData>({
    defaultValues: {
      api_password:
        account?.account?.company === "POSTEX"
          ? "Postex does not require a password"
          : account?.account?.api_password || "",
      name: account?.account?.name || "",
      is_default: account?.account?.is_default ? "default" : "false",
      in_active: account?.account?.in_active ? "IN_ACTIVE" : "ACTIVE",
      address_code: account?.account?.address_code || "",
      weight: "",
    },
  });

  useEffect(() => {
    if (account) {
      reset({
        api_password:
          account.account.company === "POSTEX"
            ? "Postex does not require a password"
            : account.account.api_password || "",
        name: account.account.name || "",
        is_default: account.account.is_default ? "default" : "false",
        address_code: account.account.address_code || "",
        weight: account.account.weight || "",
      });
    }
    return () => {
      reset({
        api_password: "",
        name: "",
        is_default: "false",
        address_code: "",
      });
    };
  }, [account, reset]);

  const onSubmit = async (formData: AccountFormData) => {
    await updateAccount({
      accountId: account_id!,
      accountData: {
        ...formData,
        is_default: formData.is_default === "default",
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      navigateTo("/dashboard/accounts");
    }
  }, [isError, isSuccess, navigateTo, error]);
  if (gettingAccountFailed) {
    return (
      <Page
        backAction={{
          content: "Settings",
          url: "",
          onAction() {
            navigateTo("/dashboard/accounts");
          },
        }}
        title="Edit Account"
        narrowWidth
      >
        <Banner title="An error occurred" tone="critical">
          <p>{gettingAccountError?.data?.errors[0].msg}</p>
        </Banner>
      </Page>
    );
  }
  return (
    <Page
      backAction={{
        content: "Settings",
        url: "",
        onAction() {
          navigateTo("/dashboard/accounts");
        },
      }}
      title="Edit Account"
      primaryAction={
        <Button
          variant="primary"
          onClick={handleSubmit(onSubmit)}
          loading={updating || gettingAccount}
          disabled={updating}
        >
          Save Changes
        </Button>
      }
      narrowWidth
    >
      <p className="my_5 small_text">
        If you change your API key, any previously booked orders may no longer
        be trackable using this API. Please ensure that your API key remains
        consistent to maintain tracking functionality for your past orders.
      </p>
      <LegacyCard sectioned>
        <FormLayout>
          {account?.account?.company !== "POSTEX" ? (
            <Controller
              control={control}
              name="api_password"
              rules={{ required: account?.account?.company !== "POSTEX" }}
              render={({ field }) => (
                <TextField
                  label="Api Password"
                  {...field}
                  type="password"
                  loading={gettingAccount}
                  autoComplete="api-password"
                  placeholder="Api password"
                  disabled={account?.account?.company === "POSTEX"}
                  error={errors.api_password ? "API password is required" : ""}
                />
              )}
            />
          ) : (
            <Controller
              control={control}
              name="address_code"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Address code"
                  value={value || ""}
                  autoComplete="Off"
                  onChange={onChange}
                  placeholder="Enter postex address code"
                  requiredIndicator
                  error={errors.address_code ? "Address code is required" : ""}
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="name"
            rules={{ required: "Account name is required" }}
            render={({ field }) => (
              <TextField
                label="Account Name"
                {...field}
                loading={gettingAccount}
                autoComplete="account-name"
                placeholder="'My Account'"
                requiredIndicator
                error={errors.name?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="weight"
            rules={{ required: "Weight is required" }}
            render={({ field }) => (
              <TextField
                label="Weight: (in grams)"
                {...field}
                type="number"
                loading={gettingAccount}
                min={0}
                autoComplete="weight"
                placeholder="Enter weight"
                requiredIndicator
                error={errors?.weight && "Weight is required"}
              />
            )}
          />
          <Controller
            control={control}
            name="is_default"
            render={({ field }) => (
              <Select
                {...field}
                label={
                  <p>
                    Set As Default:{" "}
                    <span style={{ fontSize: "12px" }}>
                      You can set an account default only when you haven't
                      already
                    </span>
                  </p>
                }
                options={[
                  { label: "No", value: "false" },
                  { label: "Default", value: "default" },
                ]}
                disabled={updating || gettingAccount}
              />
            )}
          />
          <Controller
            control={control}
            name="in_active"
            render={({ field }) => (
              <Select
                {...field}
                label={
                  <p>
                    Set Activity:{" "}
                    <span style={{ fontSize: "12px" }}>
                      Inactive accounts will not be displayed on booking page
                    </span>
                  </p>
                }
                options={[
                  { label: "ACTIVE", value: "ACTIVE" },
                  { label: "IN ACTIVE", value: "IN_ACTIVE" },
                ]}
                disabled={updating || gettingAccount}
              />
            )}
          />
        </FormLayout>
      </LegacyCard>
    </Page>
  );
}
