import {
  Badge,
  Banner,
  BlockStack,
  Button,
  CalloutCard,
  Image,
  Layout,
  LegacyCard,
  List,
  Page,
  Text,
} from "@shopify/polaris";
import { AlertTriangleIcon } from "@shopify/polaris-icons";
import ordersPageFirst from "../assets/gif/orders-page.gif";
import cancelOrderGif from "../assets/gif/order-cancel.gif";
import liveTrack from "../assets/gif/order-tracking.gif";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const REQUIRED_SCOPES = [
  { title: "READ_ORDERS", explanation: "visit order(s) page" },
  { title: "WRITE_ORDERS", explanation: "book order(s)" },
  { title: "UPDATE_ORDERS", explanation: "track order(s) live" },
];

const OrdersDocs = () => {
  const navigateTo = useNavigate();
  const [showScopes, setShowScopes] = useState(false);

  return (
    <Page
      title="Order"
      titleMetadata={
        <Badge tone="info" icon={AlertTriangleIcon} size="large">
          Admin access only. Users need <b>READ_ORDER</b> scope to access this
          dashboard.
        </Badge>
      }
    >
      <Layout>
        <Layout.Section>
          <LegacyCard title="Overview" sectioned>
            <BlockStack gap={"300"}>
              <p>
                The <b>Orders</b> page in the <b>BOOKEASE</b> app provides a
                detailed view of your orders with their current <b>statuses</b>.
                It displays orders in a paginated format, showing{" "}
                <b>50 orders</b> at a time.
              </p>
              <div>
                <BlockStack gap={"300"}>
                  <div>
                    <Button
                      variant="plain"
                      dataPrimaryLink
                      onClick={() => setShowScopes((prev) => !prev)}
                    >
                      Required scopes
                    </Button>
                  </div>
                  {showScopes && (
                    <List>
                      {REQUIRED_SCOPES.map((scope) => (
                        <List.Item>
                          <p>
                            <Badge tone="magic">{scope.title}</Badge>{" "}
                            {scope.explanation}
                          </p>
                        </List.Item>
                      ))}
                    </List>
                  )}
                </BlockStack>
              </div>
              <div>
                <Image
                  source={ordersPageFirst}
                  alt="Dashboard image"
                  width="100%"
                />
              </div>
            </BlockStack>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <LegacyCard title="Order status" sectioned>
            <List>
              <List.Item>
                <p>
                  <Text as="span" fontWeight="bold">
                    FULFILLED
                  </Text>{" "}
                  orders booked on the courier but not yet handed over to the
                  courier.
                </p>
              </List.Item>
              <List.Item>
                <p>
                  <Text as="span" fontWeight="bold">
                    INPROCESS
                  </Text>{" "}
                  orders booked and handed over to the courier.
                </p>
              </List.Item>
              <List.Item>
                <p>
                  <Text as="span" fontWeight="bold">
                    CANCELLED
                  </Text>{" "}
                  orders canceled with the courier and marked as unfulfilled on
                  Shopify.
                </p>
              </List.Item>
              <List.Item>
                <p>
                  <Text as="span" fontWeight="bold">
                    RTS
                  </Text>{" "}
                  orders returned to the sender.
                </p>
              </List.Item>
              <List.Item>
                <p>
                  <Text as="span" fontWeight="bold">
                    DELIVERED
                  </Text>{" "}
                  orders successfully delivered to the customer.
                </p>
              </List.Item>
            </List>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <CalloutCard
            title="Filters"
            illustration={"https://iili.io/24vZw3N.png"}
            primaryAction={{
              content: "Explore",
              onAction: () => {
                navigateTo("/dashboard/orders");
              },
            }}
          >
            <LegacyCard.Section>
              <List type="bullet">
                <List.Item>Status</List.Item>
                <List.Item>Booked by</List.Item>
                <List.Item>Tag</List.Item>
                <List.Item>Courier</List.Item>
                <List.Item>Track #</List.Item>
                <List.Item>Phone number</List.Item>
                <List.Item>Delivery days</List.Item>
                <List.Item>Destination city</List.Item>
                <List.Item>Order booking date</List.Item>
                <List.Item>Order delivered date</List.Item>
              </List>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <p>
                You can also <b>search your orders</b>. This{" "}
                <b>advanced filtration</b> feature makes it easy to locate
                specific orders efficiently.
              </p>
            </LegacyCard.Section>
          </CalloutCard>
        </Layout.Section>
        <Layout.Section>
          <LegacyCard title="Order cancel">
            <LegacyCard.Section>
              <BlockStack gap={"300"}>
                <p>
                  If you want to cancel an order from the courier and unfulfill
                  it from Shopify, simply hit the <b>Cancel</b> button. This
                  will cancel the order and mark it as <b>CANCELLED</b>.
                </p>
                <div>
                  <Banner tone="info">
                    {" "}
                    For <b>M&P</b> orders, clicking the cancel button will only
                    cancel the Shopify fulfillment. It will not cancel the
                    parcel with <b>M&P</b>. You will need to manually cancel the
                    parcel with <b>M&P</b>, as <b>M&P</b> does not provide a
                    route or API for canceling parcels.
                  </Banner>
                </div>
                <div>
                  <Image
                    source={cancelOrderGif}
                    alt="Dashboard image"
                    width="100%"
                  />
                </div>
              </BlockStack>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        {/* track order */}
        <Layout.Section>
          <LegacyCard title="Order live tracking">
            <LegacyCard.Section>
              <BlockStack gap={"300"}>
                <p>
                  In the Orders Table, clicking on an order's row will open a
                  modal displaying the live tracking information of the parcel.
                  This provides <b>real-time</b> updates on the parcel's status
                  and its current location.
                </p>
                <div>
                  <Banner tone="info">
                    Additionally, for <b>M&P</b> orders, freshly booked orders
                    may take 5 to 7 minutes to become available for live
                    tracking.
                  </Banner>
                </div>
                <div>
                  <Image
                    source={liveTrack}
                    alt="Dashboard image"
                    width="100%"
                  />
                </div>
              </BlockStack>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default OrdersDocs;
