/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  OrderFulfilledIcon,
  OrderUnfulfilledIcon,
  PackageFulfilledIcon,
  DeliveryIcon,
} from "@shopify/polaris-icons";
export const extractLastId = (inputString: string) => {
  const regex = /\/(\d+)$/;
  const match = inputString?.match(regex);
  if (match) {
    return match[1];
  } else {
    return null;
  }
};

export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function convertStringToUniqueArray(str: string) {
  try {
    let array = JSON.parse(str);
    array = [...new Set(array)];
    return array;
  } catch (error) {
    return [];
  }
}

export function removeEmptyElements(array: any[]) {
  return array.filter(
    (element) => element !== undefined && element !== null && element !== ""
  );
}

export function convertOrderDataToChartData(data: any) {
  return [
    {
      name: "Status Fulfilled",
      y: data?.byStatus?.FULFILLED || 0,
      icon: OrderFulfilledIcon,
      base: "caution",
    },
    {
      name: "Status Inprocess",
      y:
        data?.totalOrders -
        (data?.byStatus?.FULFILLED || 0) -
        (data?.byStatus?.CANCELLED || 0) -
        (data?.byStatus?.DELIVERED || 0),
      icon: DeliveryIcon,
      base: "emphasis",
    },
    {
      name: "Status Delivered",
      y: data?.byStatus?.DELIVERED || 0,
      icon: PackageFulfilledIcon,
      base: "success",
    },
    {
      name: "Status Cancelled",
      y: data?.byStatus?.CANCELLED || 0,
      icon: OrderUnfulfilledIcon,
      base: "critical",
    },
  ];
}

export const convertCompaniesData = (data: any) => {
  return [
    {
      name: "M&p Orders",
      y: data?.byCompany?.MNP || 0,
      icon: OrderUnfulfilledIcon,
      base: "critical",
    },
    {
      name: "Leopard Orders",
      y: data?.byCompany?.LEOPARD || 0,
      icon: OrderUnfulfilledIcon,
      base: "critical",
    },
    {
      name: "Postex Orders",
      y: data?.byCompany?.POSTEX || 0,
      icon: OrderUnfulfilledIcon,
      base: "critical",
    },
    {
      name: "Total Orders",
      y: data?.totalOrders || 0,
      icon: OrderUnfulfilledIcon,
      base: "critical",
    },
  ];
};

export const isValidObjectId = (id: string): boolean => {
  const objectIdPattern = /^[a-fA-F0-9]{24}$/;
  return objectIdPattern.test(id);
};
