import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface User {
  username: string;
  storeName: string;
  free_shipping_value: number;
  default_shipping: number;
  default_weight: number;
  access_scopes: [string];
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("auth_token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchUser: builder.query<User, void>({
      query: () => "me",
    }),
    login: builder.mutation<{ data: User }, { token: string }>({
      query: ({ token }) => ({
        url: "me",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          localStorage.setItem("auth_token", args.token);
          localStorage.setItem("user", JSON.stringify(data));
        } catch (error) {
          console.error("Login error:", error);
        }
      },
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: "logout",
        method: "POST",
      }),
      async onQueryStarted() {
        try {
          //   dispatch(authApi.util.invalidateTags(["User"]));
          localStorage.removeItem("auth_token");
          localStorage.removeItem("user");
        } catch (error) {
          console.error("Logout error:", error);
        }
      },
    }),
  }),
});

export const { useFetchUserQuery, useLoginMutation, useLogoutMutation } =
  authApi;
