// src/features/modalSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showModal: false,
  content: "", // Content of the modal
  heading: "", // Heading of the modal
  onConfirm: null, // Function to execute on confirm
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { content, heading, onConfirm } = action.payload;
      state.showModal = true;
      state.content = content || "Are You sure?"; // Default content
      state.heading = heading || "Please add heading"; // Default heading
      state.onConfirm = onConfirm || null; // Default to null
    },
    closeModal: (state) => {
      state.showModal = false;
      state.content = "";
      state.heading = "";
      state.onConfirm = null;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
