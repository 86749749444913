/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Badge,
  BlockStack,
  Box,
  Card,
  FormLayout,
  Grid,
  InlineStack,
  Link,
  Page,
  Tag,
  Text,
  TextField,
  Thumbnail,
} from "@shopify/polaris";
import { OrderFulfilledIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { LINE_ITEM } from "../store/types/MergeOrder";

const PostBookingPage = ({ data }: any) => {
  const navigateTo = useNavigate();
  return (
    <Page
      title="Order has been booked..."
      subtitle={data?.name + " - " + data?.bookedDate}
      primaryAction={{
        content: "See orders",
        onAction: () => {
          navigateTo("/dashboard/orders");
        },
      }}
    >
      <Grid columns={{ xs: 1, md: 2 }}>
        <Grid.Cell columnSpan={{ md: 6, lg: 8 }}>
          <BlockStack gap={"400"}>
            <Card>
              <div style={{ margin: "1rem 0" }}>
                <Badge icon={OrderFulfilledIcon} tone="success" size="large">
                  Fullfilled
                </Badge>
              </div>
              <BlockStack gap={"200"}>
                <Text as="h1" fontWeight="medium">
                  Items detail
                </Text>
                {data?.lineItems?.map((product: LINE_ITEM) => (
                  <Box
                    borderRadius="200"
                    borderWidth="025"
                    borderColor="border-disabled"
                    paddingInline={"300"}
                    paddingBlock={"300"}
                    background="bg-fill"
                  >
                    <div style={{ display: "flex", gap: "1rem" }}>
                      <div>
                        <Thumbnail
                          size="small"
                          source={product?.image?.url}
                          alt="product image"
                        ></Thumbnail>
                      </div>
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: "3px",
                          }}
                        >
                          <BlockStack gap={"100"}>
                            <Text
                              variant="bodyMd"
                              fontWeight="medium"
                              as="span"
                            >
                              {product?.product?.title}
                            </Text>
                            <div>
                              {" "}
                              {product?.variant?.title !== "Default Title" && (
                                <Tag>
                                  <Text
                                    as="span"
                                    variant="bodySm"
                                    fontWeight="regular"
                                  >
                                    {product?.variant?.title}
                                  </Text>
                                </Tag>
                              )}
                            </div>
                          </BlockStack>
                          <Text as="span" variant="bodyMd">
                            Rs.
                            {
                              product?.originalUnitPriceSet?.presentmentMoney
                                ?.amount
                            }
                          </Text>
                        </div>
                        <BlockStack gap={"200"}>
                          <Text as="span" variant="bodyMd">
                            Qty : {product?.currentQuantity}
                          </Text>
                        </BlockStack>
                      </div>
                    </div>
                  </Box>
                ))}
              </BlockStack>
            </Card>

            <Box
              borderRadius="200"
              borderWidth="025"
              borderColor="border-disabled"
              paddingInline={"300"}
              paddingBlock={"300"}
              background="bg-fill"
            >
              <BlockStack gap={"200"}>
                <Text as="h2" fontWeight="semibold" variant="headingSm">
                  Payment
                </Text>
                <Box
                  borderRadius="200"
                  borderWidth="025"
                  borderColor="border-disabled"
                  paddingInline={"300"}
                  paddingBlock={"300"}
                  background="bg-fill"
                >
                  <BlockStack gap={"150"}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text as="span" variant="bodyMd">
                        Cod amount
                      </Text>
                      <Text as="span" variant="bodyMd">
                        Rs.{Number(data?.cod).toFixed(2)}
                      </Text>
                    </div>
                  </BlockStack>
                </Box>
              </BlockStack>
            </Box>
          </BlockStack>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ md: 4, lg: 4 }}>
          <BlockStack gap={"400"}>
            <Card>
              <BlockStack gap={"400"}>
                <Text as="h1" fontWeight="medium">
                  Consignee details
                </Text>
                <FormLayout.Group>
                  <TextField
                    readOnly
                    value={data?.consignee?.name}
                    label="Name"
                    autoComplete="Off"
                  ></TextField>
                  <TextField
                    readOnly
                    value={data?.consignee?.email}
                    label="Email"
                    autoComplete="Off"
                  ></TextField>
                  <TextField
                    readOnly
                    value={data?.consignee?.phone}
                    label="Phone"
                    autoComplete="Off"
                  ></TextField>
                  <TextField
                    readOnly
                    value={data?.consignee?.phone2}
                    label="Phone 2"
                    autoComplete="Off"
                  ></TextField>
                  <TextField
                    readOnly
                    value={data?.consignee?.address}
                    label="Address"
                    autoComplete="Off"
                  ></TextField>
                </FormLayout.Group>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"400"}>
                <Text as="h1" fontWeight="medium">
                  Tracking details
                </Text>
                <InlineStack align="space-between">
                  <Text as="p">Cn #</Text>
                  <Link url={`/track/${data?.trackingNumber}`}>
                    {data?.trackingNumber}
                  </Link>
                </InlineStack>
                <InlineStack align="space-between">
                  <Text as="p">City</Text>
                  <p>{data?.destination}</p>
                </InlineStack>
                <InlineStack align="space-between">
                  <Text as="p">Courier</Text>
                  <p>{data?.courier}</p>
                </InlineStack>
              </BlockStack>
            </Card>
          </BlockStack>
        </Grid.Cell>
      </Grid>
    </Page>
  );
};

export default PostBookingPage;
