/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Badge,
  Banner,
  BlockStack,
  Card,
  Grid,
  Icon,
  Modal,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
  TextContainer,
} from "@shopify/polaris";
import { CheckIcon } from "@shopify/polaris-icons";
import { useState, useEffect } from "react";
import OrderStatus from "./OrderStatus";
import { GENERALIZED_STATUSES } from "../../utils/enums";
import { useGetParcelDetailsQuery } from "../../store/store/slices/TrackOrderRTK";
import formatDate from "../../hooks/useDate";
import { ModalProps } from "../../store/types/types";

function ModalComponent({
  text = "TEXT",
  heading = "HEADING",
  open,
  type,
  close,
  id,
}: ModalProps) {
  const [opened, setOpened] = useState(open);
  const [shouldFetch, setShouldFetch] = useState(false);
  const {
    data: parcel,
    error,
    isLoading,
  }: any = useGetParcelDetailsQuery(id, {
    skip: !shouldFetch,
  });

  useEffect(() => {
    setOpened(open);
    if (open) {
      setShouldFetch(true);
    }
  }, [open]);

  const modalActions = () => {
    setOpened(false);

    close();
    setShouldFetch(false);
  };

  return (
    <Modal
      open={opened}
      onClose={modalActions}
      title={
        type === "TRACKING" && !isLoading && parcel
          ? heading + " - " + parcel?.order?.track_number
          : heading
      }
    >
      {error ? (
        <Modal.Section>
          <Banner title="Error tracking order">
            <p>Something went wrong while tracking this order.</p>
            <p>In case of M&P, it takes some time to reflect the order data.</p>
          </Banner>
        </Modal.Section>
      ) : (
        <Modal.Section>
          {isLoading ? (
            <Grid columns={{ xs: 2, lg: 2 }}>
              {Array(8)
                .fill(undefined)
                .map((_, index) => (
                  <Grid.Cell key={index}>
                    <SkeletonDisplayText size="medium" maxWidth="100%" />
                  </Grid.Cell>
                ))}
              <Grid.Cell columnSpan={{ lg: 2 }}>
                <p style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <SkeletonDisplayText size="extraLarge" maxWidth="100%" />
                </p>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ lg: 2 }}>
                <div
                  style={{
                    width: "50%",
                    margin: "auto",
                  }}
                >
                  <SkeletonBodyText lines={1}></SkeletonBodyText>
                </div>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ lg: 2 }}>
                <p style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <SkeletonDisplayText size="extraLarge" maxWidth="100%" />
                </p>
              </Grid.Cell>
            </Grid>
          ) : type === "TRACKING" ? (
            <Grid columns={{ xs: 2, lg: 2 }}>
              <Grid.Cell>
                <Text as="h3" fontWeight="medium">
                  COMPANY :{" "}
                  <Badge
                    tone={
                      parcel?.company === "LEOPARD"
                        ? "info"
                        : parcel?.company === "MNP"
                        ? "magic"
                        : "success"
                    }
                  >
                    {parcel?.company}
                  </Badge>
                </Text>
              </Grid.Cell>
              <Grid.Cell>
                <Text as="h3" fontWeight="medium">
                  BOOKING : {parcel?.order?.booking_date}
                </Text>
              </Grid.Cell>

              <Grid.Cell>
                <Text as="h3" fontWeight="medium">
                  CONSIGNEE NAME : {parcel?.order?.consignee_name}
                </Text>
              </Grid.Cell>
              <Grid.Cell>
                <Text as="h3" fontWeight="medium">
                  STATUS :{" "}
                  <Badge tone="info">
                    {GENERALIZED_STATUSES[parcel?.order?.current_status - 1]}
                  </Badge>
                </Text>
              </Grid.Cell>
              <Grid.Cell>
                <Text as="h3" fontWeight="medium">
                  CONSIGNEE PHONE : {parcel?.order?.customer_phone}
                </Text>
              </Grid.Cell>
              <Grid.Cell>
                <Text as="h3" fontWeight="medium">
                  DESTINATION :{" "}
                  <Badge tone="info">{parcel?.order?.destination}</Badge>
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ lg: 2 }}>
                <Text as="h3" fontWeight="medium">
                  CURRENT COURIER STATUS :{" "}
                  <Badge tone="info">
                    {parcel?.order?.current_courier_status}
                  </Badge>
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ lg: 2 }}>
                <OrderStatus status={parcel?.order?.current_status} />
              </Grid.Cell>
            </Grid>
          ) : (
            <TextContainer>
              <p>
                {text} - {parcel?.order?.current_status}
              </p>
            </TextContainer>
          )}
          {parcel?.order?.details?.length > 0 && (
            <div style={{ margin: "20px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  justifyContent: "center",
                  margin: "20px",
                }}
              >
                <div
                  style={{
                    width: "100px",
                    background: "gray",
                    opacity: ".5",
                    height: "2px",
                  }}
                ></div>
                <Text as="h2">Details</Text>
                <div
                  style={{
                    width: "100px",
                    background: "gray",
                    opacity: ".5",
                    height: "2px",
                  }}
                ></div>
              </div>
              {parcel?.order?.details?.length > 0 &&
                parcel?.order?.details?.map((detail: any) => (
                  <div style={{ marginTop: "10px" }}>
                    <Grid>
                      <Grid.Cell columnSpan={{ lg: 1 }}>
                        <div
                          className="summary_icon_box_wrapper"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="summary_icon_box">
                            <Icon source={CheckIcon} tone="inherit" />
                          </div>
                        </div>
                      </Grid.Cell>
                      <Grid.Cell columnSpan={{ lg: 11 }}>
                        <Card roundedAbove="xs">
                          <BlockStack gap={"100"}>
                            <Text
                              variant="headingMd"
                              fontWeight="regular"
                              as="h2"
                            >
                              {detail?.status_message} - {detail?.name}
                            </Text>
                            <Text variant="bodyXs" fontWeight="regular" as="h2">
                              {formatDate(detail?.status_date)}
                            </Text>
                          </BlockStack>
                        </Card>
                      </Grid.Cell>
                    </Grid>
                  </div>
                ))}
            </div>
          )}
        </Modal.Section>
      )}
    </Modal>
  );
}

export default ModalComponent;
