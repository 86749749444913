import { Banner, List } from "@shopify/polaris";

function AccountAlert() {
  return (
    <Banner tone="success" title="Tracking account creation:">
      <List>
        <List.Item>
          Please verify that the API key, username, and token because you will
          not be able to change or delete these details.
        </List.Item>
        <List.Item>
          Ensure all information is accurate before proceeding.
        </List.Item>
      </List>
    </Banner>
  );
}

export default AccountAlert;
