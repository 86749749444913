/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Checkbox,
  Collapsible,
  Icon,
  InlineStack,
  Text,
} from "@shopify/polaris";
import { ChevronDownIcon } from "@shopify/polaris-icons";
import { useEffect, useState } from "react";
import { SCOPES } from "../../../utils/enums";

const ScopesCard = ({
  pushSelectedScopesToParent,
  scopes,
}: {
  pushSelectedScopesToParent: any;
  scopes: string[] | undefined;
}) => {
  const [open, setOpen] = useState<string[]>(SCOPES.map((scope) => scope.type));
  const [selectedScopes, setSelectedScopes] = useState<string[]>([]);

  useEffect(() => {
    if (scopes) {
      setSelectedScopes(scopes);
    }
  }, [scopes]);

  const isTypeSelected = (type: string) =>
    SCOPES.find((scope) => scope.type === type)?.scopes.some((childScope) =>
      selectedScopes.includes(childScope.scope)
    );

  const handleTypeChange = (type: string) => {
    const childScopes = SCOPES.find((scope) => scope.type === type)?.scopes.map(
      (childScope) => childScope.scope
    );

    if (childScopes) {
      setSelectedScopes((prev) => {
        const isAllSelected = childScopes.every((child) =>
          prev.includes(child)
        );

        if (isAllSelected) {
          return prev.filter((scope) => !childScopes.includes(scope));
        }

        return [
          ...prev,
          ...childScopes.filter((child) => !prev.includes(child)),
        ];
      });
    }
  };

  const handleScopeChange = (scope: string) => {
    setSelectedScopes((prev) =>
      prev.includes(scope)
        ? prev.filter((item) => item !== scope)
        : [...prev, scope]
    );
  };

  const toggleOpen = (type: string) => {
    setOpen((prev) =>
      prev.includes(type)
        ? prev.filter((item) => item !== type)
        : [...prev, type]
    );
  };

  useEffect(() => {
    pushSelectedScopesToParent(selectedScopes);
  }, [selectedScopes]);

  return (
    <>
      <div>
        <>
          {SCOPES.map((scope, index) => (
            <div key={index}>
              <div className="scope_heading_wrapper">
                <InlineStack align="space-between">
                  <Checkbox
                    label={scope.type}
                    checked={isTypeSelected(scope.type)}
                    onChange={() => handleTypeChange(scope.type)}
                  />
                  <div
                    aria-controls={`basic-${index}`}
                    onClick={() => toggleOpen(scope.type)}
                    style={{
                      transition: ".2s ease-in-out",
                      rotate: `${
                        open.includes(scope.type) ? "-180deg" : "0deg"
                      }`,
                    }}
                  >
                    <Icon source={ChevronDownIcon} />
                  </div>
                </InlineStack>
              </div>
              <Collapsible
                open={open.includes(scope.type)}
                id={`basic-${index}`}
                transition={{
                  duration: "300ms",
                  timingFunction: "ease-in-out",
                }}
              >
                {scope.scopes.map((scopeTitle, idx) => (
                  <div key={idx} style={{ padding: ".3rem 1rem" }}>
                    <InlineStack align="space-between">
                      <Checkbox
                        label={
                          <p>
                            {scopeTitle.scope}{" "}
                            <Text as="span" variant="bodyXs">
                              {scopeTitle.description}
                            </Text>
                          </p>
                        }
                        checked={selectedScopes.includes(scopeTitle.scope)}
                        onChange={() => handleScopeChange(scopeTitle.scope)}
                      />
                    </InlineStack>
                  </div>
                ))}
              </Collapsible>
            </div>
          ))}
        </>
      </div>
    </>
  );
};

export default ScopesCard;
