import {
  Badge,
  Banner,
  BlockStack,
  Card,
  Image,
  List,
  Page,
  Text,
} from "@shopify/polaris";
import profile from "../assets/docs/qr-gif.gif";

const TwofactorAuth = () => {
  return (
    <Page
      title="Two Factor Authentication"
      titleMetadata={
        <Badge tone="success">Google authenticator recommended</Badge>
      }
    >
      <Card padding={"500"}>
        <BlockStack gap={"400"}>
          <p>
            Here, you will learn everything you need to know about{" "}
            <b>Two-Factor Authentication (2FA)</b> and how to secure your app by
            adding an extra layer of <b>security</b>. You'll understand how to
            implement <b>QR code scanning</b> for <b>authentication apps</b> and
            verify the <b>2FA code</b> after login. By adding <b>2FA</b> to your
            app, you will significantly reduce the risk of{" "}
            <b>unauthorized access</b>, ensuring that only users with the
            correct <b>credentials</b> and second-factor <b>token</b> can access
            their accounts.
          </p>
          <Text as="h1" fontWeight="bold" variant="headingMd">
            What is two factor authentication?
          </Text>
          <p>
            <b>Two-Factor Authentication (2FA)</b> adds an extra layer of
            security by requiring two forms of identification: a <b>password</b>{" "}
            and a <b>temporary code</b> from an authentication app.
          </p>

          <Text as="h1" fontWeight="bold" variant="headingMd">
            Page view
          </Text>
          <Image source={profile} alt="users page view" />
          <Banner tone="info">
            <p>
              If you're receiving an "invalid code" error even after entering
              the correct code, try using <b>Google Authenticator</b>. Switching
              to it can resolve the issue and ensure smooth{" "}
              <b>Two-Factor Authentication</b> for your account.
            </p>
          </Banner>
          <Text as="h2" fontWeight="bold" variant="headingSm">
            Steps
          </Text>
          <p>
            Here are the steps to set up <b>Two-Factor Authentication (2FA)</b>{" "}
            for your account:
          </p>
          <List>
            <List.Item>
              Scan the <b>QR Code</b>:
            </List.Item>
            <List>
              <List.Item>
                Open your <b>authentication app</b> (like{" "}
                <b>Google Authenticator</b>).
              </List.Item>
              <List.Item>
                Tap on "Scan <b>QR Code</b>" and scan the <b>QR code</b>{" "}
                displayed on your screen.
              </List.Item>
            </List>
            <List.Item>
              Enter the <b>Code</b>:
            </List.Item>
            <List>
              <List.Item>
                After scanning, the app will generate a time-sensitive{" "}
                <b>code</b>.
              </List.Item>
              <List.Item>
                Enter the <b>code</b> displayed in the app into the prompt on
                your screen.
              </List.Item>
            </List>
            <List.Item>
              Press <b>Verify Code</b>:
            </List.Item>
            <List>
              <List.Item>
                Click the "<b>Verify Code</b>" button to confirm the <b>code</b>
                .
              </List.Item>
            </List>
            <List.Item>Successful Verification</List.Item>
            <List>
              <List.Item>
                Once the <b>code</b> is verified, you'll see a message like "
                <b>Two-Factor Authentication added</b>."
              </List.Item>
              <List.Item>
                From now on, you will need to enter a <b>code</b> from your
                authentication app after each login to access your account.
              </List.Item>
            </List>
          </List>
        </BlockStack>
      </Card>
    </Page>
  );
};

export default TwofactorAuth;
