// App.tsx
import { RouterProvider } from "react-router-dom";
import router from "./routes/router.tsx";
import { Provider } from "react-redux";
import en from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import store from "./store/store/store.ts";
import AuthWrapper from "./components/atoms/AuthWrapper.tsx";
import Link from "./components/molecules/LinkOverride.tsx";

function App() {
  console.log(
    "Team BookEase is thrilled to simplify your order booking journey! 📦✨"
  );
  return (
    <>
      <Provider store={store}>
        <AppProvider i18n={en} linkComponent={Link}>
          <AuthWrapper>
            <RouterProvider router={router} />;
          </AuthWrapper>
        </AppProvider>
      </Provider>
    </>
  );
}

export default App;
