/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  FormLayout,
  Link,
  Page,
  Text,
  TextField,
} from "@shopify/polaris";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { login } from "../store/store/slices/AuthSlice";
import { Controller, useForm } from "react-hook-form";
import { IS_AUTHORIZED_TYPE } from "../store/types/types";
import { useLoginUserAccountMutation } from "../store/store/slices/LoginRTK";
// import ForgetPassword from "../components/molecules/ForgetPassword";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams?.get("token");
  const [loginMode, setLoginMode] = useState(false);
  const { isAuthorized } = useSelector(
    (state: IS_AUTHORIZED_TYPE) => state?.auth
  );
  const [err, setErr] = useState<string>("");
  const [loginUser, { isLoading, isSuccess, error, isError, data }] =
    useLoginUserAccountMutation<any>();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (token) {
      localStorage.setItem("auth_token", token);
      dispatch(login({ token } as any) as any);
    }
  }, []);
  useEffect(() => {
    dispatch(
      login({ token: localStorage.getItem("auth_token") } as any) as any
    );
  }, []);

  useEffect(() => {
    if (isError) {
      setErr(() => error?.data?.errors[0]?.msg || "Something went wrong");
    }
    if (isSuccess) {
      localStorage.setItem("auth_token", data?.auth_token);
      if (data?.two_factor_await) {
        navigate("/two-factor");
      } else {
        dispatch(login({ token: data?.auth_token } as any) as any);
      }
    }
  }, [isLoading, isSuccess, error, isError]);

  const userLoginHandler = async (data: FormData) => {
    await loginUser(data);
  };

  if (isAuthorized) {
    navigate("/dashboard");
  }
  return (
    <>
      <Page
        narrowWidth
        title='Bookease'
        secondaryActions={
          <ButtonGroup>
            {!loginMode ? (
              <Button
                variant='secondary'
                onClick={() => setLoginMode(true)}
                disabled={isLoading}
                loading={isLoading}
              >
                Sign in
              </Button>
            ) : (
              <Button
                variant='plain'
                disabled={isLoading}
                loading={isLoading}
                onClick={() => setLoginMode(false)}
              >
                Cancel
              </Button>
            )}
          </ButtonGroup>
        }
        primaryAction={
          <Button
            variant='primary'
            onClick={() => {
              window.location.href = "https://admin.shopify.com/";
            }}
            // disabled={isLoading}
          >
            Shopify admin
          </Button>
        }
      >
        {loginMode ? (
          <Card>
            <FormLayout>
              <FormLayout.Group>
                <Controller
                  control={control}
                  name='email'
                  rules={{ required: "Email is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete='off'
                      label='Email'
                      type='email'
                      requiredIndicator
                      placeholder="Enter email here..."
                      error={errors?.email && "Email is required or invalid"}
                    ></TextField>
                  )}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Controller
                  control={control}
                  name='password'
                  rules={{ required: "Password is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete='off'
                      label='Password'
                      type='password'
                      placeholder='Enter password here...'
                      requiredIndicator
                      // helpText={
                      //   <div
                      //     style={{
                      //       display: "flex",
                      //       justifyContent: "end",
                      //       alignItems: "center",
                      //     }}
                      //   >
                      //     <ForgetPassword />
                      //   </div>
                      // }
                      error={errors.password && "Password is required"}
                    ></TextField>
                  )}
                />
              </FormLayout.Group>
              <div style={{ paddingBottom: ".8rem" }}>
                <Text as='h1' tone='critical'>
                  {err}
                </Text>
              </div>
            </FormLayout>
            {/* <FormLayout.Group> */}
            <ButtonGroup>
              <Button
                onClick={handleSubmit(userLoginHandler as any)}
                disabled={isLoading}
                loading={isLoading}
              >
                Login
              </Button>
              <Button
                onClick={() => setLoginMode(false)}
                disabled={isLoading}
                loading={isLoading}
                variant='plain'
              >
                Cancel
              </Button>
            </ButtonGroup>
            {/* </FormLayout.Group> */}
          </Card>
        ) : (
          <Banner title='Wellcome to Bookease...'>
            <p>
              We are unable to authorize you. <br /> To authorize yourself,
              <br /> go to
              <strong>
                {" "}
                <Link
                  url="https://apps.shopify.com"
                  dataPrimaryLink
                  monochrome
                  target="_blank"
                >
                  shopify app store
                </Link>
              </strong>{" "}
              and install
              <strong> Bookease</strong>. <br /> If you have, <br /> go to your
              <strong>
                {" "}
                <Link
                  url="https://admin.shopify.com"
                  dataPrimaryLink
                  monochrome
                  target="_blank"
                >
                  shopify store admin
                </Link>{" "}
              </strong>{" "}
              <strong>&gt;</strong>
              <strong> apps</strong> and select
              <strong> Bookease</strong>. <br /> <span>OR</span> <br />
              <span>
                You can directly sign in by clicking on{" "}
                <Button
                  dataPrimaryLink
                  variant='plain'
                  onClick={() => {
                    setLoginMode(true);
                  }}
                >
                  Sign in
                </Button>
              </span>
            </p>
          </Banner>
        )}
      </Page>
    </>
  );
};

export default Login;
