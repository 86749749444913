/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router-dom";
import { Button, Page } from "@shopify/polaris";
import AccountsTable from "../components/atoms/AccountsTable";

const Accounts = () => {
  const navigate = useNavigate();
  return (
    <Page
      title="Accounts"
      backAction={{
        onAction: () => {
          navigate("/dashboard/orders");
        },
      }}
      primaryAction={
        <Button
          variant="primary"
          onClick={() => navigate("/dashboard/accounts/create-account")}
        >
          Create account
        </Button>
      }
    >
      <AccountsTable />
    </Page>
  );
};

export default Accounts;
