import {
  Badge,
  Banner,
  BlockStack,
  Button,
  Card,
  Image,
  List,
  Page,
  Text,
} from "@shopify/polaris";
import { AlertTriangleIcon } from "@shopify/polaris-icons";
import trImage from "../assets/docs/tracking-accounts.png";
import defAccImage from "../assets/docs/deafult-account.png";
import crAccImage from "../assets/docs/create-tracking-acc.gif";
import { useState } from "react";
const REQUIRED_SCOPES = [
  { title: "READ_ACCOUNTS", explanation: "visit account(s) page" },
  { title: "WRITE_ACCOUNTS", explanation: "can create and edit account(s)" },
];

const TrackingAccountsDocs = () => {
  const [showScopes, setShowScopes] = useState(false);
  return (
    <Page
      title="Tracking accounts"
      titleMetadata={
        <Badge tone="info" icon={AlertTriangleIcon} size="large">
          Admin access only. Users need <b>READ_ACCOUNTS</b> scope to access
          this dashboard.
        </Badge>
      }
    >
      <Card padding={"500"}>
        <BlockStack gap={"400"}>
          <Text as="h1" fontWeight="bold" variant="headingMd">
            What are tracking accounts?
          </Text>
          <p>
            Tracking accounts are essentially <b>API keys</b> or{" "}
            <b>passwords</b> provided by respective <b>couriers</b> (such as
            MNP, Leopard, POSTEX, etc.). These accounts are used to authenticate
            and interact with the courier services' <b>APIs</b>, enabling
            actions like <b>booking</b>, <b>canceling</b>, and{" "}
            <b>tracking orders</b>. Without a valid <b>tracking account</b>, it
            becomes difficult or even impossible to perform these operations, as
            the courier systems require this form of <b>authentication</b> to
            ensure secure and authorized requests.
          </p>
          <div>
            <BlockStack gap={"300"}>
              <div>
                <Button
                  variant="plain"
                  dataPrimaryLink
                  onClick={() => setShowScopes((prev) => !prev)}
                >
                  Required scopes
                </Button>
              </div>
              {showScopes && (
                <List>
                  {REQUIRED_SCOPES.map((scope) => (
                    <List.Item>
                      <p>
                        <Badge tone="magic">{scope.title}</Badge>{" "}
                        {scope.explanation}
                      </p>
                    </List.Item>
                  ))}
                </List>
              )}
            </BlockStack>
          </div>
          <Image source={trImage} alt="tracking accounts " />
          <div style={{ marginTop: "2rem" }}></div>
          <Text as="h1" fontWeight="bold" variant="headingMd">
            Deafult account
          </Text>
          <p>
            The <b>default account</b> is the account that is already selected
            on the <b>order booking form</b>. You can change it at any time to
            use a different tracking account for the order.
          </p>
          <Image source={defAccImage} alt="default account" />
          <Text as="h1" fontWeight="bold" variant="headingMd">
            Create account
          </Text>
          <Banner title="Important" tone="critical">
            Please ensure that the credentials you provide are valid. You cannot
            create an account without valid credentials. We authenticate the
            credentials before account creation from the respective courier to
            ensure they are correct.
            <br />
            <br />
            Please verify that the API key, username, and token because you will
            not be able to change or delete these details.
          </Banner>
          <p>
            Click on the <b>create account</b> button, and you will be
            redirected to the <b>create account page</b>. There, you will first
            need to select the <b>courier company</b>, as each courier has
            different <b>credentials</b> that are required for setting up the
            tracking account.
          </p>
          <Image source={crAccImage} alt="create account" />
          <Text as="h1" fontWeight="bold" variant="headingMd">
            Account create info:
          </Text>

          <p>
            <b>Select Provider:</b> Choose the provider, in this case,{" "}
            <b>LEOPARD</b>
            <br />
            <b>Account name:</b> You can write any name of your choice to
            uniquely identify your account.
            <br />
            <b>Weight:</b> (in grams) This weight will be displayed on the order
            booking page so you don't have to add it manually.
            <br />
            <b>API Key:</b> Enter the <b>API key</b> provided by Leopard.
            <br />
            <b>API Password:</b> Enter the <b>API password</b> provided by
            Leopard.
            <br />
            <b>Set As Default:</b> The default account will be automatically
            selected on the booking page.
          </p>
          <p>
            <b>Select Provider:</b> Choose the provider, in this case,{" "}
            <b>MNP</b>
            <br />
            <b>Account name:</b> You can write any name of your choice to
            uniquely identify your account.
            <br />
            <b>Weight:</b> (in grams) This weight will be displayed on the order
            booking page so you don't have to add it manually.
            <br />
            <b>M&P Username:</b> Enter the <b>username</b> provided by M&P.
            <br />
            <b>Account number:</b> Given by M&P, you can find it on the MNP
            dashboard {">"} reports {">"} consignee profile.
            <br />
            <b>M&P Password:</b> Enter the <b>password</b> provided by M&P.
            <br />
            <b>Set As Default:</b> The default account will be automatically
            selected on the booking page.
          </p>
          <p>
            <b>Select Provider:</b> Choose the provider, in this case,{" "}
            <b>POSTEX</b>
            <br />
            <b>Account name:</b> You can write any name of your choice to
            uniquely identify your account.
            <br />
            <b>Weight:</b> (in grams) This weight will be displayed on the order
            booking page so you don't have to add it manually.
            <br />
            <b>Token:</b> Enter the <b>Postex token</b>.
            <br />
            <b>Pickup Address ID:</b> Enter the <b>Postex address code</b>.
            <br />
            <b>Set As Default:</b> The default account will be automatically
            selected on the booking page.
          </p>
          <p>
            After clicking on <b>Save</b>, your account will be created, and you
            will be redirected to the <b>accounts page</b>.
          </p>
        </BlockStack>
      </Card>
    </Page>
  );
};

export default TrackingAccountsDocs;
