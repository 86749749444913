/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, TextField } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useEditTagMutation } from "../../store/store/slices/UsersRTK";
import { useGetUserQuery } from "../../store/store/slices/ProfileRTK";

type FieldValues = {
  tag: string;
};

export const TagEditModal = () => {
  // HOOKS
  const [modal, setModal] = useState<boolean>(false);
  const { control, handleSubmit } = useForm<FieldValues>();
  const { refetch } = useGetUserQuery(undefined);
  const [submitEditTag, { data, isLoading, isSuccess }] = useEditTagMutation();
  // Handlers
  const submitEditTagHandler: SubmitHandler<FieldValues> = async (data) => {
    await submitEditTag(data).unwrap();
  };
  //   EFFECTS
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setModal(false);
        refetch();
      }, 1000);
    }
  }, [data, isSuccess, isLoading]);

  return (
    <>
      <Button onClick={() => setModal(true)} variant="plain">
        Edit tag
      </Button>
      {/* modal */}
      <Modal
        title="Edit tag"
        open={modal}
        onClose={() => setModal(true)}
        secondaryActions={[
          {
            content: "Cancel",
            loading: isLoading,
            disabled: isLoading,
            onAction: () => {
              setModal(false);
            },
          },
        ]}
        primaryAction={{
          content: "Save",
          loading: isLoading,
          disabled: isLoading,
          onAction: handleSubmit(submitEditTagHandler),
        }}
      >
        <Modal.Section>
          <Controller
            name="tag"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <TextField
                autoComplete="Off"
                label="Tag"
                {...field}
                disabled={isLoading}
              />
            )}
          />
        </Modal.Section>
      </Modal>
    </>
  );
};
