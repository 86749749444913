/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./BaseQuery";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["users", "dashboard", "User"],
  endpoints: (builder) => ({
    getChildAccounts: builder.query({
      query: () => {
        return `/get-users`;
      },
      transformResponse: (resp: any) => resp?.childrenAccounts,
      providesTags: ["users"],
    }),
    getChildAccountsWithLimitedData: builder.query({
      query: () => {
        return `/get-users?page=orders`;
      },
      transformResponse: (resp: any) => resp?.childrenAccounts,
      providesTags: ["users"],
    }),
    getStoreSettings: builder.query({
      query: () => {
        return `/store/default-settings`;
      },
      transformResponse: (resp: any) => resp,
    }),
    getChildAccount: builder.query({
      query: ({ id }) => {
        return `/get-user-to-edit/${id}`;
      },
      transformResponse: (resp: any) => resp?.user,
      keepUnusedDataFor: 0,
    }),

    createChildAccounts: builder.mutation({
      query: (data) => ({
        url: `/create-user`,
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["users"],
    }),
    postPasswordChangePinCode: builder.mutation({
      query: (data) => ({
        url: `/password/reset-password-pin`,
        method: "POST",
        body: { ...data },
      }),
    }),
    postCreateNewPassword: builder.mutation({
      query: (data) => ({
        url: `/password/reset-password`,
        method: "POST",
        body: { ...data },
      }),
    }),
    editChildAccounts: builder.mutation({
      query: (data) => ({
        url: `/edit-user/${data?.id}`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["users"],
    }),
    loginUserAccount: builder.mutation({
      query: (data) => {
        return {
          url: `/login-user`,
          method: "POST",
          body: { ...data },
        };
      },
      // onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
      //   const { data: response } = await queryFulfilled;
      //   // if (response?.auth_token) {
      //   //   localStorage.setItem("auth_token", response.auth_token);
      //   // }
      // },
    }),
    restrictAccount: builder.mutation({
      query: (data) => ({
        url: `/restrict-user`,
        method: "PUT",
        body: { id: data.id },
      }),
      invalidatesTags: ["users"],
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: `/change-password`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["users"],
    }),
    editTag: builder.mutation({
      query: (data) => ({
        url: `/edit-tag`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["User"],
    }),
    editEmail: builder.mutation({
      query: (data) => ({
        url: `/change-email/${data.id}`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["User"],
    }),
    changePasswordByAdmin: builder.mutation({
      query: (data) => ({
        url: `/change-password/${data.id}`,
        method: "PATCH",
        body: { ...data },
      }),
      invalidatesTags: ["User"],
    }),
    deleteAccount: builder.mutation({
      query: (data) => ({
        url: `/delete-user/${data?.id}`,
        method: "DELETE",
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(usersApi.util.invalidateTags(["users"]));
        }, 500);
      },
    }),
  }),
});

export const {
  useEditTagMutation,
  useEditEmailMutation,
  useGetChildAccountQuery,
  useGetChildAccountsQuery,
  useGetStoreSettingsQuery,
  useDeleteAccountMutation,
  useChangePasswordMutation,
  useRestrictAccountMutation,
  useLoginUserAccountMutation,
  useEditChildAccountsMutation,
  useCreateChildAccountsMutation,
  useChangePasswordByAdminMutation,
  usePostCreateNewPasswordMutation,
  usePostPasswordChangePinCodeMutation,
  useGetChildAccountsWithLimitedDataQuery,
} = usersApi;
