/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@shopify/polaris";
import { useRestrictAccountMutation } from "../../../store/store/slices/UsersRTK";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/store/slices/AlertSlice";

const RestrictUser = ({ id, active }: { id: string; active: boolean }) => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [restrictUser, { data, isLoading, isSuccess }] =
    useRestrictAccountMutation();
  const restrictUserAccount = async () => {
    await restrictUser({ id });
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showToast({
          message: !active
            ? "User has been unrestricted"
            : "User has been restricted",
          error: false,
          visible: true,
        })
      );
      navigateTo("/dashboard/users");
    }
  }, [data, isSuccess]);
  return (
    <div style={{ marginLeft: ".8rem" }}>
      <Button
        size="slim"
        variant="plain"
        tone="critical"
        onClick={restrictUserAccount}
        disabled={isLoading}
        loading={isLoading}
      >
        {active ? "Deactivate" : "Active"}
      </Button>
    </div>
  );
};

export default RestrictUser;
