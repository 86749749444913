/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BlockStack,
  Button,
  Card,
  FormLayout,
  Frame,
  Page,
  Text,
  TextField,
  Toast,
} from "@shopify/polaris";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { usePostCreateNewPasswordMutation } from "../store/store/slices/UsersRTK";
import { useCallback, useEffect, useState } from "react";
// import Timer from "../components/atoms/Timer";

const ForgotPassword = () => {
  // HOOKS
  const location = useLocation();
  const navigateTo = useNavigate();
  const [passwordsMacthed, setPasswordsMatched] = useState<boolean>(true);
  const [createNewPassword, { isError, error, isLoading, isSuccess }] =
    usePostCreateNewPasswordMutation();
  const hash = new URLSearchParams(useLocation().search).get("hash");
  const _id = new URLSearchParams(useLocation().search).get("id");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup =
    active && isSuccess ? (
      <Toast
        content="Password has been changed"
        onDismiss={toggleActive}
        duration={2000}
      />
    ) : null;
  //   EFFECTS
  useEffect(() => {
    if (isSuccess) {
      setActive(isSuccess);
      setTimeout(() => {
        navigateTo("/login");
      }, 2000);
    }
  }, [isLoading, isError, error]);
  //   HANDLERS
  const onCreateNewPasswordHandler = async (formData: any) => {
    if (formData.password !== formData.confirm_password) {
      setPasswordsMatched(false);
      return;
    }
    setPasswordsMatched(true);
    await createNewPassword({
      ...formData,
      hash,
      email: location?.state?.email,
      _id,
    });
  };
  return (
    <Frame>
      <Page title="Create password" narrowWidth>
        <BlockStack gap={"300"}>
          {toastMarkup}
          {/* <Timer
            expirationTime={location?.state?.time}
            email={location?.state?.email}
          /> */}
          <Card>
            <BlockStack gap={"300"}>
              {/* <FormLayout>
                <Controller
                  name="pin"
                  rules={{ required: "Pin is required" }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete="Off"
                      type="number"
                      label="Enter pin"
                      error={errors.pin && "Pin is required"}
                      {...field}
                    ></TextField>
                  )}
                />
              </FormLayout> */}
              <FormLayout>
                <Controller
                  name="password"
                  rules={{ required: "Password is required" }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete="Off"
                      label="Enter new password"
                      error={errors.password && "Password is required"}
                      {...field}
                    ></TextField>
                  )}
                />
              </FormLayout>
              <FormLayout>
                <Controller
                  name="confirm_password"
                  rules={{ required: "Password is required" }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete="Off"
                      label="Confirm new password"
                      error={errors.confirm_password && "Password is required"}
                      {...field}
                    ></TextField>
                  )}
                />
              </FormLayout>
              {isError && (
                <Text as="p" tone="critical">
                  {error?.data?.errors[0].msg}
                </Text>
              )}
              {!passwordsMacthed && (
                <Text as="p" tone="critical">
                  Password and confirm password must be same
                </Text>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="primary"
                  onClick={handleSubmit(onCreateNewPasswordHandler)}
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Create new password
                </Button>
              </div>
            </BlockStack>
          </Card>
        </BlockStack>
      </Page>
    </Frame>
  );
};

export default ForgotPassword;
