/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import {
  BlockStack,
  Box,
  Button,
  Card,
  Grid,
  Icon,
  InlineStack,
  LegacyCard,
  Link,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
} from "@shopify/polaris";
import { CheckIcon, AlertCircleIcon } from "@shopify/polaris-icons";
import { useParams } from "react-router-dom";
import formatDate from "../hooks/useDate";
import OrderStatus from "../components/atoms/OrderStatus";
import { GENERALIZED_STATUSES } from "../utils/enums";
import { useTrackParcelQuery } from "../store/store/slices/TrackOrderRTK";

const TrackOrder = () => {
  const { track_number } = useParams();

  const [greetings, setGreatings] = useState("Hello");

  const {
    data: parcel,
    isLoading: trackingParcelLoading,
    isError,
  } = useTrackParcelQuery<any>(track_number);

  useEffect(() => {
    const date = new Date();
    const hrs = date.getHours();
    if (hrs < 4) {
      setGreatings("Dear");
    } else if (hrs > 4 && hrs < 12) {
      setGreatings("Good Morning");
    } else if (hrs >= 12 && hrs < 15) {
      setGreatings("Good Noon");
    } else if (hrs >= 15 && hrs < 17) {
      setGreatings("Good Afternoon");
    } else if (hrs > 4 && hrs <= 19) {
      setGreatings("Good Evening");
    } else {
      setGreatings("Dear");
    }
  }, []);

  if (isError) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f8f8f8",
          padding: "1rem",
        }}
      >
        <Box maxWidth="600px">
          <Card>
            <BlockStack>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Icon source={AlertCircleIcon} />
              </div>
              <Text as="h1" variant="headingLg" alignment="center">
                Error occurred!
              </Text>
              <div style={{ marginTop: "10px" }}></div>
              <Text as="p" tone="critical" alignment="center">
                <p style={{ lineHeight: "1.5rem" }}>
                  Something went wrong while tracking your order. Please try
                  again later. (Tracking No: <strong>{track_number}</strong>)
                </p>
              </Text>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="primary"
                  onClick={() => window.location.reload()}
                >
                  Try again
                </Button>
              </div>
            </BlockStack>
          </Card>
        </Box>
      </div>
    );
  }

  return (
    <Page>
      <div style={{ padding: "1rem" }}>
        <BlockStack gap={"400"}>
          <BlockStack gap={"100"}>
            {parcel ? (
              <Text variant="heading2xl" as="h2">
                {greetings}, {parcel?.order?.consignee_name}
              </Text>
            ) : (
              ""
            )}

            <Text variant="headingLg" fontWeight="medium" as="h2">
              Shipping Details
            </Text>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Text variant="headingSm" fontWeight="regular" as="h2">
                Thank you for trusting{" "}
              </Text>
              <Link
                url={`https://${parcel?.domain}`}
                target="_blank"
                dataPrimaryLink
              >
                {!trackingParcelLoading ? (
                  parcel?.store
                ) : (
                  <div style={{ marginTop: "5px" }}>
                    <Box width="90px">
                      <SkeletonBodyText lines={1} />
                    </Box>
                  </div>
                )}
              </Link>
            </div>
          </BlockStack>
          <Grid>
            <Grid.Cell columnSpan={{ xs: 6, lg: 8 }}>
              <LegacyCard sectioned>
                <BlockStack gap={"300"}>
                  <Text variant="headingLg" fontWeight="medium" as="h2">
                    Tracking Details
                  </Text>
                  <InlineStack blockAlign="center" align="space-between">
                    <Box>
                      {trackingParcelLoading ? (
                        <div style={{ width: "200px" }}>
                          <SkeletonDisplayText size="medium" maxWidth="100%" />
                        </div>
                      ) : (
                        <>
                          <Text variant="headingMd" fontWeight="medium" as="h2">
                            Tracking Id : {parcel?.order?.track_number}
                          </Text>
                          <Text variant="headingMd" fontWeight="medium" as="h2">
                            Courier : {parcel?.company}
                          </Text>
                          <Text variant="headingMd" fontWeight="medium" as="h2">
                            Order : {parcel?.order_name}
                          </Text>
                        </>
                      )}
                    </Box>
                    <Box>
                      {trackingParcelLoading ? (
                        <div style={{ width: "200px", paddingTop: "10px" }}>
                          <SkeletonDisplayText size="medium" maxWidth="100%" />
                        </div>
                      ) : (
                        <>
                          <Text variant="headingMd" fontWeight="medium" as="h2">
                            Consignee Name : {parcel?.order?.consignee_name}
                          </Text>
                          <Text variant="headingMd" fontWeight="medium" as="h2">
                            Booking Date : {parcel?.order?.booking_date}
                          </Text>
                        </>
                      )}
                    </Box>
                  </InlineStack>
                </BlockStack>
              </LegacyCard>
              <LegacyCard sectioned>
                <BlockStack gap={"300"}>
                  <InlineStack gap={"050"}>
                    <Text variant="headingSm" fontWeight="medium" as="h2">
                      Current Status:
                    </Text>
                    <Text
                      variant="headingSm"
                      fontWeight="medium"
                      as="h2"
                      tone={`success`}
                    >
                      {GENERALIZED_STATUSES[parcel?.order?.current_status - 1]}
                    </Text>

                    {/* <SkeletonBodyText lines={1} /> */}
                  </InlineStack>
                  {trackingParcelLoading ? (
                    <SkeletonDisplayText size="large" maxWidth="100%" />
                  ) : (
                    <OrderStatus status={parcel?.order?.current_status} />
                  )}
                </BlockStack>
              </LegacyCard>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, lg: 4 }}>
              <LegacyCard sectioned>
                <BlockStack gap={"300"}>
                  <Text variant="headingMd" fontWeight="medium" as="h2">
                    Details Summary
                  </Text>
                  {trackingParcelLoading && (
                    <>
                      <Box width="100%" maxWidth="">
                        <SkeletonDisplayText
                          maxWidth={`${100}%`}
                          size="extraLarge"
                        />
                      </Box>
                      <Box width="100%" maxWidth="">
                        <SkeletonDisplayText
                          maxWidth={`${100}%`}
                          size="extraLarge"
                        />
                      </Box>
                    </>
                  )}
                  {!trackingParcelLoading &&
                  parcel?.order?.details?.length > 0 ? (
                    parcel?.order?.details?.map((detail: any) => (
                      <Grid>
                        <Grid.Cell columnSpan={{ lg: 1 }}>
                          <div
                            className="summary_icon_box_wrapper"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="summary_icon_box">
                              <Icon source={CheckIcon} tone="inherit" />
                            </div>
                          </div>
                        </Grid.Cell>
                        <Grid.Cell columnSpan={{ xs: 5, lg: 11 }}>
                          <Card roundedAbove="xs">
                            <BlockStack gap={"100"}>
                              <Text
                                variant="headingMd"
                                fontWeight="regular"
                                as="h2"
                              >
                                {detail?.status_message} - {detail?.name}
                              </Text>
                              <Text
                                variant="bodyXs"
                                fontWeight="regular"
                                as="h2"
                              >
                                {formatDate(detail?.status_date)}
                              </Text>
                            </BlockStack>
                          </Card>
                        </Grid.Cell>
                      </Grid>
                    ))
                  ) : (
                    <Text as="p">No Progress</Text>
                  )}
                </BlockStack>
              </LegacyCard>
            </Grid.Cell>
          </Grid>
        </BlockStack>
      </div>
    </Page>
  );
};

export default TrackOrder;
