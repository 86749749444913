/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ActionList,
  Badge,
  Frame,
  Icon,
  Navigation,
  Tooltip,
  TopBar,
} from "@shopify/polaris";
import {
  ExitIcon,
  LiveIcon,
  LockIcon,
  OrderIcon,
  ProfileIcon,
  SettingsIcon,
  PersonSegmentIcon,
  ChartVerticalIcon,
  // ContractIcon,
  // ReceiptPaidIcon,
} from "@shopify/polaris-icons";
import { useState, useCallback } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/store/slices/AuthSlice";
import ToastAlert from "../atoms/ToastComp";

export default function LayoutWrapper() {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const pageLocation = useLocation();
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const userData = useSelector((state: any) => state.auth.user);
  const [userMenuActive, setUserMenuActive] = useState<boolean>(false);
  const [mobileNavigationActive, setMobileNavigationActive] =
    useState<boolean>(false);

  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
  }, []);
  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );
  const READ_ACCOUNT_ACCESS =
    userData?.access_scopes?.includes("READ_ACCOUNTS");
  const READ_ORDERS_ACCESS = userData?.access_scopes?.includes("READ_ORDERS");
  const READ_DASHBOARD_ACCESS =
    userData?.access_scopes?.includes("READ_ANALYTICS");
  const READ_SETTINGS_ACCESS =
    userData?.access_scopes?.includes("READ_SETTINGS");

  const userMenuActions = [
    {
      items: [
        {
          content: (
            <h1
              style={{
                display: "flex",
                gap: ".5rem",
                alignItems: "center",
              }}
            >
              <div>
                <Icon source={ProfileIcon} />
              </div>
              <span>Profile</span>
            </h1>
          ) as any,
          onAction: () => {
            navigateTo("/dashboard/profile");
          },
        },
        {
          content: (
            <h1
              style={{
                display: "flex",
                gap: ".5rem",
                alignItems: "center",
              }}
            >
              <div>
                <Icon source={LiveIcon} />
              </div>
              <span>Accounts</span>
            </h1>
          ) as any,
          onAction: () => {
            navigateTo("/dashboard/accounts");
          },
          disbaled: !READ_ACCOUNT_ACCESS && userData?.role !== "ADMIN",
        },
        {
          content: (
            <h1
              style={{
                display: "flex",
                gap: ".5rem",
                alignItems: "center",
                width: "160px",
              }}
            >
              <div>
                <Icon source={ExitIcon} />
              </div>
              <span>Logout</span>
            </h1>
          ) as any,
          onAction: () => {
            dispatch(logout());
          },
        },
      ],
    },
  ];

  const userMenuMarkup = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: ".5rem",
        }}
      >
        <Badge tone={userData?.role === "USER" ? "info" : "magic"}>
          {userData?.role}
        </Badge>
      </div>
      <TopBar.UserMenu
        actions={userMenuActions}
        name={userData?.first_name + " " + userData?.last_name}
        detail={userData?.shop?.name}
        initials={
          userData?.first_name?.charAt(0)?.toUpperCase() +
          "" +
          userData?.last_name?.charAt(0)?.toUpperCase()
        }
        open={userMenuActive}
        onToggle={toggleUserMenuActive}
      />
    </>
  );

  const searchResultsMarkup = (
    <ActionList
      items={[
        { content: "Shopify help center" },
        { content: "Community forums" },
      ]}
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      searchResultsVisible={searchActive}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );
  const navigationMarkup = (
    <Navigation location="/">
      <Navigation.Section
        fill
        separator={true}
        items={[
          {
            label: "Dashboard",
            icon: ChartVerticalIcon,
            onClick: () => navigateTo("/dashboard"),
            selected: pageLocation.pathname === "/dashboard",
            disabled: !READ_DASHBOARD_ACCESS && userData?.role !== "ADMIN",
          },
          {
            label: "Orders",
            icon: OrderIcon,
            onClick: () => navigateTo("/dashboard/orders"),
            selected: pageLocation.pathname === "/dashboard/orders",
            disabled: !READ_ORDERS_ACCESS && userData?.role !== "ADMIN",
          },
          {
            label: "Accounts",
            icon: LiveIcon,
            onClick: () => navigateTo("/dashboard/accounts"),
            selected:
              pageLocation.pathname === "/dashboard/accounts/create-account" ||
              pageLocation.pathname === "/dashboard/accounts",
            disabled: !READ_ACCOUNT_ACCESS && userData?.role !== "ADMIN",
          },
          {
            label: "Users",
            icon: PersonSegmentIcon,
            badge: (
              <Tooltip
                width="wide"
                content={
                  <p style={{ textAlign: "center" }}>
                    User won't be able to access user's data as it is only
                    accessible to store ADMIN
                  </p>
                }
              >
                <Badge tone="success" icon={LockIcon}>
                  Admin only
                </Badge>
              </Tooltip>
            ),
            onClick: () => navigateTo("/dashboard/users"),
            selected:
              pageLocation.pathname === "/dashboard/users" ||
              pageLocation.pathname === "/dashboard/create-user",
            disabled: userData?.role !== "ADMIN",
          },
          {
            label: "Settings",
            icon: SettingsIcon,
            onClick: () => navigateTo("/dashboard/settings"),
            selected: pageLocation.pathname === "/dashboard/settings",
            disabled: !READ_SETTINGS_ACCESS && userData?.role !== "ADMIN",
          },
          // {
          //   label: "Docs",
          //   icon: ContractIcon,
          //   url: "/dashboard/docs/dashboard",
          //   // badge: <Badge tone="attention">must read</Badge>,
          //   onClick: () => navigateTo("/dashboard/docs/dashboard"),
          //   selected: pageLocation.pathname === "/dashboard/docs/dashboard",
          //   subNavigationItems: [
          //     // {
          //     //   url: "/dashboard/docs",
          //     //   label: "Home",
          //     //   onClick: () => {},
          //     //   selected: pageLocation.pathname === "/dashboard/docs",
          //     // },
          //     {
          //       url: "/dashboard/docs/dashboard",
          //       label: "Dashboard",
          //       onClick: () => navigateTo("/dashboard/docs/dashboard"),
          //       disabled: false,
          //       exactMatch: true,
          //       matches: pageLocation.pathname === "/dashboard/docs/dashboard",
          //     },
          //     {
          //       url: "/dashboard/docs/orders",
          //       label: "Orders",
          //       onClick: () => navigateTo("/dashboard/docs/orders"),
          //       disabled: false,
          //       exactMatch: true,
          //       matches: pageLocation.pathname === "/dashboard/docs/orders",
          //     },
          //     {
          //       url: "/dashboard/docs/live-tracking",
          //       label: "Live tracking",
          //       onClick: () => navigateTo("/dashboard/docs/live-tracking"),
          //       disabled: false,
          //       exactMatch: true,
          //       matches:
          //         pageLocation.pathname === "/dashboard/docs/live-tracking",
          //     },
          //     {
          //       url: "/dashboard/docs/settings",
          //       label: "Settings",
          //       onClick: () => navigateTo("/dashboard/docs/settings"),
          //       disabled: false,
          //       exactMatch: true,
          //       matches: pageLocation.pathname === "/dashboard/docs/settings",
          //     },
          //     {
          //       url: "/dashboard/docs/tracking-accounts",
          //       label: "Tracking accounts",
          //       onClick: () => navigateTo("/dashboard/docs/tracking-accounts"),
          //       disabled: false,
          //       exactMatch: true,
          //       matches:
          //         pageLocation.pathname === "/dashboard/docs/tracking-accounts",
          //     },
          //     {
          //       url: "/dashboard/docs/order-booking",
          //       label: "Order booking",
          //       onClick: () => navigateTo("/dashboard/docs/order-booking"),
          //       disabled: false,
          //       exactMatch: true,
          //       matches:
          //         pageLocation.pathname === "/dashboard/docs/order-booking",
          //     },
          //     {
          //       url: "/dashboard/docs/merge-orders",
          //       label: "Merge orders",
          //       onClick: () => navigateTo("/dashboard/docs/merge-orders"),
          //       // disabled: false,
          //       // exactMatch: true,
          //       matches:
          //         pageLocation.pathname === "/dashboard/docs/merge-orders",
          //     },
          //     {
          //       url: "/dashboard/docs/users",
          //       label: "User management",
          //       onClick: () => navigateTo("/dashboard/docs/users"),
          //       disabled: false,
          //       exactMatch: true,
          //       matches: pageLocation.pathname === "/dashboard/docs/users",
          //     },

          //     {
          //       url: "/dashboard/docs/two-factor-auth",
          //       label: "Two factor auth",
          //       onClick: () => navigateTo("/dashboard/docs/two-factor-auth"),
          //       disabled: false,
          //       exactMatch: true,
          //       matches:
          //         pageLocation.pathname === "/dashboard/docs/two-factor-auth",
          //     },
          //   ],
          // },
          // {
          //   label: "Pricing & plans",
          //   icon: ReceiptPaidIcon,
          //   onClick: () => navigateTo("/dashboard/docs/plans"),
          //   selected: pageLocation.pathname === "/dashboard/docs/plans",
          // },
          // {
          //   label: "Guide",
          //   icon: BookOpenIcon,
          //   onClick: () => navigateTo("/dashboard/docs"),
          //   selected: pageLocation.pathname === "/dashboard/docs",
          // },

          // {
          //   label: "Getting Started",
          //   icon: BookOpenIcon,
          //   onClick: () => navigateTo("/docs"),
          //   selected: pageLocation.pathname === "/docs",
          //   subNavigationItems: [
          //     {
          //       label: "Create Account guide",
          //       url: "#",
          //       // onClick: () => navigateTo("/docs/orderbookingguide"),
          //       // selected: pageLocation.pathname === "/orderbookingguide",
          //     },
          //     {
          //       label: "book an order guide",
          //       url: "#",
          //       // onClick: () => navigateTo("/bookorder"),
          //       // selected: pageLocation.pathname === "/bookorder",
          //     },
          //     {
          //       label: "See booked order",
          //       url: "#",
          //       // onClick: () => navigateTo("/checkbookedorder"),
          //       // selected: pageLocation.pathname === "/checkbookedorder",
          //     },
          //     {
          //       label: "How to Edit Profile",
          //       url: "#",
          //       // onClick: () => navigateTo("/EditProfile"),
          //       // selected: pageLocation.pathname === "/EditProfile",
          //     },
          //     {
          //       label: "Book Order",
          //       url: "#",
          //       // onClick: () => navigateTo("/order/book?id=450789469"),
          //       // selected: pageLocation.pathname === "/order/book?id=450789469",
          //     },
          //   ],
          // },
        ]}
      />
      <Navigation.Section
        items={[
          {
            label: "Profile",
            icon: ProfileIcon,
            onClick: () => navigateTo("/dashboard/profile"),
            selected: pageLocation.pathname === "/dashboard/profile",
          },
        ]}
      ></Navigation.Section>
    </Navigation>
  );
  const logo = {
    topBarSource:
      "https://cdn.shopify.com/s/files/1/0372/5675/7381/files/BookEase.png?v=1730282412",
    contextualSaveBarSource:
      "https://cdn.shopify.com/s/files/1/0372/5675/7381/files/BookEase.png?v=1730282412",
    accessibilityLabel: "Shopify",
  };

  return (
    <div style={{ height: "500px" }}>
      <Frame
        logo={logo}
        topBar={topBarMarkup}
        navigation={navigationMarkup}
        showMobileNavigation={mobileNavigationActive}
      >
        <>
          <ToastAlert />
        </>
        <div style={{ padding: ".5rem" }}>
          <Outlet />
        </div>
      </Frame>
    </div>
  );
}
