/* eslint-disable @typescript-eslint/no-explicit-any */
// src/store/slices/CitiesSlice.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./BaseQuery";

export const citiesApi = createApi({
  reducerPath: "citiesApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    fetchCities: builder.query({
      query: (trackingAccountId: string) => `/get-cities/${trackingAccountId}`,
      transformResponse: (response: any) => {
        return response.cities;
      },
    }),
  }),
});

export const { useFetchCitiesQuery } = citiesApi;
