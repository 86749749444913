import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/Login";
import NotFound from "../pages/404";
import Orders from "../pages/Orders";
import Profile from "../pages/Profile";
import Accounts from "../pages/Accounts";
import Settings from "../pages/Settings";
import Dashboard from "../pages/Dashboard";
import Templates from "../pages/Templates";
import CreateOrder from "../pages/BookOrder";
import TrackOrder from "../pages/TrackOrder";
import Privacy from "../pages/PrivacyPolicy";
import EditAccount from "../pages/EditAccount";
import ProtectedRoute from "./auth/ProtectedRoute";
import NoAccount from "../components/atoms/NoAccount";
import OrderBookingGuide from "../docs/OrderBookingGuide";
import LayoutWrapper from "../components/organisms/LayoutWrapper";
import MergeOrderWrapper from "../pages/MergeOrder";
import LandingPage from "../pages/LandingPage";
import SettingsPage from "../pages/SettingsPage";
import Users from "../pages/Users";
import CreateUser from "../pages/CreateUser";
import EditUser from "../pages/EditUser";
import ForgotPassword from "../pages/ForgotPasswordOld";
import PostBookingPage from "../pages/PostBookingPage";
import TwoFactorPage from "../pages/TwoFactorPage";
import DashboardDocs from "../docs/pages/Dashboard";
import GettingStarted from "../docs/GettingStarted";
import OrdersDocs from "../docs/pages/Orders";
import TrackingAccountsDocs from "../docs/pages/Accounts";
import BookOrderDocs from "../docs/pages/BookOrder";
import SettingsDocs from "../docs/pages/Settings";
import MergeOrdersDocs from "../docs/pages/MergeOrders";
import UsersManagement from "../docs/pages/Users";
import Plans from "../pages/Plans";
import TwofactorAuth from "../docs/pages/TwofactorAuth";
import LiveTracking from "../docs/pages/LiveTracking";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/create-new-password",
    element: <ForgotPassword />,
  },
  {
    path: "/privacy-policy",
    element: <Privacy />,
  },
  {
    path: "/two-factor",
    element: <TwoFactorPage />,
  },
  {
    path: "/track/:track_number",
    element: <TrackOrder />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  // Protected routes
  {
    path: "/dashboard",
    element: <ProtectedRoute />,
    children: [
      {
        element: <LayoutWrapper />,
        children: [
          { path: "", element: <Dashboard /> },
          { path: "users", element: <Users /> },
          { path: "orders", element: <Orders /> },
          { path: "profile", element: <Profile /> },
          { path: "accounts", element: <Accounts /> },
          { path: "templates", element: <Templates /> },
          { path: "settings", element: <SettingsPage /> },
          { path: "order/book", element: <CreateOrder /> },
          { path: "create-user", element: <CreateUser /> },
          { path: "users/:user_id", element: <EditUser /> },
          { path: "post-booking", element: <PostBookingPage /> },
          { path: "create-account-alert", element: <NoAccount /> },
          { path: "merge-orders", element: <MergeOrderWrapper /> },
          { path: "accounts/create-account", element: <Settings /> },
          { path: "edit-account/:account_id", element: <EditAccount /> },
          // Docs - BookEase
          { path: "docs", element: <GettingStarted /> },
          { path: "docs/dashboard", element: <DashboardDocs /> },
          { path: "docs/orders", element: <OrdersDocs /> },
          {
            path: "docs/tracking-accounts",
            element: <TrackingAccountsDocs />,
          },
          {
            path: "docs/order-booking",
            element: <BookOrderDocs />,
          },
          {
            path: "docs/settings",
            element: <SettingsDocs />,
          },
          {
            path: "docs/merge-orders",
            element: <MergeOrdersDocs />,
          },
          {
            path: "docs/users",
            element: <UsersManagement />,
          },
          { path: "docs/order-booking-guide", element: <OrderBookingGuide /> },
          { path: "docs/plans", element: <Plans /> },
          { path: "docs/two-factor-auth", element: <TwofactorAuth /> },
          { path: "docs/live-tracking", element: <LiveTracking /> },
        ],
      },
    ],
  },
]);

export default router;
