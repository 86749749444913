export const GENERALIZED_STATUSES = [
  "Fulfilled",
  "Inprocess",
  "Delivered",
  "Cancelled",
];
export const csvDownloadOptions = {
  allstore: "ALLSTORE",
  searched: "ALL_SEARCHED",
  currentPage: "CURRENT_SEARCHED",
};

export const usersStatus = { user: "USER", admin: "ADMIN" };
export const SCOPES = [
  {
    type: "Orders",
    scopes: [
      {
        scope: "READ_ORDERS",
        description: "See orders, use filters",
      },
      {
        scope: "BOOK_ORDERS",
        description: "Order booking and merge orders",
      },
      {
        scope: "WRITE_ORDERS",
        description: "cancel booking, track orders",
      },
      {
        scope: "UPDATE_ORDERS",
        description: "Cancel orders (requires read orders access)",
      },
      {
        scope: "EXPORT_ORDERS",
        description: "Export orders (requires read orders access)",
      },
    ],
  },
  {
    type: "Accounts",
    scopes: [
      {
        scope: "READ_ACCOUNTS",
        description: "See tracking accounts",
      },
      {
        scope: "WRITE_ACCOUNTS",
        description: "Can create new accounts.",
      },
    ],
  },
  {
    type: "Settings",
    scopes: [
      {
        scope: "READ_SETTINGS",
        description: "See store default settings",
      },
      {
        scope: "WRITE_SETTINGS",
        description: "Mutate store's default settigs",
      },
    ],
  },
  {
    type: "Analytics",
    scopes: [
      {
        scope: "READ_ANALYTICS",
        description: "Can visit dashboard",
      },
    ],
  },
];
