import { useSelector, useDispatch } from "react-redux";
import { Toast } from "@shopify/polaris";
import { RootState } from "../../store/store/store";
import { hideToast } from "../../store/store/slices/AlertSlice";

const ToastAlert = () => {
  const dispatch = useDispatch();
  const { message, visible, error } = useSelector(
    (state: RootState) => state.toast
  );

  const handleDismiss = () => {
    dispatch(hideToast());
  };
  return (
    <div style={{ zIndex: -1000 }}>
      {visible && (
        <Toast
          content={message || ""}
          onDismiss={handleDismiss}
          // tone="magic"
          error={error}
        />
      )}
    </div>
  );
};

export default ToastAlert;
