/* eslint-disable @typescript-eslint/no-explicit-any */
// src/components/AppModal.js
import { Modal, TextContainer } from "@shopify/polaris";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../store/store/slices/ModalSlice";

function AppModal() {
  const dispatch = useDispatch();

  const { showModal, content, heading, onConfirm } = useSelector(
    (state: any) => state?.modal
  );

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleYes = () => {
    if (onConfirm) {
      onConfirm();
    }
    handleClose();
  };

  return (
    <Modal
      instant
      open={showModal}
      onClose={handleClose}
      title={heading}
      primaryAction={{
        destructive: true,
        content: "Yes",
        onAction: handleYes,
      }}
      secondaryActions={[
        {
          content: "No",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p style={{ whiteSpace: "pre-wrap" }}>{content}</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}

export default AppModal;
