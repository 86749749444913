import { useEditEmailMutation } from "../../store/store/slices/UsersRTK";
import { Button, FormLayout, TextField } from "@shopify/polaris";
import { showToast } from "../../store/store/slices/AlertSlice";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const ChangeEmail = ({
  email,
  loading,
  id,
  isAdmin,
}: {
  email: string;
  loading: boolean;
  id: string;
  isAdmin: boolean;
}) => {
  const {
    reset,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [editEmail, { isLoading, isSuccess, isError, error }] =
    useEditEmailMutation();
  const onEditEmailHandler = async () => {
    const values = getValues();
    await editEmail({ ...values, id });
  };
  const BUTTON_DISABLED =
    email === watch("email")?.trim() ||
    !watch("email")?.trim() ||
    isLoading ||
    loading;
  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showToast({ error: false, message: "Email updated", visible: true })
      );
    }
    if (isError) {
      dispatch(
        showToast({
          error: true,
          message: error?.data?.errors[0]?.msg,
          visible: true,
        })
      );
    }
  }, [isLoading, isSuccess, isError, error]);
  useEffect(() => {
    reset({ email: isAdmin ? "" : email });
  }, [email, loading]);
  return (
    <>
      <FormLayout.Group>
        <Controller
          control={control}
          defaultValue={email || ""}
          name="email"
          rules={{ required: "Email is required" }}
          render={({ field }) => (
            <TextField
              label="Email"
              {...field}
              autoComplete="Off"
              error={errors.email && "Email is required"}
              requiredIndicator
              loading={isLoading || loading}
              disabled={isLoading || loading || isAdmin}
              placeholder={isAdmin ? "Admin email can not be changed" : ""}
              clearButton
              onClearButtonClick={() =>
                reset((prev) => {
                  return { ...prev, email: "" };
                })
              }
            />
          )}
        />
      </FormLayout.Group>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          size="micro"
          loading={isLoading || loading}
          disabled={BUTTON_DISABLED}
          onClick={onEditEmailHandler}
        >
          Change email
        </Button>
      </div>
    </>
  );
};

export default ChangeEmail;
