import { OrderStatusTypes } from "../../store/types/types";

const OrderStatus = ({ status }: OrderStatusTypes) => {
  return (
    <div className="order_status_wrapper">
      <div className="order_status_inner">
        <div className={`order_status_digit_box`}>1</div>
        <div className={`order_status_digit_box ${status < 2 && "not_active"}`}>
          2
        </div>
        <div
          className={`order_status_digit_box order_status_digit_box ${
            status < 3 || status === 4 ? "not_active" : ""
          }`}
        >
          3
        </div>
        <div className={`order_status_digit_box ${status < 4 && "not_active"}`}>
          4
        </div>
      </div>
    </div>
  );
};
export default OrderStatus;
