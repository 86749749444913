import {
  Badge,
  BlockStack,
  Box,
  Button,
  Icon,
  InlineStack,
  Page,
  Text,
} from "@shopify/polaris";
import { CheckIcon, StarFilledIcon } from "@shopify/polaris-icons";

interface PLAN {
  title: string;
  price: number;
  currencySign: string;
  isFree: boolean;
  durationAsPer: string;
  isPopular: boolean;
  features: string[];
}
const APP_PLANS: PLAN[] = [
  {
    title: "Basic plan",
    isFree: true,
    currencySign: "$",
    durationAsPer: "Month",
    isPopular: false,
    features: [
      "Upto 5000 order booking",
      "10 tracking accounts",
      "10 user accounts",
      "Partial settings",
      "Two factor protected",
    ],
    price: 0,
  },
  {
    title: "Standard plan",
    currencySign: "$",
    durationAsPer: "Month",
    isPopular: true,
    isFree: false,
    features: [
      "Everyday order(s) live status update",
      "Unlimited orders booking",
      "Unlimited tracking accounts",
      "Unlimited user accounts",
      "Two factor protected",
      "All settings",
    ],
    price: 10,
  },
];

const Plans = () => {
  return (
    <div
      style={{
        marginTop: "2rem auto",
        width: "max-content",
        margin: "auto",
      }}
    >
      <div style={{ margin: "2rem 0rem" }}>
        <Text as="h1" fontWeight="bold" variant="headingLg">
          Pricing
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        {APP_PLANS.map((plan: PLAN, index: number) => (
          <div
            style={{
              position: "relative",
              transform: `${
                index === 1 ? "translateY(0px)" : "translateY(0px)"
              }`,
            }}
          >
            <Box
              borderWidth="0165"
              borderRadius="300"
              width="350px"
              borderColor="border"
              padding={"400"}
              background="bg-fill"
            >
              <BlockStack gap={"500"}>
                {plan.isPopular && (
                  <div>
                    <Badge icon={StarFilledIcon} tone="magic">
                      Popular plan
                    </Badge>
                  </div>
                )}
                {/* 
                {plan.isPopular && (
                  <>
                    <div
                    style={{
                      position: "absolute",
                        height: "30px",
                        width: "150px",
                        background: "rgba(4, 123, 93, 1)",
                        left: 0,
                        top: 15,
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        padding: ".5rem",
                        color: "white",
                        borderTopRightRadius: "15px",
                        borderBottomRightRadius: "15px",
                      }}
                    >
                      Popular plans
                    </div>
                    <div>ss</div>
                    </>
                )} */}
                <Text as="p">{plan.title}</Text>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                  }}
                >
                  {plan.isFree ? (
                    <Text as="span" variant="headingXl">
                      Free
                    </Text>
                  ) : (
                    <>
                      <Text as="span" variant="headingXl">
                        {plan.price} {plan.currencySign}
                      </Text>
                      <span style={{ textTransform: "lowercase" }}>
                        /{plan.durationAsPer}
                      </span>
                    </>
                  )}
                </div>

                <BlockStack gap={"300"}>
                  {plan.features.map((feature: string) => (
                    <InlineStack gap={"300"}>
                      <div
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "100%",
                          background: "rgb(225 255 217)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span style={{ scale: ".8" }}>
                          <Icon source={CheckIcon} tone="success"></Icon>
                        </span>
                      </div>
                      <Text as="p" variant="bodyMd">
                        {feature}
                      </Text>
                    </InlineStack>
                  ))}
                </BlockStack>
                <div>
                  <Button fullWidth variant="primary">
                    Select plan
                  </Button>
                </div>
              </BlockStack>
            </Box>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
