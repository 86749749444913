import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./BaseQuery";

export const trackOrderApi = createApi({
  reducerPath: "trackOrderApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["ParcelTracking"],
  endpoints: (builder) => ({
    trackParcel: builder.query({
      query: (trackingNumber) => `/track-order-public/${trackingNumber}`,
      transformResponse: (response) => response,
      providesTags: (result, error, trackingNumber) => [
        { type: "ParcelTracking", id: trackingNumber },
      ],
      keepUnusedDataFor: 0,
    }),
    // New endpoint for `/track-order/${trackingNumber}`
    getParcelDetails: builder.query({
      query: (trackingNumber) => `/track-order/${trackingNumber}`,
      transformResponse: (response) => response,
      providesTags: (result, error, trackingNumber) => [
        { type: "ParcelTracking", id: trackingNumber },
      ],
      keepUnusedDataFor: 0,
    }),
  }),
});

// Export hooks for components
export const { useTrackParcelQuery, useGetParcelDetailsQuery } = trackOrderApi;
