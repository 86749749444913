/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1",
  }),
  endpoints: (builder) => ({
    loginUserAccount: builder.mutation({
      query: (data) => {
        return {
          url: `/login-user`,
          method: "POST",
          body: { ...data },
        };
      },
    }),
  }),
});
export const { useLoginUserAccountMutation } = loginApi;
